import React from "react";
import Header from './HeaderClient';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import pAPI from "../../api/publicAPI";

import InvalidToken from "./InvalidToken";
import Fetching from "./Fetching";
import ClientMenu from "./ClientMenu";

import {
    IoLogoWhatsapp,
} from "react-icons/io5";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

function ClientLandingPage() {

    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    let token = useParams().code;

    const [fetching, setFetching] = React.useState(true)
    const [invalidToken, setInvalidToken] = React.useState(false);


    const {
        CLIENT_INFO,
    } = dataReducer;

    /* eslint-disable */
    React.useLayoutEffect(() => {
        setFetching(true);
        pAPI.fetchV2({ token }).then(res => {
            setFetching(false);
            if (!res.success) {
                setInvalidToken(true);
            } else {
                dispatch({
                    type: 'CLIENT_INFO_SET',
                    CLIENT_INFO: res
                });
            }
        })
    }, []);
    /* eslint-enable */


    if (fetching) return <div> <Header token={token} /><Fetching /></div>
    if (invalidToken) return <InvalidToken />



    return <div>
        <HelmetProvider>
            <Helmet>
                <title>{CLIENT_INFO?.account?.clientName}</title>
            </Helmet>
        </HelmetProvider>

        <Header token={token} />

        <div style={{
            margin: "auto",
            marginTop: 70,
            maxWidth: 650,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <div
                style={{
                    marginTop: 10,
                    width: '100%'
                }}
            >
                <div
                    className="div-col"
                    style={{
                        margin: 'auto',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                >
                    <h3>{CLIENT_INFO.account.clientName}</h3>

                    <div>{CLIENT_INFO.account.contactName}</div>

                </div>

                <div
                    style={{
                        margin: 'auto',
                        marginTop: 20,
                        width: '100%',
                        maxWidth: 650,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <div style={{
                        textAlign: 'center',
                        padding: '0px 5px'
                    }}>
                        <Trans i18nKey="ClientLandingPageInstructions"
                            values={{
                                quotesCount: 20,
                                invoicesCount: 3,
                                posCount: 2
                            }}
                        >
                        </Trans>
                    </div>

                    <br />
                    <ClientMenu
                        option="landing"
                        token={token}
                    />
                    <br />

                    <div>
                        <Button onClick={() => {
                            navigate(`/w/${token}/rfq`)
                        }}
                            variant="outline-success"
                        >
                            {t('RFQNew')}
                        </Button>
                    </div>


                    <Container
                        style={{
                            textAlign: 'center',
                            marginTop: 50,
                        }}
                    >
                        {t('forSupport')}
                        <a style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            padding: 10
                        }} href='https://wa.me/17863758255'><IoLogoWhatsapp size={20} /> +1 (786) 375-8255</a>
                    </Container>
                </div>
            </div>
        </div>
    </div >
}


export default ClientLandingPage;