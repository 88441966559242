import React from "react";
import Header from './HeaderClient';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useParams, } from "react-router-dom";


import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import pAPI from "../../api/publicAPI";

import InvalidToken from "./InvalidToken";
import Fetching from "./Fetching";
import ClientMenu from "./ClientMenu";

import Container from 'react-bootstrap/Container';

import Lists from "./Lists";
const { ActivePOs } = Lists;

function PurchaseOrders() {

    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();


    let token = useParams().code;

    const [fetching, setFetching] = React.useState(true);
    const [invalidToken, setInvalidToken] = React.useState(false);


    const {
        CLIENT_INFO,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        setFetching(true);
        pAPI.fetchV2({ token }).then(res => {
            setFetching(false);
            if (!res.success) {
                setInvalidToken(true);
            } else {
                dispatch({
                    type: 'CLIENT_INFO_SET',
                    CLIENT_INFO: res
                });
            }
        });
        dispatch({
            type: 'SECTION_NAME_SET',
            sectionName: t('POSList_full')
        });
        return () => {
            dispatch({
                type: 'SECTION_NAME_SET',
                sectionName: ''
            })
        }
    }, []);
    /* eslint-enable */



    if (fetching) return <div> <Header token={token} /><Fetching /></div>
    if (invalidToken) return <InvalidToken />

    return <div>
        <HelmetProvider>
            <Helmet>
                <title>{CLIENT_INFO?.account?.clientName}</title>
            </Helmet>
        </HelmetProvider>


        <Header token={token} />
        <Container
            className="container-main"
        >


            <div
                className="div-col"
                style={{
                    margin: 'auto',
                    alignItems: 'center',
                }}
            >
                <h4 style={{ marginBottom: 5 }} >{CLIENT_INFO.account.clientName}</h4>


                <ClientMenu
                    option="purchase-orders"
                    token={token}
                />
                <ActivePOs
                    token={token}
                />
            </div>
        </Container>
    </div >
}


export default PurchaseOrders;