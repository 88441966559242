import React from "react";
import Styles from "../../styles";
import Select from "react-select";
import { useSelector } from 'react-redux';

import {  ThreeDots } from "react-loader-spinner";
  


const WRPackage = ({ 
    PCK ,
    label,
    fnCancel,
    fnSave,
    fnDelete
}) => {

    const [packageType, setPackageType] = React.useState({ label: PCK.packageType, value: PCK.packageType });
    const [quantity, setQuantity] = React.useState(PCK.packages);
    const [weight, setWeight] = React.useState(PCK.weight);
    const [height, setHeight] = React.useState(PCK.height);
    const [width, setWidth] = React.useState(PCK.width);
    const [length, setLength] = React.useState(PCK.length);
    const [remarks, setRemarks] = React.useState(PCK.remarks);

    const dataReducer = useSelector((state) => state.dataReducer);
    const { 
        fetching, 
        PACKAGE_TYPES
    } = dataReducer;  

    return <div> 
                <fieldset style={{ position: 'relative' }}>
                <legend>{label}</legend>
                {PCK.wrId !== 0 &&
                    <button
                        style={{
                            position: 'absolute',
                            top: 5, right: 5
                        }}
                        onClick={fnCancel}
                    >Cancel</button>
                }
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <input
                        style={{
                            width: 50,
                            fontSize: 17
                        }}
                        value={quantity || ""}
                        onChange={e => setQuantity(e.target.value.replace(/\D/g, ''))}
                        type="number"
                    />
                    <div style={{ width: 130, marginLeft: 10 }}>
                        <Select
                            defaultValue={{ label: 'Box', value: "box" }}
                            onChange={setPackageType}
                            value={packageType}
                            options={PACKAGE_TYPES}
                        >
                        </Select>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyItems: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginTop: 10,
                    }}
                >
                    <label style={{ marginRight: 10 }}>
                        Weight<br />
                        <input
                            style={{
                                width: 50,
                                fontSize: 17
                            }}
                            value={weight || ""}
                            onChange={e => setWeight(e.target.value.replace(/\D/g, ''))}
                            type="number"
                        />
                    </label>
                    <label style={{ marginRight: 10 }}>
                        Height<br />
                        <input
                            style={{
                                width: 50,
                                fontSize: 17
                            }}
                            value={height || ""}
                            onChange={e => setHeight(e.target.value.replace(/\D/g, ''))}
                            type="number"
                        />
                    </label>
                    <label style={{ marginRight: 10 }}>
                        Width<br />
                        <input
                            style={{
                                width: 50,
                                fontSize: 17
                            }}
                            value={width || ""}
                            onChange={e => setWidth(e.target.value.replace(/\D/g, ''))}
                            type="number"
                        />
                    </label>
                    <label>
                        Length<br />
                        <input
                            style={{
                                width: 50,
                                fontSize: 17
                            }}
                            value={length || ""}
                            onChange={e => setLength(e.target.value.replace(/\D/g, ''))}
                            type="number"
                        />
                    </label>
                </div>
                <div style={{ marginTop: 10 }}>
                    <label>
                        Remarks<br />
                        <textarea
                            style={{
                                width: 300,
                                fontSize: 17
                            }}
                            value={remarks || ""}
                            onChange={e => setRemarks(e.target.value)}
                        />
                    </label>
                </div> 
            
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    
                    {PCK.id !== 0 &&
                    <button
                        onClick={() => fnDelete(PCK.id)}
                        style={{ color: '#fff', width: 140, height: 30, backgroundColor: 'red'}}
                        disabled={fetching === "wr_updating_v2"}
                    >
                        
                            {fetching === "wr_deleting_v2"  ? 
                                "Deleting..."
                                :
                                "Delete Package"
                            } 
                    </button>
                    }
                </div>
            
            </fieldset>

            <Styles.ButtonMain 
            onClick={() => {
              
              
                
                fnSave({
                    id: PCK.id, 
                    packages: quantity , 
                    packageType: packageType.value,
                    remarks,
                    weight,
                    width,
                    height,
                    length
                });
            }}
            style={{
                width: 120
            }}
            disabled={fetching === "wr_updating_v2"}
        >
            {fetching === "wr_updating_v2"  ? 
                <ThreeDots 
                    color={"#fff"}
                    width={50}
                />
                :
                "Save"
            }
            
        </Styles.ButtonMain > 
    </div>
}

export default WRPackage;