import React from "react";
//import Menu from './Menu';
import Header from './Header';
import Container from 'react-bootstrap/Container';

import Stack from 'react-bootstrap/Stack';

import { Button } from 'react-bootstrap';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LinkContainer } from 'react-router-bootstrap';


function CSClientPage() {

    return (<>
        <HelmetProvider>
            <Helmet>
                <title>Customer Service</title>
            </Helmet>
        </HelmetProvider>
        <Header />
        <Container
            style={{
                marginTop: 70,
                maxWidth: 650
            }}
        >

            <h5>Customer Service</h5>
            <br />

            <Stack className="col-md-5 mx-auto" style={{ maxWidth: 300 }} gap={3}>
                <LinkContainer to={`/admin/customer-service/clients/`}>

                    <Button  >
                        Clients
                    </Button>
                </LinkContainer>



                <LinkContainer to={`/admin/customer-service/tickets/`}>

                    <Button  >
                        Tickets
                    </Button>
                </LinkContainer>

            </Stack>




        </Container>
    </>
    );
}

export default CSClientPage;