import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Circles, ThreeDots } from "react-loader-spinner";

import Select from "react-select";

import WHRepackAPI from "../api/WHRepackAPI";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width: width - 5,
        height
    };
}




const WRDescription = ({ wr }) => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file
    } = dataReducer;

    return <div
        style={{

            display: 'flex',
            flexDirection: 'column',
        }}
    >

        <div
            style={{
                paddingLeft: 10
            }}
        >
            <div>
                {wr.packages} {wr.packageType} <span>{wr.height} x {wr.width} x {wr.length}</span>  <span>{wr.weight}lb</span>
            </div>
            {
                file.WRItems.filter(i => i.wrId === wr.id).map((item, ix) => <div key={ix} style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <span style={{ fontWeight: 'bold', marginRight: 10 }}>{item.partNo}</span>
                        <span style={{ fontWeight: 'bold', color: 'orange' }}>{item.unitsReceived} of {item.unitsOrdered} </span>
                    </div>
                    <span style={{ paddingLeft: 10, fontSize: 14 }}>{item.partDesc}</span>
                </div>)
            }
        </div>
    </div>

}



const WRSpecs = ({
    wr,
    legend,
    split_index,
    consolidated_items
}) => {

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
        PACKAGE_TYPES
    } = dataReducer;
    //console.log("dataReducer", dataReducer) 
    const [packageType, setPackageType] = React.useState("select");
    const [quantity, setQuantity] = React.useState("");

    const [weight, setWeight] = React.useState("");
    const [height, setHeight] = React.useState("");
    const [width, setWidth] = React.useState("");
    const [length, setLength] = React.useState("");
    const [remarks, setRemarks] = React.useState("");

    const [items, setItems] = React.useState({});

    const [itemList, setItemList] = React.useState([]);


    /* eslint-disable */
    React.useEffect(() => {
        if (consolidated_items) {
            let wrId_array = []
            Object.entries(consolidated_items).forEach(([key, value]) => {
                wrId_array.push(parseInt(key));
            });
            let _wr_items = file.WRItems.filter(i => wrId_array.includes(i.wrId));
            let wr_items = [..._wr_items];
            let wr_items_sum = [];
            wr_items.forEach(wr => {
                let ix = wr_items_sum.findIndex(i => i.vendorPOItemId === wr.vendorPOItemId);
                if (ix > -1) {
                    wr_items_sum[ix].unitsReceived = parseInt(wr_items_sum[ix].unitsReceived) + parseInt(wr.unitsReceived);
                } else {
                    //use spread to push a clone of the object
                    wr_items_sum.push({ ...wr });
                }

            });

            setItemList(wr_items_sum);
        }
    }, []);


    React.useEffect(() => {

        dispatch({
            type: "WR_SPLIT_WR_UPDATE",
            wr: {
                wrId: wr.id,
                vendorPOId: wr.vendorPOId,
                split_index,
                weight: parseInt(weight || 0),
                width: parseInt(width || 0),
                length: parseInt(length || 0),
                height: parseInt(height || 0),
                remarks,
                items,
                packageType: packageType.value,
                packages: parseInt(quantity || 0),
            }
        })
    }, [
        weight,
        width,
        length,
        height,
        remarks,
        items,
        packageType,
        quantity
    ]);
    /* eslint-enable */

    const SetItems = ({

        vendorPOItemId,
        q

    }) => {
        let _items = { ...items }
        _items[vendorPOItemId] = q;
        if (q === "" || q === "0") {
            delete _items[vendorPOItemId]
        }
        setItems(_items);
    }
    return <fieldset  >
        <legend>{legend}</legend>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <input
                style={{
                    width: 50,
                    fontSize: 17
                }}
                value={quantity || ""}
                onChange={e => setQuantity(e.target.value.replace(/\D/g, ''))}
                type="number"
            />
            <div style={{ width: 130, marginLeft: 10 }}>
                <Select
                    defaultValue={{ label: 'Box', value: "box" }}
                    onChange={setPackageType}
                    value={packageType}
                    options={PACKAGE_TYPES}
                >
                </Select>
            </div>
        </div>

        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'center',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 10,
            }}
        >
            <label style={{ marginRight: 10 }}>
                Weight<br />
                <input
                    style={{
                        width: 50,
                        fontSize: 17
                    }}
                    value={weight || ""}
                    onChange={e => setWeight(e.target.value.replace(/\D/g, ''))}
                    type="number"
                />
            </label>
            <label style={{ marginRight: 10 }}>
                Height<br />
                <input
                    style={{
                        width: 50,
                        fontSize: 17
                    }}
                    value={height || ""}
                    onChange={e => setHeight(e.target.value.replace(/\D/g, ''))}
                    type="number"
                />
            </label>
            <label style={{ marginRight: 10 }}>
                Width<br />
                <input
                    style={{
                        width: 50,
                        fontSize: 17
                    }}
                    value={width || ""}
                    onChange={e => setWidth(e.target.value.replace(/\D/g, ''))}
                    type="number"
                />
            </label>
            <label>
                Length<br />
                <input
                    style={{
                        width: 50,
                        fontSize: 17
                    }}
                    value={length || ""}
                    onChange={e => setLength(e.target.value.replace(/\D/g, ''))}
                    type="number"
                />
            </label>
        </div>
        <div style={{ marginTop: 10 }}>
            <label>
                Remarks<br />
                <textarea
                    style={{
                        width: 300,
                        fontSize: 17
                    }}
                    value={remarks || ""}
                    onChange={e => setRemarks(e.target.value)}
                />
            </label>
        </div>

        {consolidated_items ?
            <div>

                {itemList.map((i, ix) => <div
                    key={ix}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 10
                    }}
                >
                    <span style={{ color: 'blue' }}>{i.unitsReceived} x {i.partNo}</span>
                    <span>{i.partDesc}</span>
                </div>)}
            </div>
            :


            file.WRItems.filter(i => i.wrId === wr.id).map((item, ix) => <div key={ix} style={{
                display: 'flex',
                flexDirection: 'column',

            }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: 300,
                        padding: 5,
                        alignItems: 'center',
                        backgroundColor: ix % 2 ? '#e0e0e0' : '#fff'
                    }}
                >
                    <span style={{ fontWeight: 'bold', marginRight: 10, width: 200 }}>{item.partNo}</span>

                    <input
                        style={{
                            width: 50,
                            fontSize: 17
                        }}
                        value={items[item.vendorPOItemId] || ""}
                        onChange={e => SetItems({ vendorPOItemId: item.vendorPOItemId, q: e.target.value.replace(/\D/g, '') })}
                        type="number"
                    />

                    <span style={{ fontWeight: 'bold', color: item.unitsReceived !== item.unitsOrdered ? 'orange' : 'green' }}>{item.unitsReceived}  </span>

                </div>
            </div>)
        }
    </fieldset>

}


const WRSplit = ({
    wrCount,
    WRtoSplit,
    fileId,
}) => {

    const WRS = [];
    for (let i = 0; i < wrCount; i++) {
        WRS.push(<WRSpecs wr={WRtoSplit} key={i} split_index={i} legend={`W/R ${i + 1}`} />);
    }
    return <div
        style={{
            width: '100%',
        }}
    >
        <fieldset>
            <legend>W/R {fileId}{JSON.stringify(WRtoSplit.id)}</legend>
            <WRDescription
                wr={WRtoSplit}
            />
        </fieldset>
        {WRS}
    </div>
}


const WarehousesForRepack = () => {
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file
    } = dataReducer;

    const dataReducerWR = useSelector((state) => state.dataReducerWR);
    const {
        wr_split,
    } = dataReducerWR;
    const [fetching, setFetching] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [repackType, setRepackType] = React.useState(searchParams.get("repackType") || null);
    const [selectedWR, setSelectedWR] = React.useState({});
    const [selectedVendorPOId, setSelectedVendorPOId] = React.useState(null);
    const [selectedWRDiv, setSelectedWRDiv] = React.useState({});
    const [splitWR, setSplitWR] = React.useState(2);

    const [WRtoSplit, setWRToSplit] = React.useState();
    /* eslint-disable */
    React.useEffect(() => {
        let _SelectedWRDiv = "<br/><b>Consolidate</b>"
        Object.entries(selectedWR).forEach(([key]) => {
            _SelectedWRDiv += '<br/>W/R ' + file.file.fileId + key;
        })
        setSelectedWRDiv(_SelectedWRDiv);

    }, [selectedWR]);
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    React.useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useEffect(() => {
        setSearchParams({ repackType, wr: searchParams.get("wr") })
    }, [repackType]);
    /* eslint-enable */

    const fnVendorName = (vendorPOId) => {
        return (file.vendorPOs.find(po => po.vendorPOId === vendorPOId)).vendorName
    }

    const SetRepackType = (a) => {
        setStep(1);
        setSelectedWR({});
        setRepackType(a);
    }

    const fnStep3_consolidate = () => {
        //console.warn(selectedWR);
        setStep(3);
    }

    const fnStep3_split = () => {


        let msg = "";
        let wrId;
        let itemQuantitiesSum = {};

        wr_split.forEach(w => {
            wrId = w.wrId;
            //delete w.wrId;
            if (
                !w.weight
                ||
                !w.length
                ||
                !w.width
                ||
                !w.height
                ||
                !w.packageType
                ||
                !w.packages
            ) {
                msg += `W/R ${w.split_index + 1} fields missing\n`;
            }

            if (!Object.keys(w.items).length) {
                msg += `W/R ${w.split_index + 1} no items\n`;
            } else {
                Object.entries(w.items).forEach(([key, value]) => {
                    itemQuantitiesSum[key] = (itemQuantitiesSum[key] || 0) + parseInt(value);
                });
            }
        });

        if (msg === "") {
            //check if sum of items in parent W/R match
            file.WRItems.filter(i => i.wrId === wrId).forEach((item) => {
                if ((itemQuantitiesSum[item.vendorPOItemId] || 0) !== item.unitsReceived) {
                    msg += `${item.partNo} must add up to ${item.unitsReceived}\n`
                }
            });
        }
        let args = { wrId, wr_split };

        console.log("wr_split==> ", wr_split)
        console.log("SEND SPLIT WR TO SERVER", JSON.stringify(args))
        if (msg !== "") {
            alert(msg);
            return;
        }



    }

    const fnSelectWR = ({
        wrId,
        wr
    }) => {
        let _selected = { ...selectedWR };
        if (_selected[wrId]) {
            delete _selected[wrId];
        } else {
            _selected[wrId] = true;
        }

        if (!Object.keys(_selected).length) {
            //if all w/r are "unselected"  => reset selectedVendorPOId to null (no selected vendorPO)
            setSelectedVendorPOId(null);

        }

        if (selectedVendorPOId !== null && selectedVendorPOId !== wr.vendorPOId) {
            alert("Consolidated W/R must be for the same Vendor PO")
            return;
        }
        setSelectedVendorPOId(wr.vendorPOId)

        setSelectedWR(_selected);
        if (repackType === 'split') {
            //only 1 W/R can be selected after split
            if (step === 1) {
                setStep(2);
                setWRToSplit(wr);
            }
            if (step === 2) {
                setStep(1);
                setWRToSplit();
            }
        }

        if (repackType === 'consolidate') {
            if (Object.keys(_selected).length > 1) {
                setStep(2);
            } else {
                setStep(1);
            }
        }
    }

    if (!file) return null;

    const wrList = file.whReceipts.filter(w => w.poOrderItemsCount > 0 && w.releaseAuthorized === 0 && w.released === 0)

    if (wrList.length === 0) return <div
        style={{
            textAlign: 'center',
            color: 'red',
            paddingTop: 50
        }}
    >
        There are no unreleased W/R
        <br />
        in this file
    </div>

    if (step === 3 && repackType === "split") return <div
        style={{
            width: 500,
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <button
                onClick={() => {
                    dispatch({
                        type: 'WR_SPLIT_WR_RESET'
                    })
                    setStep(2);
                }}
            >
                Cancel
            </button>

            <Styles.ButtonMain
                style={{
                    width: 200
                }}
                onClick={() => fnStep3_split()}

            >
                Continue
            </Styles.ButtonMain>
        </div>

        <WRSplit
            wrCount={parseInt(splitWR)}
            WRtoSplit={WRtoSplit}
            fileId={file.file.fileId}
            vendorPOId={wr_split.vendorPOId}
        />

    </div>
    if (step === 3 && repackType === "consolidate") return <div
        style={{
            width: windowDimensions.width,
            maxWidth: 600
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <button
                onClick={() => {
                    dispatch({
                        type: 'WR_SPLIT_WR_RESET'
                    })
                    setStep(2);
                }}
            >
                Cancel
            </button>

            <Styles.ButtonMain
                style={{
                    width: 200
                }}
                disabled={fetching}
                onClick={() => {

                    let wrIdArray = [];
                    let vendorPOId = selectedVendorPOId;
                    Object.entries(selectedWR).forEach(([key]) => {
                        wrIdArray.push(parseInt(key))
                    });
                    let args = wr_split[0];
                    delete args.items;
                    delete args.split_index;

                    let msg = "";

                    if (!args.packages) msg += 'Packages\n';
                    if (!args.packageType) msg += 'Package Type\n';
                    if (args.weight === 0) msg += 'Weight\n';
                    if (args.width === 0) msg += 'Width\n';
                    if (args.height === 0) msg += 'Height\n';
                    if (args.length === 0) msg += 'Length\n';

                    if (msg !== "") {
                        alert(`Following fields are required:\n\n${msg}`)
                        return;
                    } else {
                        args = {
                            ...{ ...args },
                            wrIdArray,
                            vendorPOId,
                            fileId: file.file.fileId
                        }
                        setFetching(true);
                        WHRepackAPI.consolidate(args).then(res => {
                            setFetching(false);
                            if (res.success) {
                                dispatch({
                                    type: "FILE_FETCH",
                                    fileId: file.file.fileId
                                });
                                navigate(`/admin/wh/file/${file.file.fileId}`);
                            } else {
                                alert(JSON.stringify(res))
                            }
                        });
                    }

                    /*console.warn("SEND CONSOLIDATE TO SERVER", {
                        ...{...args},
                        wrIdArray,
                        vendorPOId,
                        fileId: file.file.fileId
                    })*/
                }}

            >
                {fetching ?
                    <ThreeDots />
                    :
                    "Continue"
                }

            </Styles.ButtonMain>
        </div>

        <div
            dangerouslySetInnerHTML={{ __html: selectedWRDiv }}
        ></div>

        <WRSpecs
            legend={"Consolidated W/R"}

            wr={{
                id: 0
            }}
            consolidated_items={selectedWR}
        />

    </div>

    return <div>

        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Styles.ButtonMain
                style={{
                    color: '#000', width: 100,
                    backgroundColor: repackType === "split" ? 'orange' : '#fff'
                }}
                onClick={() => SetRepackType("split")}
            >
                Split
            </Styles.ButtonMain>
            <span style={{ margin: 10 }}>OR</span>
            <Styles.ButtonMain
                style={{
                    color: '#000', width: 120,
                    backgroundColor: repackType === "consolidate" ? 'orange' : '#fff'
                }}
                onClick={() => {
                    setSelectedVendorPOId(null);
                    SetRepackType("consolidate");
                }}

            >
                Consolidate
            </Styles.ButtonMain>
        </div>

        {step === 2 && repackType === 'split' &&
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                    paddingTop: 0,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <label
                    style={{
                        textAlign: 'center',
                        marginBottom: 10
                    }}
                >
                    How Many W/R
                    <br />
                    <input
                        style={{
                            width: 30,
                            fontSize: 17
                        }}
                        value={splitWR}
                        onChange={e => {
                            setSplitWR(e.target.value.replace(/\D/g, ''))
                        }}
                        type="number"
                    />
                </label>

                <Styles.ButtonMain
                    onClick={() => setStep(3)}
                    style={{
                        color: '#000000',
                        width: 200
                    }}
                >
                    Continue
                </Styles.ButtonMain>
            </div>
        }

        {repackType !== null &&

            <div
                style={{
                    borderTopWidth: 1,
                    borderTopColor: '#000',
                    borderTopStyle: 'solid',
                    marginBottom: 30
                }}
            >
                {wrList.map((wr, ix) => {

                    //si es split, solo se puede seleccionar 1 W/R
                    if (repackType === 'split' && step === 2 && !selectedWR[wr.id]) return null;

                    return <div
                        key={ix}
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            width: windowDimensions.width,
                            maxWidth: 600,
                            minWidth: 350,
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderBottomWidth: 1, borderBottomColor: '#000', borderBottomStyle: 'solid',
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                        >
                            {repackType !== null &&
                                <button
                                    onClick={() => fnSelectWR({ wrId: wr.id, wr })}
                                    style={{
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        margin: 10,
                                        backgroundColor: selectedWR[wr.id] ? 'orange' : '#e0e0e0'
                                    }}
                                >Select W/R</button>
                            }
                            <div >
                                <span style={{ color: 'blue', fontWeight: 'bold', }}>W/R {file.file.fileId}{wr.id}</span>
                                <br />
                                <span>{fnVendorName(wr.vendorPOId)}</span>
                            </div>
                        </div>

                        <div>{wr.remarks}</div>
                        <WRDescription wr={wr} />

                    </div>

                })}

            </div>
        }
        {step === 2 && repackType === 'consolidate' &&
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'sticky',
                    bottom: 0,
                    borderTopStyle: 'solid',
                    borderTopColor: '#000',
                    borderTopWidth: 1,
                    backgroundColor: '#c0c0c0'
                }}
            >
                <Styles.ButtonMain
                    onClick={() => fnStep3_consolidate()}
                    style={{
                        color: '#000000',
                        width: 210
                    }}
                >
                    Continue Consolidation
                </Styles.ButtonMain>
            </div>
        }
    </div>
}


function WHFileRepack() {

    let fileId = useParams().fileId || "";
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "FILE_FETCH",
            fileId
        });
    }, []);
    /* eslint-enable */


    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 350,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: 50
        }}>

            <Link to={`/admin/wh/file/${fileId}`} >File {fileId}</Link>
            <Link to={`/admin/wh/file/${fileId}/release`} >Release Status</Link>

            <Styles.H1 >File {fileId} Repack W/R</Styles.H1>



            {fetching ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20,
                        position: 'absolute',
                        backgroundColor: '#e0e0e090',
                        borderRadius: 50,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={1000}
                    />
                </div>
                :
                <WarehousesForRepack />
            }

        </div>
    </div>
    );
}

export default WHFileRepack;