import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
function AdminMain() {

    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 350,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <Styles.H1 >Notifications</Styles.H1>


            <div>
                <a href="/admin/notifications/pending/">Pending Notifications</a>

                <br />
                <br />
                <a href="/admin/notifications/sent/">Sent Notifications</a>

            </div>
        </div>
    </div>
    );
}

export default AdminMain;