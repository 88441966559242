import React from "react";
import Moment from 'react-moment';
import { Circles } from "react-loader-spinner";
import API from '../../api/ReportsAPI';
import FileNotes from "./FileNotes";

var formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
var formatterEUR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
});



const FileQuoteDetails = ({
    data,
    fileId,
    quoteId
}) => {
    const [fileDetails, setFileDetails] = React.useState(data);
    const [fetching, setFetching] = React.useState();
    const [action, setAction] = React.useState();

    const FileFetch = async ({
        fileId,
        quoteId
    }) => {
        if (fetching === 'file') return;
        setFetching("file");
        const data = await API.File({
            fileId,
            quoteId,
            method: 'GET'
        });
        data.data.fetchedFromAPI = true;
        setFileDetails(data.data);
        setFetching(null);
    }

    const QuoteActions = () => {

        return <div className="div-row-space-between"

            style={{ marginTop: 10 }}
        >
            <button
            >
                Approve
            </button>

            <button
                onClick={() => {
                    setAction(action === "reject" ? null : "reject")
                }}
            >
                Reject
            </button>


            <button>
                Follow Up
            </button>
        </div>
    }

    React.useLayoutEffect(() => {
        if (!fileId || !quoteId || fetching === 'file') return;
        FileFetch({
            fileId,
            quoteId
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (fetching === 'file') return (<div className="div-col" style={{ alignItems: 'center', margin: 50 }}>
        <Circles color="#000" height={70} width={70} />
    </div>);


    if (!fileDetails.quote) {
        return <div>
            {fileId}
            {quoteId}
        </div>
    }

    if (action === "reject") {
        return <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '50px 10px',
                backgroundColor: '#fff',
                height: '100%',
                width: '100',


            }}>
                <button
                    style={{
                        position: 'absolute',
                        top: 10,
                        left: 10,
                    }}
                    onClick={() => {
                        setAction(null)
                    }}
                >
                    Back
                </button>

                <label>
                    Reason
                    <br />
                    <select>
                        {fileDetails?.reject_reasons?.map((reason) => {
                            return <option key={reason.id} value={reason.id}>{reason.name}</option>
                        }
                        )}
                    </select>
                </label>

                <div>

                </div>
            </div>
        </div>
    }

    return <div>
        <div className="div-row-space-between" >
            <div>Quote {fileDetails.quote.clientQuoteId}</div>
            <div><Moment date={fileDetails.quote.clientQuoteDate} format="MMM DD YY" tz="America/New_York" /> </div>
        </div>
        <div>{fileDetails.clientName}</div>
        <div>{fileDetails.country}</div>
        <div> {fileDetails.salesRepName}</div>
        <div>{fileDetails.rfqName}</div>

        <div
            style={{
                marginTop: 10
            }}
        >

            {!fileDetails.fileFinished ?
                <div style={{ color: 'blue' }}>{fileDetails.fileStatusName}</div>
                :
                <div style={{ color: 'green' }}>{fileDetails.finishStatusName}</div>
            }
            <div>{fileDetails.contactName}<br /><a href={`mailto:${fileDetails.contactEmail}`}>{fileDetails.contactEmail}</a></div>
            <div>{fileDetails.contactPhone}</div>
            {fileDetails.rfqCurrency === 'USD' ?
                <div
                    style={{
                        textAlign: 'right'
                    }}
                >

                    <div>{formatterUSD.format(fileDetails.quote?.quotedItems)}</div>
                    <div>{formatterUSD.format(fileDetails.quote?.quotedOtherCosts)}</div>
                    <div><b>{formatterUSD.format(fileDetails.quote?.quotedOtherCosts + fileDetails.quote?.quotedItems)}</b></div>
                </div>
                :
                <div
                    style={{
                        textAlign: 'right'
                    }}
                >

                    <div>{formatterEUR.format(fileDetails.quote?.quotedItems)}</div>
                    <div>{formatterEUR.format(fileDetails.quote?.quotedOtherCosts)}</div>
                    <div><b>{formatterUSD.format(fileDetails.quote?.quotedOtherCosts + fileDetails.quote?.quotedItems)}</b></div>
                </div>
            }

            {
                !fileDetails.fileFinished && fileDetails.fileStatusId === 4
                &&
                <QuoteActions
                    reject_reasons={fileDetails.reject_reasons}
                />

            }
        </div>
        <hr />
        <div style={{
            marginBottom: 150
        }}>
            <FileNotes notes={fileDetails.notes} />
        </div>

        ./
    </div>

}


export default FileQuoteDetails;