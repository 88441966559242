import { combineReducers } from 'redux';

let dataState = {

    CEL_WHATSAPP: "https://wa.me/5717396142",
    NUMERO_WHATSAPP: "+5717396142",
    section: "main",
    fetching: false,
    userData: null,
    accion_terminada: null,
    display_message: null,

    wr_split: [],

    USER_ROLES: (localStorage.getItem("userRoles") || "").split(","),

    WAREHOUSES: [
        {
            id: 1,
            name: 'Prodek',
        },
        {
            id: 2,
            name: 'Brazil',
        },
        {
            id: 3,
            name: 'Tampa',
        },
    ],

    PACKAGE_TYPES: [
        {
            "label": "Bags",
            "value": "bags"
        },
        {
            "label": "Box",
            "value": "box"
        },

        {
            "label": "Bundle",
            "value": "bundle"
        },
        {
            "label": "Crate",
            "value": "crate"
        },
        {
            "label": "Drum",
            "value": "drum"
        },

        {
            "label": "Jaula",
            "value": "jaula"
        },

        {
            "label": "Pallet",
            "value": "pallet"
        },
        {
            "label": "Metal Sheets",
            "value": "sheets"
        },
        {
            "label": "Skids",
            "value": "skids"
        },

        {
            "label": "Tubes",
            "value": "tubes"
        },

    ],

    CS_REQUESTS: [],
    CS_REQUEST: null,
    CS_AGENTS: [],

}


const dataReducerWR = (state = dataState, action) => {
    switch (action.type) {
        case "WR_SPLIT_WR_RESET":
            return {
                ...state,
                wr_split: []
            }
        case "WR_SPLIT_WR_UPDATE":

            let _wr_split = state.wr_split;
            let _i = _wr_split.findIndex(e => e.split_index === action.wr.split_index)

            if (_i === -1) {
                _wr_split.push(action.wr)
            } else {
                _wr_split = _wr_split.map(e => e.split_index === action.wr.split_index ? action.wr : e)
            }

            return {
                ...state,
                wr_split: _wr_split
            }

        default:
            return state;
    }
}


const dataReducer = (state = dataState, action) => {
    switch (action.type) {
        case "PENDING_POS_REFRESH": {
            return {
                ...state,
                PENDING_POS_REFRESH: action.PENDING_POS_REFRESH
            }
        }
        case "PENDING_POS_SET": {
            return {
                ...state,
                PENDING_POS: action.data
            }
        }

        case 'CS_AGENTS_SET': {
            return {
                ...state,
                CS_AGENTS: action.data
            }
        }
        case 'CS_REQUESTS_SET': {
            return {
                ...state,
                CS_REQUESTS: action.data
            }
        }

        case 'CS_REQUEST_SET': {

            return {
                ...state,
                CS_REQUEST: action.data,
                CS_REQUESTS: {
                    ...state.CS_REQUESTS,
                    requests: state.CS_REQUESTS?.requests?.map(e => e.id === action.data.id ? action.data : e)
                }

            }
        }

        case "CLIENT_INFO_SET":

            return {
                ...state,
                CLIENT_INFO: action.CLIENT_INFO
            }


        case 'MODAL_BACKGROUND':
            return {
                ...state,
                MODAL_BACKGROUND: action.show
            }

        case "PASS_MESSAGE":
            return {
                ...state,
                message: action.message
            }
        case "FETCHING":
            return {
                ...state,
                fetching: action.fetching,
                fetching_data: action.fetching_data,
                recordId: action.recordId,
                message: action.message
            }
        case "FILE_SET":
            return {
                ...state,
                file: action.file
            }
        case "FILE_SET_SAGA":
            return {
                ...state,
                file: action.data
            }
        case "WH_FOR_RELEASE_SET":
            return {
                ...state,
                wh_for_release_list: action.data
            }
        case "VENDOR_PO_SET":
            return {
                ...state,
                vendorPO: action.vendorPO
            }
        case "WR_SET":
            return {
                ...state,
                wr: action.data
            }
        case "REPORTS_WH_SET":
            return {
                ...state,
                report_data: action.data
            }

        case "FILE_CONTACTS_UPDATE":

            return {
                ...state,
                file: {
                    ...state.file,
                    clientContacts: action.data.clientContacts,
                    whatsapp_notify: action.data.whatsapp_notify
                }
            }

        case "FILE_CONTACTS_DELETE":
            return {
                ...state,
                file: {
                    ...state.file,
                    clientContacts: state.file.clientContacts.filter(
                        c => c.id !== parseInt(action.data.deletedContactId)
                    )
                }
            }


        case "NOTIFICATIONS_CLIENTS_SET":
            return {
                ...state,
                notificationsClients: action.data.data
            }
        case "NOTIFICATIONS_CLIENT_CONTACTS_SET":
            return {
                ...state,
                notificationContacts: {
                    ...action.data
                }
            }


        case "SECTION_NAME_SET":
            return {
                ...state,
                sectionName: action.sectionName
            }
        default:
            return state;
    }

}


const rootReducer = combineReducers({
    dataReducer,
    dataReducerWR
})


export default rootReducer;