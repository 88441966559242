import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';


const NavBarTickets = ({
    activeKey,
    ticketId
}) => <Nav
    activeKey={activeKey}
    variant="pills"
>
        <Nav.Item>
            <LinkContainer to={`/admin/customer-service/`}>
                <Nav.Link href="/home">Home</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        <Nav.Item key='tickets'>
            <LinkContainer to={`/admin/customer-service/tickets/`}>
                <Nav.Link href="#">Tickets</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        {
            !!ticketId &&

            <Nav.Item key='ticket'>
                <LinkContainer to={`/admin/customer-service/tickets/${ticketId}`}>
                    <Nav.Link href="/home">Ticket {ticketId}</Nav.Link>
                </LinkContainer>
            </Nav.Item>
        }

    </Nav>

export {
    NavBarTickets
};