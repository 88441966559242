import React from "react";
import Menu from './Menu';
import Header from './Header';

import Moment from 'react-moment';
import { Circles } from "react-loader-spinner";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { IoHomeSharp, IoChevronForward, IoChevronBack } from "react-icons/io5";
import Modal from 'react-modal';

import API from '../../api/ReportsAPI';
import FileQuoteDetails from "../../app/components/FileQuoteDetails";

var formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
var formatterEUR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
});




function QuoteReports() {
    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const [reportType, setReportType] = React.useState(searchParams.get('reportType') || 'main');
    const [countryCode, setCountryCode] = React.useState(searchParams.get('countryCode'));
    const [salespersonId, setSalespersonId] = React.useState(searchParams.get('salespersonId'));
    const [fileDetails, setFileDetails] = React.useState({
        fileId: searchParams.get('fileId'),
        clientQuoteId: searchParams.get('quoteId')
    });


    const [showModal, setShowModal] = React.useState(searchParams.get('fileId') ? true : false);

    const DataFetch = async () => {
        if (loading) return;
        setLoading(true);


        const data = await API.QuotesFetch({
            reportType,
            countryCode,
            salespersonId
        });
        setLoading(false);
        setData(data);

    }

    React.useLayoutEffect(() => {

        console.log('fire effect2', new Date().getTime())
        DataFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType, countryCode, salespersonId]);





    const ReportDetails = ({
        data,
        detailType
    }) => {
        return <div style={{
            width: '100%',
            alignItems: 'flex-start'
        }}>
            <Modal
                ariaHideApp={false}
                isOpen={showModal}
                contentLabel="File"
                style={customStyles}
            >
                <div
                    style={{
                        width: '100%',
                        height: 550
                    }}
                >
                    <button
                        style={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                        }}
                        onClick={() => {
                            setShowModal(false);
                            let _searchParams = {
                                reportType,
                            }
                            if (!!countryCode) _searchParams.countryCode = countryCode;
                            if (!!salespersonId) _searchParams.salespersonId = salespersonId;

                            setSearchParams(_searchParams);

                        }}
                    >Close</button>


                    <div>
                        <h4 style={{ textAlign: 'left', padding: 0, margin: 0 }}>File {fileDetails.fileId}</h4>


                        <FileQuoteDetails
                            fileId={fileDetails.fileId}
                            quoteId={fileDetails.clientQuoteId || fileDetails.quoteId || searchParams.get('quoteId')}
                            data={fileDetails}
                        />
                    </div>


                </div>
            </Modal >
            <div
                style={{
                    padding: '10px 0px'
                }}
            >
                <Link className="div-row" onClick={() => {
                    setReportType('main');
                }} to={`/admin/reports/quotes/?`}><IoChevronBack />Back</Link>
            </div>

            {
                detailType === 'salesperson' ?
                    <div>


                        <h2>
                            {data?.data?.details && data?.data?.details[0]?.userName}
                        </h2>
                    </div>
                    :
                    <div>
                        <h2>{data?.data?.details && data?.data?.details[0]?.countryName}</h2>
                    </div>
            }
            <div style={{
                width: '100%'
            }}>
                {
                    data?.data?.details?.map((item, index) => {
                        return <div className="div-row-space-between"
                            key={index + "__"}
                            style={{
                                backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',
                                padding: 10,
                                width: '100%'
                            }} >
                            <div
                                style={{
                                    minWidth: 340
                                }}
                            >
                                <b><Moment date={item.clientQuoteDate} format="MMM DD YY" tz="America/New_York" /></b>
                                <br />
                                Quote {item.clientQuoteId}

                                <br />
                                File {item.fileId}
                                <br />
                                {item.clientName}
                                <br />
                                {detailType === 'salesperson' ? item.countryName : item.userName}
                                <div>
                                    {item.rfqName}
                                </div>
                            </div>
                            <div
                                style={{
                                    width: 150,
                                    paddingTop: 10,
                                }}
                            >
                                {!!item.quoteTotalUSD && formatterUSD.format(item.quoteTotalUSD)}

                                {!!item.quoteTotalEUR && formatterEUR.format(item.quoteTotalEUR)}

                                <div>
                                    <button
                                        style={{
                                            marginTop: 10
                                        }}
                                        onClick={() => {
                                            let _searchParams = {
                                                reportType,
                                                fileId: item.fileId,
                                                quoteId: item.clientQuoteId
                                            }
                                            if (!!countryCode) _searchParams.countryCode = countryCode;
                                            if (!!salespersonId) _searchParams.salespersonId = salespersonId;

                                            setSearchParams(_searchParams);
                                            setFileDetails(item);
                                            /* FileFetch({
                                                 fileId: item.fileId,
                                                 quoteId: item.clientQuoteId
                                             });*/
                                            setShowModal(true);
                                        }}
                                    >
                                        Details
                                    </button>
                                </div>
                            </div>

                        </div>
                    })
                }
            </div>
        </div >
    }
    const ReportMain = ({ data }) => {
        return <div className="div-row" style={{
            width: '100%',
            alignItems: 'flex-start',
            paddingTop: 10
        }}>
            <div style={{
                width: '50%'
            }}>
                <h3>Country</h3>
                {
                    data?.data?.byCountry?.map((item, index) => {
                        return <div className="div-row" key={index + "__"} style={{ backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff', padding: 10 }} >
                            <div className="div-col" style={{ width: '100%' }} >
                                <div className="div-row-space-between" >
                                    <Link
                                        onClick={() => {
                                            setReportType('details');
                                            setSalespersonId(null);
                                            setCountryCode(item.countryCode)
                                        }}
                                        to={`/admin/reports/quotes?reportType=details&countryCode=${item.countryCode}`}
                                    >{item.countryName}</Link>
                                    <div>{item.quoteCount}</div>
                                </div>
                                <div className="div-row-space-between" >
                                    <div className="div-col">
                                        {!!item.totalUSD && <div>{formatterUSD.format(item.totalUSD)}</div>}

                                        {!!item.totalEUR && <div>{formatterEUR.format(item.totalEUR)}</div>}
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <div style={{ width: '2%' }} />
            <div style={{
                width: '48%'
            }}>
                <h3>Sales Person</h3>

                {
                    data?.data?.bySalesperson?.map((item, index) => {
                        return <div className="div-row" key={index} style={{ backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff', padding: 10 }} >
                            <div className="div-col" style={{ width: '100%' }} >
                                <div className="div-row-space-between" >
                                    <Link
                                        onClick={() => {
                                            setReportType('details');
                                            setCountryCode(null);
                                            setSalespersonId(item.salesRepUserId)
                                        }}
                                        to={`/admin/reports/quotes?reportType=details&salespersonId=${item.salesRepUserId}`}>{item.userName}</Link>
                                    <div>{item.quoteCount}</div>
                                </div>
                                <div className="div-row-space-between" >
                                    <div className="div-col">
                                        {!!item.totalUSD && <div>{formatterUSD.format(item.totalUSD)}</div>}

                                        {!!item.totalEUR && <div>{formatterEUR.format(item.totalEUR)}</div>}
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }

    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Pending Quotes</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div className="navigation-bar" >
            <div className="navigation-bar-content" >
                <Link to={`/admin/reports/`}><IoHomeSharp /></Link>

                <IoChevronForward />
                Quotes
                <IoChevronForward />
                <b><Link onClick={() => {
                    setReportType('main')
                }} to={`/admin/reports/quotes/`}>Pending</Link></b>
            </div>
        </div>
        <div className="content">


            {
                loading ?
                    <div className="div-row-center" style={{ height: 300 }}>

                        <Circles height={100} width={100} />
                    </div>
                    :
                    <div>
                        {reportType === 'main' && <ReportMain data={data} />}
                        {reportType === 'details' && <ReportDetails
                            data={data}
                            detailType={!!salespersonId ? 'salesperson' : 'country'}

                        />}
                    </div>

            }







        </div>
    </div>
    );
}

export default QuoteReports;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 550
    },
    overlay: {
        background: "#00000090"
    }
};