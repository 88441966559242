const token = localStorage.getItem("token") || "";

const WHRepackAPI = {
    split: (args) => {

        return new Promise((resolve, reject) => {

            let { 
                fileId,
                width,
                length,
                height,
                weight,
                remarks,
                packageType,
                packages,
                wrIdArray,
                vendorPOId
            } = args;
 
            wrIdArray = JSON.stringify(wrIdArray);
            

            let body = JSON.stringify({
                width,
                length,
                height,
                weight,
                remarks,
                packageType,
                packages,
                wrIdArray,
                vendorPOId
            })
 

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/wr/repack/consolidate`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
                body
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHRepackAPI.consolidate", err)
                    reject(err);
                });
        })
    },
    
    consolidate_undo: (args) => {

        return new Promise((resolve, reject) => {

            let { 
                fileId,
                wrId,
                 
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/wr/repack/consolidate/${wrId}`;
 
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'DELETE',
                 
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHRepackAPI.consolidate_undo", err)
                    reject(err);
                });
        })
    },
    consolidate: (args) => {

        return new Promise((resolve, reject) => {

            let { 
                fileId,
                width,
                length,
                height,
                weight,
                remarks,
                packageType,
                packages,
                wrIdArray,
                vendorPOId
            } = args;
 
            wrIdArray = JSON.stringify(wrIdArray);
            

            let body = JSON.stringify({
                width,
                length,
                height,
                weight,
                remarks,
                packageType,
                packages,
                wrIdArray,
                vendorPOId
            })
 

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/wr/repack/consolidate`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
                body
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHRepackAPI.consolidate", err)
                    reject(err);
                });
        })
    },
}



export default WHRepackAPI;