import React from "react";
import Header from './HeaderClient';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useNavigate } from "react-router-dom";


import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import pAPI from "../../api/publicAPI";

import InvalidToken from "./InvalidToken";
import Fetching from "./Fetching";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

/* eslint-disable */
const FileUpload = ({
    preview
}) => {
    const { t } = useTranslation();

    return <Container
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 5
        }}
    >
        <label
            htmlFor="file-upload"
            style={{
                padding: 5,
                paddingRight: 10, paddingLeft: 10,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#000',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: 200,
                fontSize: 15,
                backgroundColor: '#e0e0e0',
            }}
        >
            {t('Add Files')}
        </label>
        <input
            style={{
                display: 'none'
            }}
            id="file-upload"
            type="file"
            multiple={true}
            accept="*/*"
            onChange={(event) => {
                preview(event);
            }}
        />
    </Container>

}
/* eslint-enable */
const RFQForm = ({
    phone
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let token = useParams().code;

    const [description, setDescription] = React.useState("");
    const [fetching, setFetching] = React.useState(false);

    return <div
        className="div-col"
        style={{
            width: '100%',
            maxWidth: 550,
            margin: 'auto',
        }}
    >

        <h4 style={{ textAlign: 'center', marginBottom: 10 }}>{t('RFQNew')}</h4>

        <div style={{
            textAlign: 'center',
        }}>
            <Trans i18nKey="RFQDescription" />
        </div>
        <div
            style={{
                margin: 10
            }}
        >
            <textarea
                numberOfLines={10}
                numberoflines={10}
                style={{
                    height: 150,
                    fontSize: 17,
                    width: '95%',
                    margin: 'auto'
                }}
                value={description}
                onChange={e => setDescription(e.target.value)}
            />
        </div>
        <div style={{
            textAlign: 'center',
        }}>
            <Trans i18nKey="RFQDescription2" />
        </div>

        <div
            className="div-col"
            style={{
                width: 150,
                margin: 'auto',
                marginTop: 20,
            }}
        >
            <Button
                variant="success"
                size="lg"
                disabled={fetching}
                onClick={() => {

                    if ((description || "").trim() === '') {
                        alert(t('RFQDescriptionRequired'));
                        return;
                    }

                    setFetching(true);
                    pAPI.RFQCreate({
                        token,
                        description,
                        phone
                    }).then(res => {
                        setFetching(false);
                        navigate(`/w/${token}/rfq/confirm`)
                    });

                }}
            >
                {
                    fetching ?
                        <ThreeDots
                            color="#fff"
                            height={20}
                            width={30}
                        />
                        :
                        t('Continue')
                }

            </Button>
        </div>

    </div>
}

function WhatsAppRFQ() {

    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();


    let token = useParams().code;

    const [fetching, setFetching] = React.useState(true)
    const [invalidToken, setInvalidToken] = React.useState(false);


    const {
        CLIENT_INFO,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        setFetching(true);
        pAPI.fetchV2({ token }).then(res => {
            setFetching(false);
            if (!res.success) {
                setInvalidToken(true);
            } else {
                dispatch({
                    type: 'CLIENT_INFO_SET',
                    CLIENT_INFO: res
                });
            }
        })
    }, []);
    /* eslint-enable */

    if (invalidToken) return <InvalidToken />

    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Prodek Inc - RFQ</title>
            </Helmet>
        </HelmetProvider>

        <Header
            token={token}
        />
        <Container style={{
            marginTop: 70,
            padding: 0
        }}>


            {fetching ?


                <Fetching />
                :
                <div style={{
                    marginBottom: 50,
                    padding: 5

                }}>
                    <h4  >{CLIENT_INFO.account.clientName}</h4>
                    <h4  >{CLIENT_INFO.account.contactName}</h4>

                    <hr />

                    <RFQForm
                        phone={CLIENT_INFO.account.clientContactPhone}
                    />

                </div>


            }
        </Container>


    </div>
    );
}

export default WhatsAppRFQ;