import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import Moment from 'react-moment';
import 'moment-timezone';

import { useParams } from "react-router-dom";

import { IoWarningSharp } from "react-icons/io5";

import { Circles, ThreeDots } from "react-loader-spinner";

import WHAPI from "../api/WHAPI";

import { useDispatch, useSelector } from 'react-redux';


import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width: width - 10,
        widthCol: width >= 1400 ? 600 : width >= 400 ? ((width / 2) - 20) : width,
        height
    };
}

const WHReceipts = () => {

    const dispatch = useDispatch();
    const fileId = useParams().fileId || "";
    let wrId = useParams().wrId;
    const dataReducer = useSelector((state) => state.dataReducer);
    const [quantity, setQuantity] = React.useState({});
    const [fetching, setFetching] = React.useState(false);
    const [apiMessage, setApiMessage] = React.useState();
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    const {
        file,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        if (file?.WRItems) {

            let _q = {}
            file.vendorPOItems.forEach(i => _q[i.vendorPOItemId] = "0");
            file.WRItems.filter(i => i.wrId == wrId).forEach(i => { _q[i.vendorPOItemId] = i.unitsReceived });
            setQuantity(_q);
        }

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /* eslint-enable */

    const fnSave = ({
        vendorPOId
    }) => {
        setApiMessage("Saving...")
        let units_by_vendorItem = []
        for (var i in quantity) {
            units_by_vendorItem.push({
                "vendorPOItemId": i,
                "units": quantity[i]
            })
        }
        let args = {
            fileId: file.file.fileId,
            vendorPOId,
            wrId,
            units_by_vendorItem,
        }
        setFetching(true);
        WHAPI.wrUpdatePOUnits(args).then(res => {
            setFetching(false);
            setApiMessage("");
            dispatch({
                type: "FILE_FETCH",
                NO_FETCH_INDICATOR: true,
                fileId
            })
        });

    }

    if (!file) return null;



    const FileWRFiles = () => {
        return <div style={{
            marginTop: 0,
            width: windowDimensions.widthCol,
            padding: 5,
            backgroundColor: '#fff',
        }}>

            {file.photos.filter(w => w.wrId === parseInt(wrId)).length === 0 ?
                <div style={{
                    width: 150,
                    borderRadius: 5, margin: 10, padding: 10,
                    backgroundColor: 'red', display: 'flex',
                    flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold'
                }}>NO FILES &nbsp; <IoWarningSharp size={20} /></div>
                :
                undefined
            }
            <div

                style={{
                    position: 'sticky',
                    top: 0,
                }}

            >

                {file.photos.filter(w => w.wrId === parseInt(wrId)).map((w, ix) => <div
                    key={ix}
                    style={{
                        marginTop: 10,
                    }}
                >
                    {w.isPackingList === 1 &&
                        <div style={{
                            backgroundColor: '#FF9400',
                            color: '#000',
                            padding: 5,
                            paddingRight: 10,
                            paddingLeft: 10,
                            borderColor: '#000',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderBottomStyle: 'none',
                            width: 200,
                            fontSize: 14
                        }}>
                            PACKING LIST
                        </div>}
                    <Zoom>
                        <img src={`${w.data_url}`} alt={`W/R ${w.id}`}
                            width={windowDimensions.widthCol}

                            style={{
                                borderWidth: 1,
                                borderColor: '#000',
                                borderStyle: 'solid',
                                minWidth: 200,
                            }}
                        /></Zoom>
                </div>)}
            </div>
        </div>
    }

    return <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        }}
    >
        <div>{
            file.whReceipts.filter(i => i.id === parseInt(wrId)).map((wr, ix) => {
                let showHeader = true;
                if (ix > 0) {
                    showHeader = (wr.vendorPOId !== file.whReceipts[ix - 1].vendorPOId)
                }
                return <div
                    key={ix}
                    style={{
                        margin: 'auto',
                        width: windowDimensions.widthCol,
                        padding: 5
                    }}
                >
                    {showHeader && <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 5,
                            backgroundColor: '#e0e0e0'
                        }}
                    >
                        <span style={{ fontWeight: 'bold' }}>Vendor PO# {wr.vendorPONumber}</span>

                    </div>
                    }
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div

                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 5
                            }}
                        >

                            <Moment date={wr.receivedDateTime} format="MMM-DD HH:mm" tz="America/New_York" />
                            <span>{wr.packages} {wr.packageType}</span>

                        </div>

                    </div>

                    <div>

                        {file.vendorPOItems.filter(item => item.vendorPOId === wr.vendorPOId).map((item, ix) => {

                            return <div key={ix}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',

                                    padding: 5,
                                    paddingTop: 10,
                                    borderTopWidth: ix === 0 ? 1 : 0, borderTopColor: '#c0c0c0', borderTopStyle: 'solid',
                                    borderBottomWidth: 1, borderBottomColor: '#c0c0c0', borderBottomStyle: 'solid',
                                    borderRightWidth: 1, borderRightColor: '#c0c0c0', borderRightStyle: 'solid',

                                }}
                            >
                                <div ><span style={{ color: 'blue', fontWeight: 'bold' }}>{ix + 1})</span> {item.vendorPOItemQuantity} x {item.vendorPOItemPartNumber}</div>
                                <span style={{ fontSize: 12 }}>{item.vendorPOItemDescription}</span>
                                <label style={{
                                    padding: 10,
                                    display: 'flex', flexDirection: 'row', alignItems: 'center'
                                }}>
                                    Received
                                    &nbsp;
                                    <input
                                        style={{
                                            width: 50,
                                            fontSize: 17
                                        }}
                                        value={quantity[item.vendorPOItemId] || ""}
                                        onChange={e => {
                                            let _q = { ...quantity };
                                            _q[item.vendorPOItemId] = e.target.value.replace(/\D/g, '');
                                            setQuantity(_q);
                                        }}
                                        type="number"
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                        <div style={{ margin: 5, marginBottom: 0, marginLeft: 10, }}>
                                            <span style={{ fontSize: 10 }}>Vendor PO</span><br />
                                            {item.vendorPOItemQuantity}
                                        </div>
                                        <div  >
                                            <span style={{ fontSize: 10 }}>Client PO</span><br />
                                            {item.clientPOItemQuantity}
                                        </div>
                                    </div>
                                    <div style={
                                        {
                                            marginLeft: 10, paddingRight: 10, paddingLeft: 10,
                                            minWidth: 30,
                                            textAlign: 'center',
                                            backgroundColor: item.vendorPOItemQuantity === item.unitsInWRIdentified ? 'black' : 'red',
                                            color: '#fff',
                                        }
                                    }>{item.vendorPOItemQuantity - item.unitsInWRIdentified}</div>

                                </label>
                            </div>
                        })}
                    </div>
                    <div
                        style={{
                            padding: 10,
                            position: "sticky",
                            bottom: 0,
                            marginBottom: 5,
                            backgroundColor: '#c0c0c0',
                            width: 370,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Styles.ButtonMain
                            disabled={fetching}
                            onClick={() => fnSave({
                                vendorPOId: wr.vendorPOId
                            })}
                            style={{ width: 200 }}>
                            {fetching ?
                                <ThreeDots
                                    height={10}
                                />
                                :
                                "Save"
                            }
                        </Styles.ButtonMain>
                        <div style={{ width: 200 }}>{apiMessage}</div>
                    </div>

                </div>
            })
        }</div>
        <FileWRFiles />
    </div >

}





const WHReceiptContent = () => {

    let wrId = useParams().wrId;
    const dispatch = useDispatch();
    const fileId = useParams().fileId || "";

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;



    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "FILE_FETCH",
            fileId
        })
    }, []);
    /* eslint-enable */
    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            maxWidth: 1500
        }}>

            <div>
                <a href={`/admin/wh/file/${fileId}`} >File {fileId}</a> | <a href={`/admin/wh/file/${fileId}/wr/${wrId}`} >WR {fileId}{wrId}</a>
            </div>
            <Styles.H1 style={{ textAlign: 'center' }} >W/R {fileId}{wrId} </Styles.H1>

            {fetching ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={50}
                    />
                </div>
                :

                <WHReceipts />

            }



        </div>
    </div>
    );
}

export default WHReceiptContent;