import React, { Suspense, lazy } from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { Provider } from 'react-redux';
import { AuthProvider } from './hooks/useAuth.jsx';
import { CookiesProvider } from 'react-cookie';
import store from './app/store'

import App from './App';
import reportWebVitals from './reportWebVitals';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Login from './routes/Login';
import WAPFileDetailsSummary from './routes/WAPFileDetailsSummary';

import AdminMain from './routes/AdminMain';
import WHMain from './routes/WHMain';

import WHReceive from './routes/WHReceive';
import WRReceiveV2 from './routes/WRReceiveV2';
import WRReceiveV2WR from './routes/WRReceiveV2WR';


//import WHReceiveWRs from './routes/WHReceiveWRs';
import WHRPhotos from './routes/WHRPhotos';
import WHRLabels from './routes/WHRLabels';

import FileWarehouse from './routes/FileWarehouse';
import WHFileRelease from './routes/WHFileRelease';
import WHFileRepack from './routes/WHFileRepack';
import WHReceiptContent from './routes/WHReceiptContent';

import WHRelease from './routes/WHRelease';
import WR from './routes/WR';
import WRRelease from './routes/WRRelease';


import WHReleased from './routes/WHReleased';

import QRReader from './routes/QRReader';

import InTransit from './routes/InTransit';


import Invoice from './routes/Invoice';
import ReportsWH from './routes/ReportsWH';


import SignUp from './routes/SignUp';
import SignUpConfirmation from './routes/SignUpConfirmation';


import NotificationsSent from './routes/NotificationsSent';
import NotificationsMain from './routes/NotificationsMain';





//import WhatsAppLanding from './routes/WhatsAppLanding';

import WhatsAppRFQ from './routes/Client/WhatsAppRFQ';
import WhatsAppRFQConfirm from './routes/Client/WhatsAppRFQConfirm';

import ClientQuotes from './routes/Client/Quotes';
import ClientPurchaseOrders from './routes/Client/PurchaseOrders';
import ClientInvoices from './routes/Client/Invoices';
import ClientLandingPage from './routes/Client/LandingPage';

import PosVendorQuotes from './routes/Pos_VendorQuotes';


import CustomerServiceMain from './routes/CustomerService/index';
import CustomerServiceClients from './routes/CustomerService/Clients';
import CSClientPage from './routes/CustomerService/Client';
import CustomerServiceTickets from './routes/CustomerService/Tickets';
import CustomerServiceTicket from './routes/CustomerService/Ticket';
import CustomerServiceSetup from './routes/CustomerService/Setup';
import AgentDetails from './routes/CustomerService/AgentDetails';

import Reports from './routes/Reports/index';
import ReportsMonthly from './routes/Reports/Monthly';
import ReportsQuotes from './routes/Reports/Quotes';
import ReportsPOSPending from './routes/Reports/PurchaseOrdersPending';

import ReportsPOSDelivered from './routes/Reports/PurchaseOrdersDelivered';


import CSClientsActiveFiles from './routes/CustomerService/ClientsActiveFiles';
import CSClientsActiveFilesContacts from './routes/CustomerService/ClientActiveFilesContacts';



const NotificationsPending = lazy(() => import('./routes/NotificationsPending'));


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "FirstName": "First Name",
          "LastName": "Last Name",
          "Email": "Email",
          "CountryCode": "Country Code",
          "Country": "Country",
          "CellPhone": "Cell Phone",
          "Company": "Company Name",
          "selectIndustry": "Select Industry",
          "Continue": "Continue",


          "Signup": "Please sign up and we will contact you shortly.",
          "SignUpConfirmation": "Thank you for signing up with<br/>Prodek Inc.",
          "SignUpAccountHadBeenCreated": "Your account has been created.<br/>We will contact you shortly",

          "Quotes": "Quotes",
          "POSList": "POs",
          "Invoices": "Invoices",

          "RFQDescription": "Enter your request for quote description",
          "RFQDescription2": "Send any additional files to<br/>our WhatsApp number",
          "RFQDescriptionRequired": "Please enter a description for your request for quote.",
          "AddFiles": "Add Files",

          "RFQList": "Request for Quotes",
          "RFQNew": "New Request for Quote",
          "RFQNew2": "New RFQ",
          "RFQConfirm": `Your request for quote has been received.<br/><br/>We will contact you shortly.<br/><br/>You can send additional information or supporting files<br/>to our WhatsApp number<br/><br/>`,

          "Account": "My Account",

          "ClientLandingPageInstructions": `Select from the following options`,

          "ExpiredLink": `This link has expired. Please message us to request a new link.`,
          "Quoted": "Quoted",
          "Details": "Details",

          "Check Delivery Status": "Check Delivery Status",
          "Accept": "Accept",
          "Reject": "Reject",
          "Close": "Close",
          "forSupport": "For additional support please contact us at our WhatsApp number",
          "Requested To Vendor": "In Process",
          "Search": "Search",
          "POSList_full": "Purchase Orders",
          "quote_accept_confirm": "Are you sure you want to accept this quote?<br/><br/>You need to send a purchase order to the vendor to complete the process.",
          "quote_reject_confirm": "Please select the reason for rejecting this quote",
          "quote_no_response_confirm": "Are you sure you want to mark this quote as no response?",
          'Accepted': 'Accepted',
          "Rejected": "Rejected",
          "No Response": "No Response",
          "followups": "Followups",
        }
      },
      es: {
        translation: {
          "FirstName": "Nombre",
          "LastName": "Apellido",
          "Email": "Correo Electrónico",
          "CountryCode": "Código País",
          "Country": "País",
          "cellPhone": "Teléfono Celular",
          "Company": "Nombre Compañía",
          "selectIndustry": "Seleccione Industria",
          "Continue": "Continuar",

          "Signup": "Por favor, regístrese y nos pondremos en contacto con usted en breve.",
          "SignUpConfirmation": "Gracias por registrarte en<br/>Prodek Inc.",
          "SignUpAccountHadBeenCreated": "Su cuenta ha sido creada.",

          "POSList": "POs",
          "Quotes": "Cotizaciones",
          "Invoices": "Facturas",

          "RFQNew2": "Nueva RFQ",
          "RFQDescription": "Ingrese la descripción de su solicitud de cotización",
          "RFQDescription2": "Envíe cualquier archivo adicional a<br/>nuestro número de WhatsApp",
          "RFQDescriptionRequired": "Por favor, ingrese una descripción de su solicitud de cotización.",
          "AddFiles": "Agregar Archivos",

          "RFQNew": "Nueva Solicitud de Cotización",
          "RFQList": "Solicitudes de Cotización",
          "RFQConfirm": `<strong>Gracias!</strong><br/><br/>Su solicitud de cotización ha sido recibida. 
          <br/><br/>
          Estaremos en contacto<br/>con usted en breve.
          <br/><br/>
          Puede enviar más detalles o archivos sobre su solicitud
          <br/>
          a nuestro número de WhatsApp
          `,


          "Account": "Mi Cuenta",

          "ClientLandingPageInstructions": "Seleccione una de las siguientes opciones",

          "Details": "Detalles",
          "Quoted": "Cotizado",
          "Delivered": "Entregado",
          "Check Delivery Status": "Revisar Status de Entrega",

          "ExpiredLink": `Este link ha expirado. Por favor, envíenos un mensaje para recibir un nuevo link.`,
          "Accept": "Aceptar",
          "Reject": "Rechazar",
          "Close": "Cerrar",
          "forSupport": "Para cualquier otro tema, por favor contáctenos a nuestro número de WhatsApp",
          "Requested To Vendor": "En Proceso",
          "Search": "Buscar",
          "POSList_full": "Ordenes de Compra",
          "quote_accept_confirm": "¿Está seguro que desea aceptar esta cotización?<br/><br/>Recuerde que debe enviar la orden de compra física para que sea procesada.",
          "quote_reject_confirm": "Por favor seleccione la razón por la cual está rechazando esta cotización.",
          "quote_no_response_confirm": "¿Está seguro que desea no responder esta cotización?",
          'Accepted': 'Aceptada',
          "Rejected": "Rechazada",
          "No Response": "Sin Respuesta",
          "followups": "Seguimientos",
        }
      }
    },
    //lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(document.getElementById("root"));

let authed = (!localStorage.getItem("token") || localStorage.getItem('token') !== "undefined");

root.render(
  <React.StrictMode>

    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Suspense fallback={<div style={{ padding: 20, textAlign: 'center' }}>Loading...</div>}>

        <Provider store={store}>
          <CookiesProvider>
            <BrowserRouter>
              <AuthProvider>
                <Routes>

                  <Route path='/qr/:fileId/:wrId'
                    element={<QRReader />}
                  />

                  <Route path="/a" element={<SignUp />} />
                  <Route path="/a/:token" element={<SignUp />} />
                  <Route path="/ac/:token" element={<SignUpConfirmation />} />
                  <Route path="/" element={<Login />} />
                  <Route path="/login/" element={<Login />} />

                  <Route path='/prodek/:fileId'
                    element={<WAPFileDetailsSummary />}
                  />




                  <Route path='/w/:code/rfq'
                    element={<WhatsAppRFQ />}
                  />
                  <Route path='/w/:code/rfq/confirm'
                    element={<WhatsAppRFQConfirm />}
                  />



                  <Route path='/w1/:code'
                    element={<ClientLandingPage />}
                  />
                  <Route path='/w2/:code'
                    element={<ClientQuotes />}
                  />
                  <Route path='/w3/:code'
                    element={<ClientPurchaseOrders />}
                  />
                  <Route path='/w4/:code'
                    element={<ClientInvoices />}
                  />





                  <Route path='/admin/reports/' element={
                    authed
                      ?
                      <Reports />
                      :
                      <Login />
                  } />
                  <Route path='/admin/reports/monthly/' element={
                    authed
                      ?
                      <ReportsMonthly />
                      :
                      <Login />
                  } />
                  <Route path='/admin/reports/quotes/' element={
                    authed
                      ?
                      <ReportsQuotes />
                      :
                      <Login />
                  } />


                  <Route path='/admin/reports/pos/pending/' element={
                    authed
                      ?
                      <ReportsPOSPending />
                      :
                      <Login />
                  } />


                  <Route path='/admin/reports/pos/delivered/' element={
                    authed
                      ?
                      <ReportsPOSDelivered />
                      :
                      <Login />
                  } />


                  <Route path='/admin/customer-service/' element={
                    authed
                      ?
                      <CustomerServiceMain />
                      :
                      <Login />
                  } />


                  <Route path='/cs/:userId' element={
                    authed
                      ?
                      <CustomerServiceTickets />
                      :
                      <Login />
                  } />
                  <Route path='/admin/customer-service/tickets' element={
                    authed
                      ?
                      <CustomerServiceTickets />
                      :
                      <Login />
                  } />


                  <Route path='/admin/customer-service/tickets/:id' element={
                    authed
                      ?
                      <CustomerServiceTicket />
                      :
                      <Login />
                  } />



                  <Route path='/admin/customer-service/clients/' element={
                    authed
                      ?
                      <CustomerServiceClients />
                      :
                      <Login />
                  } />


                  <Route path='/admin/customer-service/clients/active-files/' element={
                    authed
                      ?
                      <CSClientsActiveFiles
                      />
                      :
                      <Login />
                  } />


                  <Route path='/admin/customer-service/clients/active-files/:clientId/contacts' element={
                    authed
                      ?
                      <CSClientsActiveFilesContacts
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/customer-service/clients/:clientId' element={
                    authed
                      ?
                      <CSClientPage />
                      :
                      <Login />
                  } />

                  <Route path='/admin/customer-service/setup/agent/:agentId' element={
                    authed
                      ?
                      <AgentDetails />
                      :
                      <Login />
                  } />

                  <Route path='/admin/customer-service/setup' element={
                    authed
                      ?
                      <CustomerServiceSetup />
                      :
                      <Login />
                  } />


                  <Route path='/admin/pos/vendor_pos' element={
                    authed
                      ?
                      <PosVendorQuotes
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/release' element={
                    authed
                      ?
                      <WHRelease
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/released' element={
                    authed
                      ?
                      <WHReleased
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh' element={
                    authed
                      ?
                      <WHMain
                      />
                      :
                      <Login />
                  } />


                  <Route path='/admin/wh/inTransit' element={
                    authed
                      ?
                      <InTransit
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/file/:fileId/wr/:wrId' element={
                    authed
                      ?
                      <WR
                      />
                      :
                      <Login />
                  } />
                  <Route path='/admin/wh/file/:fileId/wr/:wrId/release' element={
                    authed
                      ?
                      <WRRelease
                      />
                      :
                      <Login />
                  } />



                  <Route path='/admin/wh/file/:fileId/wr/:wrId/idw' element={
                    authed
                      ?
                      <WHReceiptContent
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/file/:fileId' element={
                    authed
                      ?
                      <FileWarehouse
                      />
                      :
                      <Login />
                  } />
                  <Route path='/admin/wh/file/:fileId/release' element={
                    authed
                      ?
                      <WHFileRelease
                      />
                      :
                      <Login />
                  } />
                  <Route path='/admin/wh/file/:fileId/repack' element={
                    authed
                      ?
                      <WHFileRepack
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/receive' element={
                    authed
                      ?
                      <WHReceive
                      />
                      :
                      <Login />
                  } />



                  <Route path='/admin/wh/vendorPO/:vendorPOId/receiveV2/' element={
                    authed
                      ?
                      <WRReceiveV2
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/vendorPO/:vendorPOId/receiveV2/:fileId/:wrId' element={
                    authed
                      ?
                      <WRReceiveV2WR
                      />
                      :
                      <Login />
                  } />


                  <Route path='/admin/wh/:vendorPOId/photos' element={
                    authed
                      ?
                      <WHRPhotos
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/wh/:fileId/labels/:wrId' element={
                    authed
                      ?
                      <WHRLabels
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/' element={
                    authed
                      ?
                      <AdminMain
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/file/:fileId/invoice/:invoiceId' element={
                    authed
                      ?
                      <Invoice
                      />
                      :
                      <Login />
                  } />


                  <Route path='/admin/wh/reports' element={
                    authed
                      ?
                      <ReportsWH
                      />
                      :
                      <Login />
                  } />


                  <Route path='/admin/notifications/' element={
                    authed
                      ?
                      <NotificationsMain
                      />
                      :
                      <Login />
                  } />



                  <Route path='/admin/notifications/pending' element={
                    authed
                      ?
                      <NotificationsPending
                      />
                      :
                      <Login />
                  } />

                  <Route path='/admin/notifications/sent' element={
                    authed
                      ?
                      <NotificationsSent
                      />
                      :
                      <Login />
                  } />





                  <Route
                    path="*"
                    element={<App />}
                  />
                </Routes>
              </AuthProvider>
            </BrowserRouter>

          </CookiesProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>
);





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
