


import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';

import { Circles } from "react-loader-spinner";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import notificationsAPI from '../api/notificationsAPI';

import Moment from 'react-moment';
import 'moment-timezone';

const List = ({ data }) => {

    return <div
        style={{
            width: '100%',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#000',
            borderBottomStyle: 'none'
        }}
    >

        {data.map((d, ix) => <div
            key={ix}
            style={{
                backgroundColor: ix % 2 ? '#c0c0c0' : '#fff',
                padding: 5,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: '#000'
            }}
        >
            <div><Moment date={d.sentDT} format="MMM-DD HH:mm" tz="America/New_York" /></div>
            <div>{d.name}</div>
            <div style={{ color: 'blue' }}>{d.phone}</div>
            <div>{d.clientName} File {d.fileId}</div>
            <div style={{
                margin: 10,
                padding: 10,
                paddingTop: 0, paddingBottom: 0,
                fontSize: 12,
                borderLeftColor: '#000',
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                width: 330
            }}>
                {d.body}
            </div>
        </div>)

        }

    </div>
}

function NotificationsPending() {

    //let clientId = useParams().clientId || "";
    const [fetching2, setFetching2] = React.useState(false)
    const [data, setData] = React.useState([])


    /* eslint-disable */
    React.useEffect(() => {
        setFetching2(true);
        notificationsAPI.notificationsFetch({
            reportType: 'sent'
        }).then(res => {
            setFetching2(false);
            setData(res.data)
        });
    }, []);
    /* eslint-enable */
    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Pending Notifications</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: '100%',
            maxWidth: 650,
            minWidth: 350
        }}>

            <a style={{ color: 'blue' }} href="/admin/notifications/">Notifications</a>
            <a style={{ color: 'blue' }} href="/admin/notifications/pending/">Pending</a>

            <Styles.H1 >Notifications Sent</Styles.H1>


            {fetching2 ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={50}
                    />
                </div>
                :

                <div
                    style={{
                        width: '100%'
                    }}
                >


                    <List data={data} />
                </div>


            }



        </div>
    </div>
    );
}

export default NotificationsPending;