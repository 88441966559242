import React from "react";
import Header from './HeaderClient';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useParams, useNavigate } from "react-router-dom";


import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import pAPI from "../../api/publicAPI";

import InvalidToken from "./InvalidToken";
import Fetching from "./Fetching";
import ClientMenu from "./ClientMenu";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Lists from "./Lists";
const { Quotes } = Lists;

function ClientQuotes() {

    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    let token = useParams().code;

    const [fetching, setFetching] = React.useState(true)
    const [invalidToken, setInvalidToken] = React.useState(false);


    const {
        CLIENT_INFO,
    } = dataReducer;

    /* eslint-disable */
    React.useLayoutEffect(() => {

        setFetching(true);
        pAPI.fetchV2({ token }).then(res => {
            setFetching(false);
            if (!res.success) {
                setInvalidToken(true);
            } else {
                dispatch({
                    type: 'CLIENT_INFO_SET',
                    CLIENT_INFO: res
                });
            }
        })
        dispatch({
            type: 'SECTION_NAME_SET',
            sectionName: t('Quotes')
        });
        return () => {
            dispatch({
                type: 'SECTION_NAME_SET',
                sectionName: ''
            })
        }
    }, []);
    /* eslint-enable */




    if (fetching) return <div> <Header token={token} /><Fetching /></div>
    if (invalidToken) return <InvalidToken />

    return <div>
        <HelmetProvider>
            <Helmet>
                <title>{CLIENT_INFO?.account?.clientName}</title>
            </Helmet>
        </HelmetProvider>


        <Header token={token} />



        <Container

            className="container-main"
        >
            <div
                className="div-col"
                style={{
                    margin: 'auto',
                    alignItems: 'center'
                }}
            >

                <h4 style={{ marginBottom: 5 }} >{CLIENT_INFO.account.clientName}</h4>


                <ClientMenu
                    option="quotes"
                    token={token}
                />

                <Button

                    onClick={() => {
                        navigate(`/w/${token}/rfq`)
                    }}
                    variant="outline-primary"

                >
                    {t('RFQNew')}
                </Button>
                <br />
                <Quotes
                    token={token}
                />
            </div>
        </Container>
    </div>

}


export default ClientQuotes;