const token = localStorage.getItem("token") || "";

const FileAPI = {

    vendorQuotesFetch: (args) => {

        return new Promise((resolve, reject) => {

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/vendorQuotes`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    statusId: args.statusId,
                    vendorName: args.vendorName,
                    clientName: args.clientName,
                    search: args.search,
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.inventoryFetch", err)
                    reject(err);
                });
        })
    },


    whForReleaseFetch: (args) => {

        return new Promise((resolve, reject) => {

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/releaseAuthorized`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
    fileFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRKFileAllDetailsGet
        //SP CALL file_fetch
        let {
            fileId
        } = args;

        return new Promise((resolve, reject) => {

            let url = ` https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/files/${fileId}`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

}

export default FileAPI;