import React, { useRef } from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import { useParams, useSearchParams, Link } from "react-router-dom";
import Moment from 'react-moment';
import 'moment-timezone';
import { IoWarningSharp, IoCameraOutline, IoPencilSharp } from "react-icons/io5";
import { Circles, ThreeDots } from "react-loader-spinner";

import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas'

import WHAPI from "../api/WHAPI";

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import ImageUploading from 'react-images-uploading';


function resizeBase64Img(base64Str) {
    return new Promise((resolve, reject) => {
        const MAX_WIDTH = 600;
        const MAX_HEIGHT = 450;
        var img = new Image();
        img.src = base64Str;
        img.onload = () => {
            var canvas = document.createElement('canvas');

            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            resolve({
                data_url: canvas.toDataURL(),
                w: width,
                h: height
            });
        }
    });
}

const WRS = () => {
    const dispatch = useDispatch();
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;
    const [editWR, setEditWR] = React.useState({});

    const sigCanvas = useRef({});
    const clearCanvas = () => sigCanvas.current.clear();
    const signatureGet = () => sigCanvas.current.getCanvas().toDataURL("image/png");


    const WrDetails = ({ wr }) => <div
        style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}
    >

        <Link style={{ color: 'blue', marginLeft: 5 }} to={`/admin/wh/file/${file.file.fileId}/wr/${wr.id}`} >W/R {file.file.fileId}{wr.id}</Link>

        <div
            style={{
                padding: 5,
                backgroundColor: '#e0e0e0',
                marginTop: 10,
                marginBottom: 10
            }}
        >
            <div >
                {file?.wr_packages.filter(p => p.wrId === wr.id).map((pck, ix) => <div key={ix}>
                    <div>{pck.p} {pck.ptype}</div>
                    <div style={{ marginLeft: 10 }}>{pck.rm}</div>
                </div>)}

            </div>

            <b>Remarks</b>
            <div
                style={{
                    padding: 5,
                    marginLeft: 5,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 1,
                    borderLeftColor: '#c0c0c0'
                }}
            >
                <div>{wr.remarks}</div>
                <div>{wr.releaseAuthorizedRemarks}</div>
            </div>
        </div>
        {file.WRItems.filter(i => i.wrId === wr.id).length === 0 ?
            <div style={{ margin: 5, color: 'red', display: 'flex', flexDirection: 'row' }}><IoWarningSharp size={17} />&nbsp;Contents Not Available</div>
            :
            <div
                style={{
                    padding: 5
                }}
            >
                <b>Contents</b>
                <div
                    style={{
                        borderColor: '#000',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        marginBottom: 10,
                        borderBottomStyle: 'none'
                    }}
                >

                    {
                        file.WRItems.filter(i => i.wrId === wr.id).map((item, ix) => <div
                            key={ix}
                            style={{
                                borderBottomColor: '#000', borderBottomWidth: 1, borderBottomStyle: 'solid',
                                padding: 5
                            }}
                        >

                            <div>
                                <span>{item.unitsReceived} of {item.unitsOrdered} </span>
                                <span>{item.partNo}</span>
                            </div>
                            {item.invoiceId === null ?
                                <div style={{ color: 'red', }}>Not Invoiced</div>
                                :
                                <div style={{ color: 'green' }}>Invoice {item.invoiceId}</div>
                            }
                        </div>)}

                </div>
            </div>
        }
        <div
            style={{
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}
        >
            {file.photos.filter(p => p.wrId === editWR.id).map((photo, ix) => <div
                key={ix}
            >
                <Zoom key={ix}>
                    <img alt="inventory" src={photo.data_url} style={{
                        borderWidth: 1,
                        borderColor: '#000',
                        borderStyle: 'solid',
                        width: 100,
                        minHeight: 50,
                        marginRight: 10
                    }} />
                </Zoom>
            </div>)}
        </div>

    </div>

    const WRList = ({
        wrList,
    }) => <div
        style={{
            width: '100%',
            maxWidth: 600,
            paddingTop: 20
        }}
    >


            {

                wrList.map((wr, ix) => {

                    return <div
                        key={ix}
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            borderWidth: 1, borderColor: '#000', borderStyle: 'solid',
                            borderTopStyle: ix === 0 ? 'solid' : 'none'
                        }}
                    >
                        <div style={{
                            padding: 10,
                            backgroundColor: ix % 2 ? '#fff' : '#e0e0e0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                setSearchParams({ wr: wr.id })
                                setEditWR(wr);
                            }}
                        >

                            <div style={{ color: 'blue', flex: 2 }}>W/R {file.file.fileId}{wr.id}</div>
                            {wr.released === 1 ?
                                <div style={{ color: 'blue', flex: 5 }}>
                                    Released
                                    <br />
                                    <Moment date={wr.releasedDT} format="MMM-DD HH:mm" tz="America/New_York" />

                                </div>

                                :
                                wr.releaseAuthorized === 1 ?
                                    <div style={{ color: 'green', flex: 5 }}>
                                        Authorized for Release
                                        <div>{wr.releaseAuthorizedRemarks} </div>
                                        <Moment date={wr.releaseAuthorizedDT} format="MMM-DD HH:mm" tz="America/New_York" />

                                    </div>
                                    :
                                    wr.poOrderItemsCount > 0 ? <div style={{ color: 'red', flex: 5 }}>
                                        No Release Authorization
                                    </div>
                                        :
                                        <div style={{ color: 'red', flex: 5 }}>
                                            Not Ready
                                        </div>
                            }

                        </div>



                    </div>
                })}
        </div>


    const EditWR = ({
        wr,
    }) => {

        const [ref, setRef] = React.useState();
        const [fetching_2, setFetching_2] = React.useState(false);
        const [captureSignature, setCaptureSignature] = React.useState(false);
        const [signature, setSignature] = React.useState(null);
        const [images, setImages] = React.useState();


        const onChange = (
            imageList,
            addUpdateIndex
        ) => {

            let randomid = Math.floor(Math.random() * 100000);


            resizeBase64Img(imageList[addUpdateIndex].data_url).then(res => {
                imageList[addUpdateIndex].data_url = res.data_url;
                imageList[addUpdateIndex].w = res.width;
                imageList[addUpdateIndex].h = res.height;
                imageList[addUpdateIndex].photoId = randomid;
                setImages(imageList);
            });
        };

        const PhotoDelete = ({ id }) => {
            setImages(undefined);
        }

        const SignaturePad = () => {
            /*if (signature !== null){
                setTimeout(() => {
                    sigCanvas.current.fromDataURL(signature);
                })
               
             }*/
            return <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 10
                    }}
                >
                    Signature

                    <button onClick={clearCanvas}>Clear</button>
                </div>
                <div
                    style={{
                        borderStyle: 'solid',
                        borderColor: '#000',
                        borderWidth: 1,
                        backgroundColor: '#e0e0e0',
                        width: 350
                    }}
                >
                    <SignatureCanvas
                        penColor='#000000'
                        ref={sigCanvas}
                        canvasProps={{ height: 180, width: 350, className: 'sigCanvas' }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10
                    }}
                >
                    <Styles.ButtonMain
                        onClick={() => {
                            if (sigCanvas.current.isEmpty()) {
                                setSignature(null);
                            } else {
                                setSignature(signatureGet());
                            }

                            setCaptureSignature(false)
                        }}
                    >
                        Accept
                    </Styles.ButtonMain>

                    <button onClick={() => setCaptureSignature(false)}>Cancel</button>
                </div>
            </div>
        }

        const DataUpdate = async ({
            statusUpdate,
            undo,
            remarks,
            signature,
            license
        }) => {
            setFetching_2(true);

            let args = {
                fileId: file.file.fileId,
                wrId: wr.id,
                statusUpdate,
                signature,
                license: license !== undefined ? license.data_url : null,
                undo,
                remarks
            }

            let res = await WHAPI.wrReleaseStatusUpdate(args);

            setFetching_2(false);
            if (res.success) {
                dispatch({
                    type: "FILE_FETCH",
                    fileId: file.file.fileId
                })
            } else {
                alert(res.msg)
            }
        }


        if (captureSignature) return <SignaturePad />


        return <div
            style={{
                width: '100%',
                maxWidth: 500,
            }}
        >

            <div
                onClick={() => {
                    setSearchParams({ wr: 0 })
                    setEditWR(null);
                }}
                style={{
                    textAlign: 'center',
                    marginBottom: 10,
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer'
                }}
            >
                W/R Status List
            </div>



            {editWR !== null && editWR.poOrderItemsCount > 0 && editWR.releaseAuthorized === 0 &&
                <div style={{
                    marginBottom: 10,
                    padding: 5
                }}> <label>
                        Release Instructions<br />
                        <textarea
                            style={{
                                width: 320,
                                fontSize: 17
                            }}
                            type="textarea"
                            value={ref || ""}
                            onChange={e => {
                                setRef(e.target.value)
                            }}
                        />
                    </label>
                    <Styles.ButtonMain
                        disabled={fetching_2}
                        style={{
                            width: 200
                        }}
                        onClick={() => {
                            DataUpdate({
                                statusUpdate: 'releaseAuthorize',
                                remarks: ref
                            }).then(res => {
                                setEditWR(null);
                            });
                        }}
                    >
                        {fetching_2 ?
                            <ThreeDots
                                width={50}
                                color={"#fff"}
                            />
                            :
                            "Authorize Release"
                        }

                    </Styles.ButtonMain>
                </div>
            }
            {editWR.releaseAuthorized === 1 && editWR.released === 0 &&
                <div style={{
                    marginBottom: 10,
                    padding: 5
                }}>
                    <b>Release Instructions</b>
                    <div
                        style={{
                            padding: 10,
                            marginLeft: 5,
                            borderLeftStyle: 'solid',
                            borderLeftWidth: 1,
                            borderLeftColor: '#c0c0c0'
                        }}
                    >
                        {editWR.releaseAuthorizedRemarks}
                    </div>
                    <label>
                        Release Remarks <br />
                        <textarea
                            style={{
                                width: 330,
                                fontSize: 17
                            }}
                            type="textarea"
                            value={ref || ""}
                            onChange={e => {
                                setRef(e.target.value)
                            }}
                        />
                    </label>

                    {signature !== null &&
                        <div
                            style={{
                                height: 180, width: 350,
                                cursor: 'pointer',
                                borderStyle: 'solid', borderWidth: 1, borderColor: '#000'
                            }}
                        >
                            <img src={signature} width={350} height={180} alt="Signature" />
                        </div>


                    }



                    <ImageUploading
                        multiple={false}
                        value={images}
                        onChange={onChange}
                        maxNumber={3}
                        dataURLKey="data_url"

                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                        }) => (
                            <div style={{
                                marginTop: 5
                            }}>
                                <Styles.ButtonSecondary
                                    style={{
                                        width: 150,
                                        backgroundColor: signature !== null ? '#c0c0c0' : 'blue'
                                    }}
                                    onClick={() => {
                                        setCaptureSignature(true);
                                    }}
                                >
                                    <IoPencilSharp />
                                    &nbsp;
                                    Signature
                                </Styles.ButtonSecondary>

                                {imageList.map((image, index) => (
                                    <div key={index} className="image-item" style={{ marginTop: 10, marginBottom: 20 }}>
                                        <div style={{
                                            position: 'relative',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>

                                            <Zoom>
                                                <img src={image['data_url']}
                                                    width={image['w']}
                                                    height={image['h']}

                                                    alt="License"
                                                    style={{
                                                        borderWidth: 1,
                                                        borderColor: '#000',
                                                        borderStyle: 'solid',
                                                        maxWidth: 250,
                                                    }}
                                                />
                                            </Zoom>

                                        </div>

                                        <div style={{ marginTop: 0 }}>
                                            <button onClick={() => {
                                                if (window.confirm("Are you sure you wish to delete the license photo?")) {
                                                    PhotoDelete({
                                                        id: images[index].id
                                                    });
                                                    onImageRemove(index);
                                                }
                                            }}>Remove</button>
                                        </div>
                                    </div>
                                ))}


                                <Styles.ButtonSecondary
                                    style={{
                                        marginTop: 10,
                                        width: 150
                                    }}
                                    onClick={() => {
                                        onImageUpload()
                                    }}
                                    {...dragProps}
                                >
                                    <IoCameraOutline />
                                    &nbsp;
                                    License
                                </Styles.ButtonSecondary>
                            </div>
                        )}
                    </ImageUploading>



                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 10
                        }}
                    >

                        <Styles.ButtonMain
                            style={{
                                width: 210,
                            }}
                            onClick={() => {



                                if (signature === null || images === undefined) {

                                    alert("Signature and license is required")
                                    return;
                                }
                                if (!window.confirm("Are you sure you wish to release?")) {
                                    return;
                                }

                                DataUpdate({
                                    statusUpdate: 'release',
                                    undo: false,
                                    remarks: ref,
                                    signature,
                                    license: images[0]
                                }).then(res => {
                                    setEditWR(null);
                                });

                            }}
                        >
                            {fetching_2 ?
                                <ThreeDots
                                    width={50}
                                    color={"#fff"}
                                />
                                :
                                "Release W/R"
                            }
                        </Styles.ButtonMain>

                    </div>

                </div>
            }

            {editWR.released === 1 &&
                <div style={{
                    backgroundColor: '#c0c0c0', textAlign: 'center', borderRadius: 5, marginBottom: 10,
                    padding: 5,
                    color: '#000',
                    width: '100%'
                }}>
                    Released
                    <br />
                    <Moment date={wr.releasedDT} format="MMM-DD HH:mm" tz="America/New_York" />
                    <br />
                    {editWR.releaseRemarks}

                    <img height={150} alt="Signature" src={`${wr.releaseSignature}`} />

                    <div
                        style={{
                            backgroundColor: '#fff', textAlign: 'center', borderRadius: 5, margin: 10,
                            padding: 10,
                            color: '#000',
                        }}
                    >
                        <Zoom >
                            <img width={100} alt="License" src={`${wr.releaseLicense}`} />
                        </Zoom>
                    </div>
                </div>
            }


            <WrDetails
                wr={editWR}
            />


            {editWR.released === 1 &&
                <button
                    style={{
                        margin: 10,
                        height: 30,
                        fontSize: 12,
                        color: 'red'
                    }}
                    onClick={() => {
                        if (!window.confirm("Are you sure you widh to cancel W/R release?")) {
                            return;
                        }
                        DataUpdate({
                            statusUpdate: 'release',
                            undo: true
                        }).then(res => {
                            setEditWR(null);
                        });

                    }}
                >
                    {fetching_2 ?
                        <ThreeDots
                            width={50}
                            color={"red"}
                        />
                        :
                        "Cancel Release"
                    }
                </button>
            }

            {editWR.releaseAuthorized === 1 && editWR.released === 0 &&
                <button
                    style={{ fontSize: 12, height: 30 }}
                    onClick={() => {
                        if (!window.confirm("Are you sure you widh to undo authorization?")) {
                            return;
                        }
                        DataUpdate({
                            statusUpdate: 'releaseAuthorize',
                            undo: true
                        }).then(res => {
                            setEditWR(null);
                        });

                    }}
                >
                    {fetching_2 ?
                        <ThreeDots
                            width={50}
                            color={"red"}
                        />
                        :
                        "Cancel Authorization"
                    }
                </button>
            }



        </div>
    }


    /* eslint-disable */
    React.useEffect(() => {
        let wrId = searchParams.get("wr") || "0";
        if (wrId !== "0" && file) {
            let _wr = file?.whReceipts.find(w => w.id === parseInt(wrId || 0));
            setEditWR(_wr);
        }
    }, [file]);
    /* eslint-enable */
    if (!file) return null;
    if (editWR && editWR.id) {
        return <EditWR
            wr={editWR}
            fnReturnLicensePhoto={(val) => {
                console.log(val)
            }}
        />
    }


    return <React.Fragment>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',

        }}>

        </div>
        <WRList wrList={file.whReceipts} />

    </React.Fragment>
}


function Main() {

    let fileId = useParams().fileId || "";

    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    let backNav = searchParams.get("b");
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "FILE_FETCH",
            fileId
        })
    }, []);
    /* eslint-enable */

    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            {backNav === 'release' &&
                <Link style={{ color: 'blue' }} to="/admin/wh/release">Back to Release</Link>
            }
            <div
                style={{
                    flexDirection: "column",
                    display: 'flex',
                    alignItems: "center",
                }}
            >
                <h2>W/R Status</h2>

                <Link style={{ color: '#000' }} to={`/admin/wh/file/${fileId}`} >File {fileId}</Link>


            </div>




            {fetching &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20,
                        position: 'absolute',
                        backgroundColor: '#e0e0e090',
                        borderRadius: 50,
                        width: '100%',
                        height: '1000',
                        zIndex: 1000
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={1000}
                    />
                </div>
            }

            <WRS
            />




        </div>
    </div>
    );
}

export default Main;