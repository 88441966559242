import React from "react";
import Moment from 'react-moment';
import { IoAttach, } from "react-icons/io5";



const TicketItem = ({ item, style }) => {

    return <div className="div-table-col"
        style={{
            ...{

            },
            ...style
        }}
    >

        {
            item.archivedDT &&
            <div className="badge_" style={{ backgroundColor: 'red', color: '#fff' }}>
                ARCHIVED&nbsp;<Moment date={item.archivedDT} format="MMM-DD HH:mm" tz="America/New_York" />

            </div>
        }


        <Moment date={item.requestDT} format="MMM-DD HH:mm" tz="America/New_York" />
        <div>
            <div className="div-row"
                style={{
                    alignItems: 'center'
                }}
            >
                {!!item.whatsAppNewContactId && <div style={{ margin: "0px 5px 0px 0px", backgroundColor: 'yellow', color: 'red' }} className="badge_ badge_-2">New Contact</div>} {item.clientName}
            </div>
            {item.countryName}

            <br />
            {item.contactName}

        </div>
        <div
            className="div-row"
            style={{
                flexWrap: 'wrap',
            }}
        >
            {!!item.assignedToUserName &&
                <div className="badge_ badge_-2">Assigned to {item.assignedToUserName}</div>

            }

            {!!item.closedDT &&
                <div className="badge_ badge_-3">Closed</div>

            }
            {!!item.isRFQ && <div
                className="badge_"
            >
                New RFQ
            </div>
            }
            {!!item.file_key && <div
                className="badge_"
                style={{
                    backgroundColor: 'olive'
                }}
            >

                <IoAttach size={18} color="#fff" />
            </div>
            }
        </div>

        <div className="div-row" style={{ alignItems: 'center' }}>
            <div
                style={{
                    marginLeft: 5,
                    padding: 5
                }}
                dangerouslySetInnerHTML={{ __html: (item.message || "").replace(/\n/g, '<br />') }}
            >

            </div>
        </div>
    </div>

}


export default TicketItem;