import React from "react";
import Header from './Header';
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Link, useSearchParams } from 'react-router-dom';


import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import API from "../../api/CustomerServiceAPI";
import { NavBarClients } from './Components/NavBarClients';

function CSClientPage() {
    /* eslint-disable-next-line */
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState(searchParams.get("search") || '');
    const [fetching, setFetching] = React.useState(false);
    const [data, setData] = React.useState();
    const dataSearch = async () => {
        API.clients({
            search: search.trim() === '' ? null : search,
        }).then(res => {
            setFetching(false);
            setData(res.data)
        });

    }
    /* eslint-disable */
    React.useEffect(() => {
        if (searchParams.get("search")) {
            setFetching(true);
            dataSearch();
        }
    }, []);
    /* eslint-enable */
    const Item = ({ item, index }) => {
        return (<div className="div-row"
            style={{
                backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',

                padding: 10
            }}
        >
            <div className="div-col" style={{}}>
                <Link to={`/admin/customer-service/clients/${item.clientId}?search=${search}`}>{item.clientName}</Link>
                <div>{item.countryName}</div>
            </div>
        </div >)
    }

    return (< >
        <HelmetProvider>
            <Helmet>
                <title>CS &gt; Clients</title>
            </Helmet>
        </HelmetProvider>
        <Header />



        <Container
            style={{
                marginTop: 60,
                maxWidth: 650,
            }}
        >


            <NavBarClients
                activeKey={"active-clients"}
            />


            <div className="div-row"

            >
                <div className="div-col"

                    style={{
                        marginRight: 10,
                    }}
                >
                    Search<br />
                    <input
                        value={search}
                        type="text"
                        key="search"
                        onChange={(e) => setSearch(e.target.value)} onKeyDown={e => {
                            if (e.key === "Enter") {
                                setFetching(true);
                                dataSearch();
                            }

                        }}

                    />
                </div>
                <div>
                    <br />
                    <Button
                        onClick={() => {
                            setFetching(true);
                            dataSearch();
                        }}
                        disabled={fetching}
                        style={{ width: 140 }}
                    >
                        {fetching ? "Searching..." : "Search"}
                    </Button>

                </div>

            </div>
            {
                !data ?
                    <div
                        style={{
                            padding: 50
                        }}
                    >

                    </div>
                    :

                    <div
                        style={{
                            width: '100%',
                            maxWidth: 750,
                            marginTop: 10,
                        }}
                    >
                        {
                            data.length === 0 && <div style={{
                                textAlign: "center",
                                padding: 20
                            }}>No records found</div>
                        }
                        {data?.map((item, index) => {

                            return <Item key={index} index={index} item={item} />

                        })}
                    </div>
            }

        </Container>
    </>
    );
}

export default CSClientPage;