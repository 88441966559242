import React from "react";
import { useDispatch, useSelector } from 'react-redux'; 
import { Circles } from "react-loader-spinner";

import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";


function WHRLabels() {

    let fileId = useParams().fileId; 
    let wrId = useParams().wrId; 
    const dispatch = useDispatch();

    const [WRPackages, setwWRPackages] = React.useState([])
    const [TotalPackages, setTotalPackages] = React.useState(0)

    const dataReducer = useSelector((state) => state.dataReducer); 
    const { 
        fetching,
        wr,
        file
    } = dataReducer;   
  
    const Labels = ({
        fileId,
        wrId,
        pcks , 
        totalPackages
    }) => {
 

        let menuItems = []
        for (var i = 0; i < pcks.packages; i++) {
 

            menuItems.push(<div
                key={i}
                style={{
                    pageBreakBefore: 'always',
                    clear: 'both',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            > 
                <span style={{ fontWeight: 'bold', fontSize: 24 }}>W/R {fileId}{wrId}</span>
                <span style={{ fontWeight: 'bold', fontSize: 20 }}>{ pcks.label_index + i } / {totalPackages}</span>
                <div style={{ margin: 10 }}>
                    <QRCode size={90} value={`${process.env.REACT_APP_SITE_URL}/qr/${fileId}/${wrId}`} />
                </div>
                <div style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span>File {fileId}</span>

                    <span>{pcks.packageType.toUpperCase()}</span>
                    {pcks.weight} lbs | {pcks.height} x {pcks.width} x {pcks.length}
                    <div style={{
                        marginTop: 10,
                        textAlign: 'center'
                    }}
                        dangerouslySetInnerHTML={{ __html: "<b>From: Prodek Inc</b><br/>10900 NW 21st suite 190<br/> Miami, FL. 33172 USA<br/>+1(305)594-4488" }}
                    >

                    </div>
                    <div style={{
                        marginTop: 10,
                        textAlign: 'center'
                    }}
                        dangerouslySetInnerHTML={{ __html: `<b>To: ${file?.client.clientName}</b><br/> ${file?.client.clientAddress1} <br/> ${file?.client.clientAddress2} `}}
                    >


                    </div>

                </div>

            </div>);
        }
        return menuItems;
    }
 
    
    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "WR_FETCH",
            fileId ,
            wrId 
        });
        dispatch({
            type: "FILE_FETCH",
            fileId
        })
    }, []);
    React.useEffect(() => {
        if(!wr) return;
        let _total = 0;
        let _wr_packages = wr.packages.map( (p, ix) => {
            p.label_index =  _total + 1;
            _total += p.packages;  
            return p
        });
        setTotalPackages(_total);
        setwWRPackages(_wr_packages)
    }, [wr]);
    

    /* eslint-enable */
    return (<div style={{
    }}>
 
        {(fetching || file===null || wr === null ) ? <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 50
            }}

        ><Circles
                color="#FF9400"
                height={80}
                width={150}
                timeout={5000}
            />
        </div>

            :
            <div> 

                {WRPackages.map( ( p,ix  ) => {
                    

                   return <div
                        key={ix}
                        style={{
                            marginTop: 50
                        }}
                    >  
                    <Labels 
                        fileId={fileId}
                        wrId={wrId}
                        pcks={p}
                        key={ix} 
                        totalPackages={TotalPackages}
                    />
                </div>}
                )  }
                



            </div>
        }



    </div>
    );
}

export default WHRLabels;