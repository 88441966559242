import React from "react";
import Header from './Header';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Moment from 'react-moment';
import 'moment-timezone';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { IoAttach } from "react-icons/io5";
import { useDispatch, } from "react-redux";
import Fetching from "../Client/Fetching";
import API from "../../api/CustomerServiceAPI";

import TicketItem from "./Components/TicketItem";
import Container from 'react-bootstrap/Container';

import { NavBarClients } from './Components/NavBarClients';
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Contacts = ({ items }) => {
    if (!items) return null;
    return <div>

        {items.map((contact, index) => {


            return <div key={index} className="div-col"

                style={{
                    backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',
                    padding: 5
                }}
            >
                <div>{contact.name}</div>
                <div>{contact.email}</div>
                <div>{contact.phone}</div>
                <div>{contact.contactType || "N/A"}</div>

            </div>
        })}

    </div>
}

const PurchaseOrders = ({ items, invoices }) => {



    return <div
        style={{
            width: '100%',
        }}
    >

        {items.map((f, index) => {
            let file_invoices = invoices.filter(i => i.fileId === f.fileId);
            return <div key={index}
                style={{
                    backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',

                    padding: 10
                }}
            >
                <div className="div-row" style={{
                    justifyContent: 'space-between'
                }}>
                    {f.fileId}
                    <div className="div-col">

                        <Moment date={f.fileDate} format="MMM DD YY" tz="America/New_York" />
                    </div>
                </div>
                <div>{f.rfqName}</div>
                <div>Quoted {formatter.format(f.totalQuoted)}</div>

                <div>{f.finishStatusName || f.fileStatusName}   <Moment date={f.clientPODeliveryDate} format="MMM DD YY" tz="America/New_York" /></div>
                {file_invoices.length > 0 &&
                    <div>
                        Invoiced
                        <div className="div-col" style={{
                            marginLeft: 10
                        }}>
                            {file_invoices.map((invoice, ix) => {
                                return <div key={ix}>
                                    <b>{invoice.invoiceId}</b>
                                    &nbsp;
                                    <Moment date={invoice.invoiceDate} format="MMM DD 'YY" tz="America/New_York" />
                                    &nbsp;
                                    {formatter.format(invoice.totalInvoiced)}
                                </div>
                            })}</div>
                    </div>
                }
            </div>
        })
        }
    </div>
}

const Tickets = ({ items, clientId }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!items) return null;
    return <div>
        {items.map((f, index) => {


            return <div key={index} className="div-col"
                style={{
                    backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',
                }}

            >

                <TicketItem
                    item={f}

                />

                {f.files && f.files.length > 0 &&
                    <div
                        style={{ padding: 10 }}
                    >
                        {
                            f.files.map((file, index) => {
                                return <div key={index}>
                                    <IoAttach color='blue' />
                                    <a href={`${file.file_url}`} target="_blank" rel="noopener noreferrer" >{(file.file_key || "").replace(`${clientId}.`, '')}</a>

                                </div>
                            })}
                    </div>
                }

                <button
                    style={{
                        margin: 5,
                        width: 100
                    }}
                    onClick={() => {


                        dispatch({
                            type: 'CS_REQUEST_SET',
                            data: f
                        });
                        navigate(`/admin/customer-service/tickets/${f.id}?back=${window.location.pathname + window.location.search}`);
                    }}
                >
                    Details
                </button>

            </div>
        })}

    </div>
}


const Quotes = ({ items }) => {

    return <div
        style={{
            width: '100%',
        }}
    >

        {items.map((f, index) => {

            return <div key={index}
                style={{
                    backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',

                    padding: 10
                }}
            >
                <div className="div-row" style={{
                    justifyContent: 'space-between'
                }}>{f.fileId} <Moment date={f.fileDate} format="MMM DD YY" tz="America/New_York" /></div>
                <div>{f.rfqName}</div>
                <div>{f.fileStatusName} {f.finishStatusName}</div>
                <div>{formatter.format(f.totalQuoted)}</div>
            </div>
        })
        }
    </div>
}


const Invoices = ({ items }) => {

    return <div
        style={{
            width: '100%',
        }}
    >

        {items.map((f, index) => {

            return <div key={index}
                style={{
                    backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',

                    padding: 10
                }}
            >
                <div className="div-row" style={{
                    justifyContent: 'space-between'
                }}><div>{f.fileId} <b>{f.invoiceId}</b></div> <Moment date={f.invoiceDate} format="MMM DD YY" tz="America/New_York" /></div>
                <div>{f.rfqName}</div>
                <div>{f.finishStatusName}</div>
                <div>{formatter.format(f.totalInvoiced)}</div>
            </div>
        })
        }
    </div>
}




function CSClientPage() {

    const id = useParams().clientId;

    /* eslint-disable-next-line */
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("search");

    const [fetching, setFetching] = React.useState(false);
    const [data, setData] = React.useState();
    const [section, setSection] = React.useState(searchParams.get("section") || "purchase-orders");



    const dataSearch = async () => {

        API.clients({
            id
        }).then(res => {
            setFetching(false);
            setData(res.data)
        });
    }

    /* eslint-disable */
    React.useEffect(() => {
        //if (!CS_REQUESTS || CS_REQUESTS?.length === 0) {
        setFetching(true);
        // }
        dataSearch();
    }, []);

    React.useEffect(() => {
        setSearchParams({ section, search: search || '', back: searchParams.get("back") || '' });
    }, [section]);


    /* eslint-enable */


    return (<>
        <HelmetProvider>
            <Helmet>
                <title>{(data?.client) ? data.client.clientName : "Client Details"}</title>
            </Helmet>
        </HelmetProvider>
        <Header />

        <Container
            style={{
                marginTop: 60,
                maxWidth: 650,
                padding: 0
            }}
        >


            <NavBarClients
                activeKey={"active-clients"}
            />

            {fetching ? <Fetching />
                :
                data && <div
                    style={{
                        width: '100%',
                        maxWidth: 550,
                        marginBottom: 50
                    }}
                >
                    <h2>{data.client.clientName}</h2>

                    <div className="div-row" style={{
                        justifyContent: 'space-between',
                        margin: '10px 5px',
                    }}>
                        <button style={{ margin: 5, backgroundColor: section === 'quotes' ? '#c0c0c0' : "#fff" }} onClick={() => setSection("quotes")}>Quotes</button>
                        <button style={{ margin: 5, backgroundColor: section === 'purchase-orders' ? '#c0c0c0' : "#fff" }} onClick={() => setSection("purchase-orders")}>Purchase Orders</button>
                        <button style={{ margin: 5, backgroundColor: section === 'invoices' ? '#c0c0c0' : "#fff" }} onClick={() => setSection("invoices")}>Invoices</button>
                        <button style={{ margin: 5, backgroundColor: section === 'wap_tickets' ? '#c0c0c0' : "#fff" }} onClick={() => setSection("wap_tickets")}>Tickets</button>
                        <button style={{ margin: 5, backgroundColor: section === 'contacts' ? '#c0c0c0' : "#fff" }} onClick={() => setSection("contacts")}>Contacts</button>

                    </div>

                    {
                        section === 'quotes' && <div>

                            <Quotes items={data.quotes} />
                        </div>
                    }

                    {
                        section === 'purchase-orders' && <div>
                            <PurchaseOrders items={data.purchaseOrders} invoices={data.invoices} />
                        </div>
                    }

                    {
                        section === 'invoices' && <div>
                            <Invoices items={data.invoices} />
                        </div>
                    }

                    {
                        section === 'wap_tickets' && <div>
                            <Tickets items={data.tickets} clientId={data.id} />
                        </div>
                    }
                    {
                        section === 'contacts' && <div>
                            <Contacts items={data.contacts} />
                        </div>
                    }
                </div>

            }


        </Container>
    </>
    );
}

export default CSClientPage;