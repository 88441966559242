const pageUserAPI = {

    logout: (args) => {
        return new Promise((resolve, reject) => {
            let {
                token,
                accountId
            } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/logout?token=${token}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    fetch: (args, phone) => {

        ///ConversationBot/PageInfoFetch/index.js
        //CALL convo_infoPageGet( token , phone , checkPinCode )

        return new Promise((resolve, reject) => {
            let { token, checkPinCode, rememberMe } = args;

            if (!phone /*|| !token || token === undefined*/) {
                console.warn("pageUserAPI.fetch ==> abortar fetch", phone + token)
                resolve({});
                return
            }




            if (!checkPinCode) checkPinCode = 0;

            phone = phone && phone.replace("+", "");


            if (!token) token = "0";

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/convoPageInfo/${token}?phone=${phone}&pinCode=${checkPinCode}`;
            if (rememberMe !== undefined) url += `&rememberMe=${rememberMe}`;

            console.warn("pageUserAPI", url)


            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


    tokenRenovar: (args) => {


        ///ConversationBot/WhatsappSendMessage/index.js
        //CALL token_renew( token_or_phone )

        return new Promise((resolve, reject) => {
            let { token_or_phone } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/token/${token_or_phone}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    prealertaCancelar: (args) => {
        return new Promise((resolve, reject) => {
            let { token, prealertaId } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/convoPageInfo/${token}/prealertas/${prealertaId}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}

export default pageUserAPI