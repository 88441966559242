const token = localStorage.getItem("token") || "";
const api = process.env.REACT_APP_API;

const CustomerService = {

    agents: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_CUSTOMER_SERVICE_SETUP
        return new Promise((resolve, reject) => {
            let method = 'GET';
            let body = undefined;
            let url = `${api}/admin/customer-service/setup/agents`;
            if (args.search) {
                url += `?search=${encodeURI(args.search)}`;
            }

            if (args.id !== undefined) {
                url += `/${args.id}`;
            }

            if (args.body) {
                method = 'POST';
                body = JSON.stringify(args.body);
            }

            if (args.delete) {
                method = 'DELETE';
            }

            var headers = new Headers(

            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method,
                body
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("CustomerService.clients", err)
                    reject(err);
                });
        })
    },

    clients: (args) => {

        return new Promise((resolve, reject) => {
            let method = 'GET';
            let url = `${api}/admin/customer-service/clients`;
            if (args.search) {

                url += `?search=${encodeURI(args.search)}`;

            }

            if (args.id) {
                url += `/${args.id}`;
            }


            var headers = new Headers(

            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("CustomerService.clients", err)
                    reject(err);
                });
        })
    },
    fetch: (args) => {
        //PRK_WHATSAPP_REQUESTS_FETCH
        //CALL whatsapp_request_update(
        return new Promise((resolve, reject) => {
            let method = 'GET';
            let url;
            let body = undefined;

            if (args.type === "requests") {
                url = `${api}/admin/customer-service/requests`;
                if (!args.id) {
                    url += `?status=${args.status}`;
                    if (args.search) {
                        url += `&search=${encodeURI(args.search)}`;
                    }
                    if (args.startDate) {
                        url += `&startDate=${encodeURI(args.startDate)}`;
                    }
                    if (args.endDate) {
                        url += `&endDate=${encodeURI(args.endDate)}`;
                    }
                    if (args.agentId) {
                        url += `&agentId=${args.agentId}`;
                    }
                }
            }
            if (args.id) {
                url += `/${args.id}`;
            }

            if (args.data?.status) {
                method = 'POST';
                body = JSON.stringify(args.data);
            }


            var headers = new Headers(

            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method,
                body
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.inventoryFetch", err)
                    reject(err);
                });
        })
    },



}

export default CustomerService;