import React from "react";
import Menu from './Menu';
import Header from './Header';

import Moment from 'react-moment';
import { Circles } from "react-loader-spinner";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { IoHomeSharp, IoChevronForward, IoWarning } from "react-icons/io5";
import Modal from 'react-modal';

import { useDispatch, useSelector } from 'react-redux';


import API from '../../api/ReportsAPI';
import FilePODetails from "../../app/components/FilePODetails";

var formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
var formatterEUR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
});

var formatter = (val, currency) => {
    if (currency === 'USD') {
        return formatterUSD.format(val);
    } else if (currency === 'EUR') {
        return formatterEUR.format(val);
    }
}



function PurchaseOrdersPending() {
    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const dataReducer = useSelector((state) => state.dataReducer);

    const dispatch = useDispatch();
    const {
        PENDING_POS,
        PENDING_POS_REFRESH
    } = dataReducer;
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [sortValue, setSortValue] = React.useState("ETA");

    const [fileDetails, setFileDetails] = React.useState({
        fileId: searchParams.get('fileId'),
        clientQuoteId: searchParams.get('quoteId')
    });


    const [showModal, setShowModal] = React.useState(searchParams.get('fileId') ? true : false);

    const DataFetch = async () => {
        if (loading) return;
        setLoading(true);
        const data = await API.PurchaseOrdersFetch({
            reportType: 'pending',
            sort: sortValue
        });
        setLoading(false);
        dispatch({
            type: 'PENDING_POS_SET',
            data
        });
    }

    React.useLayoutEffect(() => {
        DataFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortValue]);
    React.useLayoutEffect(() => {
        DataFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useLayoutEffect(() => {
        setData(PENDING_POS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PENDING_POS]);


    const PendingPurchaseOrdersList = ({
        items
    }) => {

        const [search, setSearch] = React.useState(searchTerm || "");
        if (!items) return null;
        return <div className="div-col" style={{ width: '100%' }}>

            <Modal
                ariaHideApp={false}
                isOpen={showModal}
                contentLabel="File"
                style={customStyles}
            >
                <div
                    style={{
                        width: '100%',
                        height: 550
                    }}
                >
                    <button
                        style={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                        }}
                        onClick={async () => {
                            setShowModal(false);
                            if (PENDING_POS_REFRESH) {
                                const data = await API.PurchaseOrdersFetch({
                                    reportType: 'pending',
                                });
                                dispatch({
                                    type: 'PENDING_POS_SET',
                                    data
                                });
                                dispatch({
                                    type: 'PENDING_POS_REFRESH',
                                    PENDING_POS_REFRESH: null
                                });
                            }
                        }}
                    >Close</button>


                    <div>
                        <h4 style={{ textAlign: 'left', padding: 0, margin: 0 }}>File {fileDetails.fileId}</h4>

                        <FilePODetails
                            fileId={fileDetails.fileId}
                            clientPOId={fileDetails.clientPOId}
                            data={fileDetails}
                        />


                    </div>


                </div>
            </Modal >

            <div
                className="div-row-space-between"
                style={{
                    padding: 10,
                    borderBottom: '1px solid #ccc',
                    width: '100%'
                }}
            >

                <div>{items.length} pending vendor po's</div>
                <div
                    style={{
                        textAlign: 'right'
                    }}
                >
                    <div>
                        {formatter(items.reduce((a, b) => parseFloat(a) + parseFloat(b.currency === 'USD' ? b.total : 0), 0.00), 'USD')}
                    </div>
                    <div>
                        {formatter(items.reduce((a, b) => parseFloat(a) + parseFloat(b.currency === 'EUR' ? b.total : 0), 0.00), 'EUR')}

                    </div>
                </div>
            </div>
            <div className="div-row">
                <div >
                    Search<br />
                    <input
                        style={{
                            width: 250
                        }}
                        type="text"
                        value={search}
                        key="search"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearchTerm(search);
                            }
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div
                    style={{ marginLeft: 10, marginRight: 10 }}
                >
                    <br />
                    <button
                        className="btn btn-primary"

                        onClick={() => {
                            setSearchTerm(search);
                        }}
                        style={{
                            width: 100
                        }}
                    >
                        {"Search"}
                    </button>
                </div>
                <div

                >   <br />
                    <select
                        onChange={(e) => {
                            setSortValue(e.target.value);
                        }}
                        value={sortValue}
                    >
                        <option value="ETA">
                            ETA Date
                        </option>
                        <option value="followup">
                            Follow Up Date
                        </option>
                    </select>
                </div>
            </div>

            {loading ?
                <div className="div-row-center" style={{ height: 300 }}>

                    <Circles height={100} width={100} />
                </div>

                :

                items.filter(item => {
                    if (searchTerm === "") return true;
                    return (item.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
                        ||
                        item.clientName.toLowerCase().includes(searchTerm.toLowerCase())
                        ||
                        item.clientPONumber.toLowerCase().includes(searchTerm.toLowerCase())
                        ||
                        parseInt(item.fileId) === parseInt(searchTerm)
                    )
                        ;
                }).map((item, index) => {
                    return <div key={index} className="div-row"
                        style={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 10,
                            borderBottom: '1px solid #ccc',
                            backgroundColor: index % 2 === 0 ? '#fff' : '#f5f5f5'
                        }}
                    >
                        <div className="div-col"
                            style={{
                                width: '100%'
                            }}
                        >
                            <div
                                className="link"
                                onClick={() => {
                                    setShowModal(true);
                                    setFileDetails({
                                        fileId: item.fileId,
                                        clientPOId: item.clientPOId
                                    });
                                }}
                                style={{
                                    color: "blue"
                                }}
                            >File {item.fileId}</div>

                            <div
                                style={{
                                    width: '100%',
                                    alignItems: 'flex-start',
                                    flexWrap: 'wrap'
                                }}
                                className="div-row-space-between"
                            >
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <Moment format="MMM DD YYYY">{item.clientPODate}</Moment>
                                    <br />
                                    PO {item.clientPONumber}
                                    <br />
                                    {item.clientName}
                                    <div>
                                        {formatter(item.total, item.currency)}

                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <b><Moment format="MMM DD YYYY">{item.ETA}</Moment></b>
                                    <br />
                                    {item.vendorName}
                                    <br />
                                    {item.vendorId}



                                </div>
                                <div style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    alignItems: 'center'
                                }}
                                    className="div-col"

                                >

                                    {item.vendorPOFollowupDT ?
                                        <div>
                                            <div style={{ fontSize: 10 }}>Followup</div>
                                            <div className="badge_"><Moment format="MMM DD YYYY">{item.vendorPOFollowupDT}</Moment></div>
                                        </div>
                                        :
                                        <IoWarning color='red' size={20} />
                                    }

                                    {!!item.clientPOStatusDT
                                        &&
                                        <div style={{
                                            backgroundColor: '#c0c0c0',
                                            padding: 5,
                                            borderRadius: 5,
                                            marginTop: 10,
                                            minWidth: 150
                                        }} >
                                            <div style={{ fontSize: 10 }}>Client Update</div>
                                            {item.clientPOStatusDesc}
                                            <div>
                                                <Moment format="MMM DD YYYY">{item.clientPOStatusDT}</Moment>
                                            </div>
                                        </div>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div >
    }


    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Pending Purchase Orders</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div className="navigation-bar" >
            <div className="navigation-bar-content" >
                <Link to={`/admin/reports/`}><IoHomeSharp /></Link>
                <IoChevronForward />
                Purchase Orders
                <IoChevronForward />
                <b><Link to={`/admin/reports/pos/pending/`}>Pending</Link></b>
                <IoChevronForward />
                <Link to={`/admin/reports/pos/delivered/`}>Delivered</Link>
            </div>
        </div>
        <div className="content">


            <PendingPurchaseOrdersList
                items={data?.data?.pending}
            />









        </div>
    </div>
    );
}

export default PurchaseOrdersPending;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 550
    },
    overlay: {
        background: "#00000090"
    }
};