import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/HeaderWH';
import WHAPI from "../api/WHAPI";
import { Circles } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";


import { useDispatch } from 'react-redux';

const WHRecibir = () => {
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [fetching2, setFetching2] = React.useState(false);
    const [reference, setReference] = React.useState(searchParams.get("poNo") || "");

    const vendorPOFetch = async () => {
        setFetching2(true);
        WHAPI.vendorPOFetch({
            vendorPONumber: reference
        }).then(res => {
            setFetching2(false)
            if (res.success) {

                dispatch({
                    type: 'VENDOR_PO_SET',
                    vendorPO: res
                });
                if (res.foundByFileId) {
                    alert(`Found Vendor PO ${res.vendorPO.vendorPONumber} by File ID`)
                }
                navigate(`/admin/wh/vendorPO/${res?.vendorPO?.vendorPOId}/receiveV2/`)
            } else {
                alert(res.msg)
            }
        });

    }


    return <div
    >


        <div
            style={{
                flexDirection: 'column',
                display: 'flex',
                width: 280,
                margin: 'auto'
            }}
        >



            Vendor PO / File / Tracking
            <input
                value={reference || ""}
                onChange={e => setReference(e.target.value)}
                style={{
                    marginBottom: 5,
                    fontSize: 16
                }}
                maxLength={250}
            />
            <Styles.ButtonSecondary
                disabled={fetching2}
                onClick={() => {
                    if (!reference) {
                        return alert("Vendor PO#, Tracking # or File# is required")
                    }
                    vendorPOFetch()
                }}
            >{!fetching2 ?
                "Search"
                :
                "Searching"
                }</Styles.ButtonSecondary>
        </div>
        {fetching2 &&
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 20,

                }}
            >
                <Circles
                    color="#FF9400"
                    height={50}
                    timeout={5000}
                />
            </div>
        }


    </div>
}


function WHReceive() {



    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <div style={{ marginBottom: 10 }}><a href="/admin/wh/inTransit">In Transit</a>|<a href="/admin/wh/">Warehouse</a></div>
            <Styles.H1 >W/H Receive</Styles.H1>

            <WHRecibir />

        </div>
    </div>
    );
}

export default WHReceive;