import React from "react";
import Styles from "../styles";
import Menu from '../app/components/Menu';
import Header from '../app/components/Header';
import { useParams ,useSearchParams  } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Circles } from "react-loader-spinner";
 
 
const InvoicePDF = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file
    } = dataReducer;
 
    return <div>
    
        INVOICE PDF
        {JSON.stringify(file)}
    </div>
}


function Invoice() {

    let fileId = useParams().fileId || ""; 

    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    //let backNav = searchParams.get("b");
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "FILE_FETCH",
            fileId
        })
    }, []);
    /* eslint-enable */


    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 350,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <Styles.H1 >File {fileId}</Styles.H1>

            {fetching &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20,
                        position: 'absolute',
                        backgroundColor: '#e0e0e090',
                        borderRadius: 50,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={1000}
                    />
                </div>
            }



            <InvoicePDF />

        </div>
    </div>
    );
}

export default Invoice;