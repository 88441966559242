import React from "react";

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';


const WRPhoto = ({
    photo,
    fileId,
    width,
    noTitle
}) => <div
    style={{
        marginBottom: 10, 
        marginRight: 5, 
        marginLeft: 5
    }}
>
        {!noTitle && <b>W/R {fileId}{photo.wrId}</b>}
        {(photo.isPackingList === 1 && <div
            style={{
                borderStyle: "solid",
                borderColor: '#000',
                borderWidth: 1,
                padding: 5,
                backgroundColor: "#FF9400",
                color: '#fff',
                textAlign: 'center',
                borderBottomStyle: "none"
            }}
        >
            Packing List
        </div>)}
        <Zoom  >
            <img alt={`W/R ${fileId}${photo.wrId}`} src={photo.data_url} style={{
                borderWidth: 1,
                borderColor: '#000',
                borderStyle: 'solid',
                width: width || 350,
                minHeight: 50,
            }} />
        </Zoom>

    </div>


export default WRPhoto;