import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { Circles } from "react-loader-spinner";

import WRPackage from "../app/components/WRPackage";
import WRDetails from "../app/components/WRDetails";


const WRNew = ({
    fileId,
    vendorPOId
}) => {

    const dispatch = useDispatch();
    const [remarks, setRemarks] = React.useState("");
    const [warehouseId, setWarehouseId] = React.useState(0);

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        WAREHOUSES
    } = dataReducer;
    return <div>

        <div
            style={{
                marginBottom: 10
            }}
        >
            Warehouse
            <br />
            <select
                style={{
                    fontSize: 17,
                    padding: 5,
                    width: 150
                }}
                onChange={(e) => setWarehouseId(e.target.value)}
            >
                {WAREHOUSES.map((w) => <option key={w.id} value={w.id}>{w.name}</option>)}
            </select>
        </div>

        New W/R Remarks<br />
        <textarea
            style={{
                width: 300,
                fontSize: 17
            }}
            value={remarks || ""}
            onChange={e => setRemarks(e.target.value)}
        />

        <WRPackage
            label={"New Package"}
            PCK={{
                id: 0,
                wrId: 0
            }}
            fnSave={(val) => {

                dispatch({
                    type: 'WR_UPDATE_V2',
                    fileId: fileId,
                    vendorPOId: vendorPOId,
                    wrId: 0,
                    wrRemarks: remarks,
                    warehouseId,
                    newPackage: val

                });
            }}
        />
    </div>
}


function WRReceiveV2WR() {

    const navigate = useNavigate();
    let vendorPOId = useParams().vendorPOId;
    let fileId = useParams().fileId;
    let wrId = parseInt(useParams().wrId);
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        wr,
        fetching,
        recordId
    } = dataReducer;



    const VendorPOSummary = ({
        vendorName,
        fileId,
        clientName,
    }) => <div
        style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}
    >
            <b>{vendorName}</b>
            <span style={{ fontSize: 12 }}>File {fileId} - {clientName}</span>
        </div>

    /* eslint-disable */
    React.useEffect(() => {
        if (wrId !== 0) {
            dispatch({
                type: 'WR_FETCH',
                fetching: "wr_fetching",
                wrId: wrId,
                fileId: fileId,
            });
        }
    }, []);
    React.useEffect(() => {
        if (fetching === "wr_updating_v2_success") {
            //si el update fue exitoso, hacer reload de la data del W/R 
            dispatch({
                type: 'WR_FETCH',
                fetching: "wr_fetching",
                wrId: wrId || recordId,
                fileId: fileId,
            });
            if (wrId === 0) {
                navigate(`/admin/wh/vendorPO/${vendorPOId}/receiveV2/${fileId}/${recordId}?t=${Date.now()}`);
            }

        }
    }, [fetching]);
    /* eslint-enable */


    return (<div   >
        <Menu />
        <Header />
        {fetching === "wr_fetching" && <Styles.DivFetching>
            <Circles
                color="#FF9400"
            />
        </Styles.DivFetching>}
        <div style={{
            margin: "auto",
            marginTop: 20,
            maxWidth: 500,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: '100%',
        }}>

            <Styles.H1 >Receive W/R</Styles.H1>

            <Link to={`/admin/wh/vendorPO/${vendorPOId}/receiveV2`}>Vendor PO {wr?.wr?.vendorPONumber}</Link>

            {wr?.success === 1 && <div
                style={{
                    width: '100%'
                }}
            >
                <VendorPOSummary
                    fileId={fileId}
                    vendorName={wr.vendor.vendorName}
                    clientName={wr.client.clientName}
                />


                <div style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    margin: 10
                }} >W/R {fileId}{wrId}</div>

                <WRDetails
                    vendorPOId={vendorPOId}
                />
            </div>
            }

            {wrId === 0 &&
                <WRNew
                    vendorPOId={vendorPOId}
                    fileId={fileId}
                />
            }



        </div>


    </div>
    );
}

export default WRReceiveV2WR;