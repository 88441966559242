import React from "react";
import { Link } from "react-router-dom";
import Styles from "../styles";
import Menu from '../app/components/Menu';
import Header from '../app/components/Header';

function AdminMain() {

    const USER_ROLES = (localStorage.getItem("userRoles") || "").split(",");

    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 350,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <Styles.H1 >Main</Styles.H1>
            {
                !!process.env.REACT_APP_BETA &&
                <div className='badge_'>
                    PRODEK TEST SITE
                </div>
            }
            {USER_ROLES.indexOf("2") > -1 && <div>
                <br />
                <Link style={{ color: 'blue' }} to="/admin/wh/">Warehouse</Link>
            </div>
            }

            {USER_ROLES.indexOf("9") > -1 && <div>
                <br />
                <Link style={{ color: 'blue' }} to="/admin/pos/vendor_pos/">Vendor Quotes</Link>
            </div>
            }


            <br />

            <Link style={{ color: 'blue' }} to="/admin/customer-service/">Customer Service</Link>


            {USER_ROLES.indexOf("8") > -1 && <div>
                <br />
                <Link style={{ color: 'blue' }} to="/admin/reports/">Reports</Link>
            </div>

            }

            <div style={{
                marginTop: 50
            }}>
                V {process.env.REACT_APP_VERSION}
            </div>
        </div>
    </div>
    );
}

export default AdminMain;