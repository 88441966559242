import React from "react";
import Header from './Header';

import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';


import Container from 'react-bootstrap/Container';
import { NavBarClients } from './Components/NavBarClients';

import { IoLogoWhatsapp, IoNotifications } from "react-icons/io5";

const Clients = () => {
    const dataReducer = useSelector((state) => state.dataReducer);

    const {
        notificationsClients,
    } = dataReducer;

    if (!notificationsClients) return null;
    return <div
        style={{
            width: '100%',
        }}
    >
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#000',
                padding: 5
            }}
        >
            <div style={{
                flex: 8,
                color: '#fff',
                textAlign: 'center',
                fontSize: 19
            }}>
                Client
            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                color: '#fff',
                flexDirection: 'column',
                alignItems: 'center',
                width: 50
            }}>
                <IoNotifications size={25} />
            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                color: '#fff',
                flexDirection: 'column',
                alignItems: 'center',
                width: 50
            }}>
                <IoLogoWhatsapp size={25} />
            </div>

        </div>

        {notificationsClients.map((n, ix) => <div
            key={ix}
            style={{
                backgroundColor: ix % 2 ? '#c0c0c0' : '#fff',
                padding: 5,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: '#000',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between'

            }}
        >
            <div style={{ flex: 8 }}>
                <Link to={`/admin/customer-service/clients/active-files/${n.clientId}/contacts`} >{n.clientName}</Link>
                <div>{n.contactsCount} contacts</div>
            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                color: '#fff',
                flexDirection: 'column',
                alignItems: 'center',
                width: 50

            }}>
                <div style={{
                    backgroundColor: n.fileNotificationsCount ? 'green' : '#808080',
                    padding: 5,
                    color: '#fff',
                    textAlign: 'center',
                    width: 40
                }}>
                    {n.fileNotificationsCount || "No"}
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    color: '#fff',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 50
                }}
            >
                <div style={{
                    backgroundColor: n.whatsappAccessCount ? 'green' : '#808080',
                    padding: 5,
                    color: '#fff',
                    textAlign: 'center',
                    width: 40
                }}>
                    {n.whatsappAccessCount}
                </div>
            </div>
        </div>)
        }

    </div>
}


function NotificationsContacts() {
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();
    const {
        fetching,
    } = dataReducer;


    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "NOTIFICATIONS_CLIENTS_FETCH",
        })
    }, []);
    /* eslint-enable */
    return (<>
        <HelmetProvider>
            <Helmet>
                <title>Prodek Notifications</title>
            </Helmet>
        </HelmetProvider>
        <Header />


        <Container
            style={{
                marginTop: 60,
                maxWidth: 650,
                padding: 0
            }}
        >

            <NavBarClients />

            <Container
                style={{
                    marginTop: 10
                }}
            >

                {fetching === "client_notifications" ?
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            padding: 20
                        }}
                    >
                        <Circles
                            color="#FF9400"
                            height={50}
                        />
                    </div>
                    :

                    <Clients />

                }
            </Container>



        </Container>
    </>
    );
}

export default NotificationsContacts;