import React from "react";
import Menu from './Menu';
import Header from './Header';

import Moment from 'react-moment';
import { Circles } from "react-loader-spinner";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { IoHomeSharp, IoChevronForward } from "react-icons/io5";
import Modal from 'react-modal';

import API from '../../api/ReportsAPI';
import FilePODetails from "../../app/components/FilePODetails";
import {
    ETABadge,
    formatter
} from '../../app/components/';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




function PODelivered() {
    //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const [fileDetails, setFileDetails] = React.useState({
        fileId: searchParams.get('fileId'),
        clientQuoteId: searchParams.get('quoteId')
    });


    const [search, setSearch] = React.useState('');
    const [startDate, setStartDate] = React.useState(new Date(Date.now() - 2656000000));
    const [endDate, setEndDate] = React.useState(new Date());
    const [showModal, setShowModal] = React.useState(searchParams.get('fileId') ? true : false);

    const DataFetch = async () => {
        if (loading) return;
        setLoading(true);


        const data = await API.PurchaseOrdersFetch({
            reportType: 'delivered',
            search,
            startDate,
            endDate
        });
        setLoading(false);
        setData(data);

    }

    React.useLayoutEffect(() => {
        DataFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const PendingPurchaseOrdersList = ({
        items
    }) => {

        if (!items) return null;
        return <div className="div-col" style={{ width: '100%' }}>

            <Modal
                ariaHideApp={false}
                isOpen={showModal}
                contentLabel="File"
                style={customStyles}
            >
                <div
                    style={{
                        width: '100%',
                        height: 550
                    }}
                >
                    <button
                        style={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                        }}
                        onClick={() => {
                            setShowModal(false);

                        }}
                    >Close</button>


                    <div>
                        <h4 style={{ textAlign: 'left', padding: 0, margin: 0 }}>File {fileDetails.fileId}</h4>

                        <FilePODetails
                            fileId={fileDetails.fileId}
                            clientPOId={fileDetails.clientPOId}
                            data={fileDetails}
                        />


                    </div>


                </div>
            </Modal >

            <div
                className="div-row-space-between"
                style={{
                    padding: 10,
                    borderBottom: '1px solid #ccc',
                    width: '100%'
                }}
            >

                <div>{items.length} delivered vendor po's</div>
                <div
                    style={{
                        textAlign: 'right'
                    }}
                >
                    <div>
                        {formatter(items.reduce((a, b) => parseFloat(a) + parseFloat(b.currency === 'USD' ? b.total : 0), 0.00), 'USD')}
                    </div>
                    <div>
                        {formatter(items.reduce((a, b) => parseFloat(a) + parseFloat(b.currency === 'EUR' ? b.total : 0), 0.00), 'EUR')}

                    </div>
                </div>
            </div>

            {
                items.map((item, index) => {
                    return <div key={index} className="div-row"
                        style={{
                            width: '100%', alignItems: 'center', justifyContent: 'space-between', padding: 10,
                            borderBottom: '1px solid #ccc',
                            backgroundColor: index % 2 === 0 ? '#fff' : '#f5f5f5'
                        }}
                    >
                        <div className="div-col"
                            style={{
                                width: '100%'
                            }}
                        >
                            <div
                                className="link"
                                onClick={() => {
                                    setShowModal(true);
                                    setFileDetails({
                                        fileId: item.fileId,
                                        clientPOId: item.clientPOId
                                    });
                                }}
                                style={{
                                    color: "blue"
                                }}
                            >File {item.fileId}</div>
                            <div
                                style={{
                                    width: '100%',
                                    alignItems: 'flex-start',
                                    flexWrap: 'wrap'
                                }}
                                className="div-row-space-between"
                            >
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <Moment format="MMM DD YYYY">{item.clientPODate}</Moment>
                                    <br />
                                    PO {item.clientPONumber}
                                    <br />
                                    {item.clientName}
                                    <div>
                                        {formatter(item.total, item.currency)}

                                    </div>
                                </div>
                                <div
                                    style={{
                                        flex: 1
                                    }}
                                >
                                    <b><Moment format="MMM DD YYYY">{item.vendorPOReceiveDate}</Moment></b>
                                    <br />

                                    <Moment format="MMM DD YYYY">{item.ETA}</Moment>
                                    <br />
                                    <ETABadge
                                        po={item}
                                    />
                                    {item.vendorName}
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    }


    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Delivered Purchase Orders</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div className="navigation-bar" >
            <div className="navigation-bar-content" >
                <Link to={`/admin/reports/`}><IoHomeSharp /></Link>
                <IoChevronForward />
                Purchase Orders
                <IoChevronForward />
                <Link to={`/admin/reports/pos/pending/`}>Pending</Link>
                <IoChevronForward />
                <b><Link to={`/admin/reports/pos/delivered/`}>Delivered</Link></b>
            </div>
        </div>
        <div className="content">


            {
                loading ?
                    <div className="div-row-center" style={{ height: 300 }}>

                        <Circles height={100} width={100} />
                    </div>
                    :
                    <div>
                        <div
                            className="div-row"
                        >
                            <div className="div-col"
                                style={{
                                    marginRight: 5
                                }}
                            >
                                From
                                <DatePicker className="date-picker-custom" selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <div className="div-col"
                                style={{
                                    marginRight: 5
                                }}
                            >
                                To
                                <DatePicker className="date-picker-custom" selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                            <div  >
                                Search<br />
                                <input
                                    style={{
                                        width: 150
                                    }}
                                    type="text"
                                    value={search}
                                    key="search"
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            DataFetch();
                                        }

                                    }}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <div
                                style={{
                                    padding: '15px 0px 0px 10px'
                                }}
                            >
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        DataFetch();
                                    }}
                                    style={{
                                        width: 120
                                    }}
                                >
                                    {"Search"}
                                </button>
                            </div>
                        </div>

                        <PendingPurchaseOrdersList
                            items={data?.data?.delivered}
                        />
                    </div>

            }







        </div>
    </div>
    );
}

export default PODelivered;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 550
    },
    overlay: {
        background: "#00000090"
    }
};