import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Circles, ThreeDots } from "react-loader-spinner";
import FileDocumentsAPI from "../../api/FIleDocumentsAPI";

import { IoDownload } from "react-icons/io5";
import Styles from "../../styles";

import Moment from 'react-moment';
import { animateScroll as scroll } from 'react-scroll'

import Modal from 'react-modal';

const fnDocSize = (docSize) => {
    let strSize = Math.round((docSize / 1000), 2) / 1000;
    strSize += " Mb";

    return strSize
}

const Documents = () => {
    const [fileDocuments, setFileDocuments] = React.useState([]);

    const [fetchingDocs, setFecthingDocs] = React.useState(false);
    const [deleting, setDeleting] = React.useState({});

    const [zippingDocs, setZipppingDocs] = React.useState(false);
    const [zipDocsResult, setZipDocsResult] = React.useState({});


    const [disableDelete, setDisableDelete] = React.useState(false);
    const [allowDelete, setAllowDelete] = React.useState(false);

    const [downloadLink, setDownloadLink] = React.useState();
    const [showModal, setShowModal] = React.useState(false);

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
        fetching,
        MODAL_BACKGROUND
    } = dataReducer;
    const dispatch = useDispatch();

    const DataFetch = ({
        fileId
    }) => {

        setFecthingDocs(true);
        FileDocumentsAPI.documentsFetch({ fileId }).then(
            res => {
                setFecthingDocs(false);
                setFileDocuments(res);
            }
        )
    }


    const DocumentsZip = ({
        fileId,
        docIds
    }) => {

        setZipppingDocs(true);
        FileDocumentsAPI.documentsZip({
            fileId,
            docIds: docIds || []
        }).then(
            res => {
                setZipppingDocs(false);
                setZipDocsResult(res);
            }
        )
    }


    const DocumentDelete = ({
        id
    }) => {
        let fileId = file.file.fileId;
        let _deleting = { ...deleting };
        _deleting[id] = true;
        setDeleting(_deleting);
        setDisableDelete(true);
        FileDocumentsAPI.documentDelete({ fileId, id }).then(
            res => {

                setDisableDelete(false);
                setFileDocuments(fileDocuments.filter(d => d.id !== id))


            }
        )
    }

    /* eslint-disable */
    React.useEffect(() => {
        if (file?.file.fileId) {
            DataFetch({
                fileId: file.file.fileId
            })
        }
    }, []);
    React.useEffect(() => {
        if (fetching === 'REFRESHING_FILE_DOCUMENTS') {
            DataFetch({
                fileId: file.file.fileId
            });
            dispatch({
                'type': 'FETCHING',
                fetching: null
            });
        }
    }, [fetching]);
    /* eslint-enable */

    if (fetchingDocs) return <div
        style={{
            padding: 30,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
        }}
    >
        <Circles
            color={"orange"}
            height={50}
        />
    </div>

    return <div
        style={{
            padding: 10
        }}
    >








        <Modal
            isOpen={showModal}
            contentLabel="Example Modal"
            style={customStyles}
        >
            <div style={{
                width: 350,
                height: zipDocsResult.url ? 100 : 400,

            }}>
                <div

                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    {!zipDocsResult.url &&
                        <button
                            style={{
                            }}
                            onClick={() => {
                                DocumentsZip({
                                    fileId: file.file.fileId,
                                    docIds: [] //all
                                });
                            }}
                        >
                            {zippingDocs ?

                                "Zipping..."
                                :
                                "Zip Files"
                            }
                        </button>
                    }
                    <button
                        onClick={() => {
                            if (!zipDocsResult.url) {
                                setShowModal(false)
                            }
                            setZipDocsResult({});
                        }}
                    >Close</button>
                </div>

                <div>

                    {zipDocsResult.url ?
                        <div
                            style={{
                                textAlign: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 20
                            }}
                        >
                            <a target="_blank" rel='noreferrer' href={`${zipDocsResult.url}`}>Download</a>
                            &nbsp;
                            <IoDownload size={20} />
                        </div>
                        :

                        zippingDocs ?
                            <div style={{
                                paddingTop: 50,
                                display: 'flex',
                                flexDirection: 'column',

                                alignItems: 'center'
                            }}>
                                <Circles
                                    color={'orange'}
                                />
                                <br /><br />
                                <div>Zipping Files</div>
                            </div>

                            :
                            fileDocuments?.map((document, ix) => <Styles.DivRow
                                key={document.id}
                                style={{
                                    fontSize: 14
                                }}
                            >
                                <div style={{
                                    backgroundColor: '#c0c0c0',
                                    borderRadius: 5,
                                    padding: 5,
                                    marginRight: 10,
                                    flex: 1,
                                    textAlign: 'center'
                                }}>{ix + 1}</div>
                                <div style={{
                                    flex: 10,
                                }}>{document.docName}</div>
                            </Styles.DivRow>
                            )
                    }



                </div>
            </div>
        </Modal>


















        {
            fileDocuments?.length > 0 && <Styles.DivRow
                style={{
                    borderStyle: 'none',
                    justifyContent: 'space-between'
                }}
            >
                <button
                    style={{
                        backgroundColor: allowDelete ? 'red' : 'transparent'
                    }}
                    onClick={() => {
                        setShowModal(true);
                    }}
                >Share/ Compress</button>

                <button
                    style={{
                        backgroundColor: allowDelete ? 'red' : 'transparent',
                        color: allowDelete ? '#fff' : '#000',
                    }}
                    onClick={() => setAllowDelete(!allowDelete)}
                >Delete Files</button>
            </Styles.DivRow>
        }

        {fileDocuments?.map(document => {
            return <Styles.DivRow key={document.id}>

                <Styles.DivCol
                    style={{
                        width: '100%'
                    }}
                >

                    {document.docName}
                    <Styles.DivRow
                        style={{
                            borderStyle: 'none',

                            padding: 0,
                            width: '100%'
                        }}
                    >
                        {fnDocSize(document.docSize)}

                        <Moment date={document.createDT} format="MMM-DD HH:mm" tz="America/New_York" />
                    </Styles.DivRow>
                    <button
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: 5,
                            cursor: 'pointer',
                            color: 'blue'
                        }}
                        onClick={() => {
                            setDownloadLink(null);
                            dispatch({
                                type: 'MODAL_BACKGROUND',
                                show: true
                            });
                            scroll.scrollToTop();
                            FileDocumentsAPI.documentFetch({
                                id_array: [document.id],
                                fileId: file.file.fileId
                            }).then(res => {
                                setDownloadLink(res[0]);
                            });
                        }}
                    >
                        Download
                        <IoDownload size={20} />
                    </button>
                </Styles.DivCol>

                {allowDelete &&
                    <div>
                        &nbsp;
                        <Styles.ButtonDelete
                            disabled={disableDelete}
                            style={{
                                backgroundColor: disableDelete ? '#c0c0c0' : '#fff'
                            }}
                            onClick={() => {
                                if (window.confirm('Are you sure you wish to delete this document')) {
                                    DocumentDelete({
                                        id: document.id
                                    })

                                }
                            }}
                        >
                            {
                                !deleting[document.id] ?
                                    "Delete"
                                    :
                                    <ThreeDots color={"red"} width={30} />
                            }
                        </Styles.ButtonDelete>
                    </div>
                }
            </Styles.DivRow>
        }
        )}

        {!!MODAL_BACKGROUND && <div style={{
            backgroundColor: '#fff',
            width: 300,
            height: 220,
            right: 0,


            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',

            zIndex: 2010,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
            textAlign: 'center'
        }}>
            {!!downloadLink ?
                <div>
                    <button
                        onClick={() => dispatch({ type: 'MODAL_BACKGROUND', show: false })}
                        style={{
                            position: 'absolute',
                            top: 5, right: 5
                        }}
                    >Close</button>
                    <div style={{
                        width: 280,
                        textAlign: 'center',
                        textOverflow: 'hidden',
                        color: 'blue'
                    }}>{downloadLink.docName}</div>
                    <br />
                    <Moment date={downloadLink.createDT} format="MMM-DD HH:mm" tz="America/New_York" />

                    <Styles.DivRow
                        style={{
                            borderStyle: 'none',
                            width: 100,
                            margin: 'auto',
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <a target="_blank" rel='noreferrer' href={`${downloadLink.url}`}>Download</a>

                        <IoDownload size={20} />
                    </Styles.DivRow>
                    <div style={{
                        textAlign: 'center'
                    }}>{fnDocSize(downloadLink.docSize)}</div>

                </div>
                :
                <Circles
                    color={"orange"}
                    height={50}
                />
            }
        </div>
        }
    </div>
}


function FileUpload(props) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [uploadingFiles, setUploadingFiles] = React.useState(0);
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;
    const dispatch = useDispatch();

    const files = uploadFiles.map(file => (
        <li key={file.path}>
            {file.path} <br />
            {fnDocSize(file.size)}
        </li>
    ));



    /* eslint-disable */
    React.useEffect(() => {
        setUploadFiles(acceptedFiles);
        if (acceptedFiles.length) {
            dispatch({
                type: 'PASS_MESSAGE',
                message: 'hide_file_documents'
            });
        }
    }, [acceptedFiles]);
    /* eslint-enable */


    if (uploadingFiles > 0) return <div
        style={{
            padding: 30,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        }}
    >
        <Circles
            color={"green"}
            height={50}
        />

        <h4>Uploading {uploadingFiles}</h4>
    </div>

    return (
        <section >


            {uploadFiles.length === 0 && <div>

                <div
                    style={{
                        backgroundColor: '#c0c0c0',
                        padding: 20,
                        margin: 10,
                        borderRadius: 5,
                        cursor: 'pointer',
                        textAlign: 'center'
                    }}
                    {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag & Drop or  click to select files</p>
                </div>
            </div>
            }



            {uploadFiles.length > 0 && <div style={{
                margin: 5
            }}>
                <h4>Upload Files</h4>
                <ul>{files}</ul>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    <button
                        style={{
                            height: 30,
                            backgroundColor: 'green',
                            color: '#fff',
                            width: 150,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => {
                            let fileId = file.file.fileId;
                            let _uploadingFiles = uploadFiles.length
                            setUploadingFiles(_uploadingFiles);
                            uploadFiles.forEach(file => {

                                let formData = new FormData()
                                formData.append('File', file);
                                FileDocumentsAPI.fileUpload({
                                    fileId,
                                    formData
                                }).then(res => {
                                    _uploadingFiles = _uploadingFiles - 1
                                    setUploadingFiles(_uploadingFiles)
                                    if (_uploadingFiles === 0) {
                                        setUploadFiles([]);
                                        dispatch({
                                            type: 'PASS_MESSAGE',
                                            message: null
                                        });
                                        dispatch({
                                            'type': 'FETCHING',
                                            fetching: 'REFRESHING_FILE_DOCUMENTS'
                                        });
                                    }
                                });
                            });
                        }}
                    >
                        {uploadingFiles > 0 ?
                            <ThreeDots color={"orange"} height={10} />
                            :
                            "Upload Files"
                        }

                    </button>
                    <button
                        style={{ height: 30 }}
                        disabled={uploadingFiles > 0}
                        onClick={() => {
                            setUploadFiles([]);
                            dispatch({
                                type: 'PASS_MESSAGE',
                                message: null
                            });
                        }}
                    >
                        {uploadingFiles > 0 ?
                            <ThreeDots color={"orange"} height={10} />
                            :
                            " Clear Files"
                        }
                    </button>

                </div>
            </div>}

        </section>
    );
}



const FileDocuments = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        message,
    } = dataReducer;
    if (!process.env.REACT_APP_MODULE_FILE_DOCS) {
        return <div>Module FILE_DOCS not enabled</div>
    }

    return <div>

        <FileUpload />
        {message !== 'hide_file_documents' &&

            <Documents />
        }
    </div>
}



export default FileDocuments;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};