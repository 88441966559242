import React from "react";
import Styles from "../../styles";
import Header from './HeaderClient';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useParams } from "react-router-dom";


import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import pAPI from "../../api/publicAPI";
import {
    IoLogoWhatsapp,
} from "react-icons/io5";
import InvalidToken from "./InvalidToken";
import Fetching from "./Fetching";
import ClientMenu from "./ClientMenu";


function WhatsAppRFQ() {

    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();


    let token = useParams().code;

    const [fetching, setFetching] = React.useState(true)
    const [invalidToken, setInvalidToken] = React.useState(false);


    const {
        CLIENT_INFO,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        setFetching(true);
        pAPI.fetch({ token }).then(res => {
            setFetching(false);
            if (!res.success) {
                setInvalidToken(true);
            } else {
                dispatch({
                    type: 'CLIENT_INFO_SET',
                    CLIENT_INFO: res
                });
            }
        })
    }, []);
    /* eslint-enable */

    if (invalidToken) return <InvalidToken />

    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Prodek Inc - RFQ</title>
            </Helmet>
        </HelmetProvider>
        <div
            style={{
                position: 'sticky',
                top: 0
            }}
        >
            <Header
                token={token}
            />
        </div>
        <div style={{
            margin: "auto",
            marginTop: 20,
            maxWidth: 650,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center"
        }}>


            {fetching ?


                <Fetching />
                :
                <div

                    className="div-col"
                    style={{
                        margin: 'auto',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: 650,
                    }}
                >


                    <Styles.H1 style={{ textAlign: 'center' }} >{CLIENT_INFO.account.clientName}</Styles.H1>
                    <div style={{ textAlign: 'center' }}>
                        <h4  >{CLIENT_INFO.account.clientName}</h4>
                        <h4  >{CLIENT_INFO.account.contactName}</h4>

                    </div>

                    <br />
                    <ClientMenu
                        token={token}
                    />

                    <div
                        style={{
                            textAlign: 'center',
                            padding: 20,
                            width: 350,
                            margin: 'auto'
                        }}
                    >
                        <Trans i18nKey="RFQConfirm">

                        </Trans>

                        <a style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            padding: 10
                        }} href='https://wa.me/17863758255'><IoLogoWhatsapp size={20} /> +1 (786) 375-8255</a>

                    </div>

                </div>


            }
        </div>


    </div>
    );
}

export default WhatsAppRFQ;

