import React from "react";
import Nav from 'react-bootstrap/Nav';

import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from "react-i18next";

const ClientMenu = ({
    option,
    token,
}) => {

    const { t } = useTranslation();


    return (
        <Nav variant={"pills"}
            fill
            defaultActiveKey="/home"
            style={{
                marginTop: 20,
                marginBottom: 20,
                zIndex: 100
            }}
        >
            <Nav.Item>
                <LinkContainer to={`/w2/${token || ""}`}>
                    <Nav.Link href="#quotes">{t("Quotes")}</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            <Nav.Item>
                <LinkContainer to={`/w3/${token || ""}`}>
                    <Nav.Link href="#quotes">{t("POSList")}</Nav.Link>
                </LinkContainer>
            </Nav.Item>
            <Nav.Item>
                <LinkContainer to={`/w4/${token || ""}`}>
                    <Nav.Link href="#quotes">{t("Invoices")}</Nav.Link>
                </LinkContainer>
            </Nav.Item>
        </Nav>
    );


}


export default ClientMenu;
