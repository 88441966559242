const token = localStorage.getItem("token") || "";

const FileDocumentsAPI = {

    documentsZip: (args) => { 
        return new Promise((resolve, reject) => {
            let {
                fileId
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/library/${fileId}/documents/zip/`; 
           
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            
            fetch(url, {
                headers,
                method: 'POST',  
                body: JSON.stringify({
                    docIds: args.docIds
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
    
    documentFetch: (args) => { 
        return new Promise((resolve, reject) => {
            let {
                fileId,
                id_array
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/library/${fileId}/documents/?id_array=${id_array}`; 
           
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            
            fetch(url, {
                headers,
                method: 'GET', 
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    documentDelete: (args) => { 
        return new Promise((resolve, reject) => {
            let {
                fileId,
                id
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/library/${fileId}/documents/${id}`; 
           
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            
            fetch(url, {
                headers,
                method: 'DELETE', 
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    documentsFetch: (args) => { 
 
        return new Promise((resolve, reject) => {
            let {
                fileId
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/library/${fileId}`; 
           
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET', 
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    fileUpload: (args) => { 
        return new Promise((resolve, reject) => {
            let {
                fileId
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/library/${fileId}/documents`; 
           
            var headers = new Headers(
                {
                    //'Content-Type':'multipart/form-data'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
                body: args.formData
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}


export default FileDocumentsAPI;