import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import {
    useParams,
    useNavigate,
    Link
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Select from "react-select";
import { Circles, ThreeDots } from "react-loader-spinner";

import WHAPI from "../api/WHAPI";

const CARRIERS = [
    {
        "label": "UPS",
        "value": "ups"
    },
    {
        "label": "Fedex",
        "value": "fedex"
    },
    {
        "label": "USPS",
        "value": "usps"
    },
]

const VendorPOTracking = () => {

    const [trackingNumber, setTrackingNumber] = React.useState("");
    const [carrier, setCarrier] = React.useState("select");
    const [fetching, setFetching] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const dispatch = useDispatch();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        vendorPO,
    } = dataReducer;

    const fnAdd = () => {

        if (carrier === 'select' || trackingNumber === '') {
            return (alert("All fields are required"));
        }
        setFetching(true);
        WHAPI.vendorPOTrackinNumberAdd_Delete({
            vendorPOId: vendorPO.vendorPO.vendorPOId,
            carrier: carrier.value,
            trackingNumber
        }).then(res => {
            if (res.success) {
                setCarrier('select');
                setTrackingNumber("");
                dispatch({
                    'type': 'VENDOR_PO_FETCH',
                    vendorPOId: vendorPO.vendorPO.vendorPOId
                })
                setFetching(false);
            } else {
                setFetching(false);
                alert(res.msg)
            }
        })
    }
    const fnDelete = ({
        id
    }) => {

        if (!window.confirm("Are you sure you wish to delete this tracking number?")) {
            return;
        }

        setDeleting(true);
        WHAPI.vendorPOTrackinNumberAdd_Delete({
            vendorPOId: vendorPO.vendorPO.vendorPOId,
            id,
            delete_: true
        }).then(res => {
            if (res.success) {
                dispatch({
                    'type': 'VENDOR_PO_FETCH',
                    vendorPOId: vendorPO.vendorPO.vendorPOId
                })
                setDeleting(false);
            } else {
                setFetching(false);
                alert(res.msg)
            }
        })
    }
    if (vendorPO === null) return null;

    return <div
        style={{
            paddingTop: 10,
        }}
    >
        <div
            style={{
                minWidth: 350,
                maxWidth: 600,
                margin: 'auto',
                padding: 5
            }}
        >

            <div style={{
                fontWeight: 'bold',
                textAlign: 'center',
                margin: 10
            }}>Vendor PO Tracking Numbers</div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <label style={{
                    width: 250,
                    marginBottom: 10
                }}>
                    Carrier<br />
                    <Select
                        onChange={setCarrier}
                        value={carrier}
                        width={70}
                        options={CARRIERS}
                        style={{ fontSize: 13 }}
                    >
                    </Select>
                </label>

                <label  >
                    Tracking Number<br />
                    <input
                        style={{
                            width: 250,
                            fontSize: 17
                        }}
                        value={trackingNumber || ""}
                        onChange={e => setTrackingNumber(e.target.value)}
                    />
                </label>


                <Styles.ButtonMain
                    disabled={fetching}
                    onClick={fnAdd}
                    style={{ width: 200, padding: 10, marginTop: 20 }}
                >{fetching ?
                    "Adding..."
                    :
                    "Add Tracking Number"
                    }
                </Styles.ButtonMain>
            </div>
            <div style={{
                marginTop: 10
            }}>
                {vendorPO?.vendorPOTracking.map((t, ix) => <Styles.DivRow
                    style={{
                        borderWidth: 1, borderColor: '#c0c0c0',
                        borderTopStyle: ix === 0 && 'solid'
                    }}
                    key={ix}
                >
                    <span style={{ flex: 3 }} >{t.carrier.toUpperCase()}</span>
                    <span style={{ flex: 5, textAlign: 'right', marginRight: 30 }} >{t.trackingNumber}</span>
                    <span style={{ flex: 2 }}>
                        <Styles.ButtonDelete
                            disabled={deleting}

                            onClick={() => fnDelete({ id: t.id })}
                        >{
                                deleting ?
                                    <ThreeDots width={50} height={20} />
                                    :
                                    "Remove"
                            }</Styles.ButtonDelete>
                    </span>
                </Styles.DivRow>)}
            </div>
        </div>
    </div >
}



const VendorPOWarehouseReceipts = () => {

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        vendorPO,
    } = dataReducer;

    if (!vendorPO) return null;

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 10,
        }}
    >
        <div style={{
            margin: 10
        }}>


            <Styles.ButtonMain
                onClick={() => {

                    navigate(`/admin/wh/vendorPO/${vendorPO.vendorPO.vendorPOId}/receiveV2/${vendorPO.file.fileId}/0`)
                }
                }
            >
                Receive New W/R
            </Styles.ButtonMain>

        </div>
        {
            vendorPO.whReceipts.map((wr, ix) => <div
                style={{
                    padding: 5
                }}
                key={ix}

            >
                <Link style={{ color: 'blue' }} to={`/admin/wh/vendorPO/${vendorPO.vendorPO.vendorPOId}/receiveV2/${vendorPO.file.fileId}/${wr.id}`}>W/R {vendorPO.file.fileId}{wr.id}</Link>
            </div>)

        }


    </div>

}

function WRReceiveV2() {

    const [trackingNumbersView, setTrackingNumbersView] = React.useState(false);
    let vendorPOId = useParams().vendorPOId;
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        vendorPO,
        fetching
    } = dataReducer;

    const VendorPOSummary = () => vendorPO && <div
        style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}
    >

        <h2>Vendor PO {vendorPO?.vendorPO.vendorPONumber}</h2>
        <b>{vendorPO?.vendorPO.vendorName}</b>
        <span style={{ fontSize: 12 }}>File {vendorPO.file.fileId} - {vendorPO.client.clientName}</span>
    </div>

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            'type': 'VENDOR_PO_FETCH',
            vendorPOId
        });

    }, []);
    /* eslint-enable */

    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>{`WR Receive`}</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        {fetching === "vendor_po_fetching" && <Styles.DivFetching>
            <Circles
                color="#FF9400"
            />
        </Styles.DivFetching>}
        <div style={{
            margin: "auto",
            marginTop: 20,
            maxWidth: 650,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <Styles.H1 >Receive W/R</Styles.H1>

            <Link to="/admin/wh">Warehouse</Link>

            <Link style={{ color: 'blue', margin: 5 }} to={`/admin/wh/file/${vendorPO?.file.fileId}?s=wrs&sc=pos`}>File {vendorPO?.file.fileId}</Link>


            <VendorPOSummary />

            <VendorPOWarehouseReceipts />

            {fetching !== "vendor_po_fetching" &&

                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <button
                        style={{
                            margin: 'auto',
                        }}
                        onClick={() => {
                            setTrackingNumbersView(!trackingNumbersView)
                        }}
                    >
                        {trackingNumbersView ?
                            'Hide Tracking Numbers'
                            :
                            'Show Tracking Numbers'
                        }
                    </button>
                    {trackingNumbersView &&
                        <VendorPOTracking />
                    }
                </div>
            }
        </div>
    </div>
    );
}

export default WRReceiveV2;