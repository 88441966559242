import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import { Circles, ThreeDots } from "react-loader-spinner";

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from "react-router-dom";

import Moment from 'react-moment';
import 'moment-timezone';

import WHAPI from "../api/WHAPI";

const WRDetails = () => {
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
        wr
    } = dataReducer;

    const [fetching_2, setFetching_2] = React.useState(false);



    const DataUpdate = async ({
        statusUpdate,
        undo,
        fileId,
        wrId
    }) => {
        setFetching_2(true);
        let res = await WHAPI.wrReleaseStatusUpdate({
            fileId,
            wrId,
            statusUpdate,
            undo
        })
        setFetching_2(false);
        if (res.success) {
            dispatch({
                type: "WR_FETCH",
                fileId,
                wrId
            })
        } else {
            alert(res.msg)
        }
    }



    if (fetching || !wr) return null;


    let WR = wr.wr;


    return <div>

        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <span>{WR.packages} {WR.packageType}</span>
            <span>{WR.height} x {WR.length} x {WR.width} {WR.weight}lbs</span>

            <span>{wr.client.clientName}</span>

            <div style={{
                textAlign: 'center'
            }}>
                <span>Received <Moment date={WR.receivedDateTime} format="MMM-DD HH:mm" tz="America/New_York" /></span>
                <br />


                {WR.releaseAuthorized === 1 && WR.released === 0 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            backgroundColor: 'green',
                            color: '#fff',
                            borderRadius: 5,
                            padding: 10
                        }}>Released Authorized <Moment date={WR.releaseAuthorizedDT} format="MMM-DD HH:mm" tz="America/New_York" /></div>
                        <Styles.ButtonCancel
                            disabled={fetching_2}
                            style={{
                                width: 200,
                                marginTop: 10
                            }}
                            onClick={() => {
                                if (!window.confirm("Are you sure you wish to undo the W/R authorization")) {
                                    return
                                }
                                DataUpdate({
                                    wrId: WR.id,
                                    fileId: WR.fileId,
                                    statusUpdate: 'releaseAuthorize',
                                    undo: true
                                }).then(res => {
                                    console.warn(res)
                                });
                            }}
                        >
                            {fetching_2 ?
                                <ThreeDots
                                    width={50}
                                    color={"red"}
                                />
                                :
                                "Cancel Release Authorization"
                            }

                        </Styles.ButtonCancel>
                        <Styles.ButtonMain
                            disabled={fetching_2}
                            style={{
                                width: 200,
                                marginTop: 10,
                                alignSelf: 'center'
                            }}
                            onClick={() => {
                                if (window.confirm("Are you sure you wish to release this W/R")) {
                                    DataUpdate({
                                        wrId: WR.id,
                                        fileId: WR.fileId,
                                        statusUpdate: 'release',
                                    }).then(res => {
                                        console.warn(res)
                                    });
                                }
                            }}
                        >
                            {fetching_2 ?
                                <ThreeDots
                                    width={50}
                                    color={"#fff"}
                                />
                                :
                                "Release"
                            }

                        </Styles.ButtonMain>
                    </div>
                    :
                    <div>
                        {WR.releaseAuthorized === 1 ?
                            <span>Authorized <Moment date={WR.releaseAuthorizedDT} format="MMM-DD HH:mm" tz="America/New_York" /></span>

                            :
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ padding: 10, borderRadius: 5, margin: 10, backgroundColor: 'red', color: '#fff', textAlign: 'center' }}>Not authorized for release</span>





                                <Styles.ButtonMain
                                    disabled={fetching_2}
                                    style={{
                                        width: 200,
                                        marginTop: 10,
                                        alignSelf: 'center'
                                    }}
                                    onClick={() => {
                                        DataUpdate({
                                            wrId: WR.id,
                                            fileId: WR.fileId,
                                            statusUpdate: 'releaseAuthorize',
                                        }).then(res => {
                                            console.warn(res)
                                        });
                                    }}
                                >
                                    {fetching_2 ?
                                        <ThreeDots
                                            width={50}
                                            color={"#fff"}
                                        />
                                        :
                                        "Authorize for Release"
                                    }

                                </Styles.ButtonMain>

                            </div>

                        }
                    </div>

                }


                {WR.released === 1 &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <div style={{
                            backgroundColor: 'green',
                            color: '#fff',
                            borderRadius: 5,
                            padding: 10
                        }}>Released <Moment date={WR.releasedDT} format="MMM-DD HH:mm" tz="America/New_York" /></div>


                        <Styles.ButtonCancel
                            disabled={fetching_2}
                            style={{
                                width: 200,
                                marginTop: 10
                            }}
                            onClick={() => {
                                if (!window.confirm("Are you sure you wish to undo the W/R release")) {
                                    return
                                }
                                DataUpdate({
                                    wrId: WR.id,
                                    fileId: WR.fileId,
                                    statusUpdate: 'release',
                                    undo: true
                                }).then(res => {
                                    console.warn(res)
                                });
                            }}
                        >
                            {fetching_2 ?
                                <ThreeDots
                                    width={50}
                                    color={"red"}
                                />
                                :
                                "Undo Release"
                            }

                        </Styles.ButtonCancel>
                    </div>
                }



            </div>

        </div>
    </div>
}

function WRRelease() {

    const { wrId, fileId } = useParams();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "WR_FETCH",
            fileId,
            wrId
        })
    }, []);
    /* eslint-enable */



    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flexShrink: 0,
            flexGrow: 1,
            maxWidth: 600,
        }}>
            <div>
                <a href={`/admin/wh/file/${fileId}/wr/${wrId}`}>WR Details</a>
            </div>
            <Styles.H1 >Release W/R {fileId}{wrId}</Styles.H1>


            {fetching ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        paddingTop: 200,
                        position: 'absolute',
                        backgroundColor: '#ffffff80',
                        width: '100%',
                        height: '1000'
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={100}
                    />
                </div>
                :
                <WRDetails />
            }



        </div>
    </div>
    );
}

export default WRRelease;