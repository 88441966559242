import React from "react";


var formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
var formatterEUR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
});

var formatter = (val, currency) => {
    if (currency === 'USD') {
        return formatterUSD.format(val);
    } else if (currency === 'EUR') {
        return formatterEUR.format(val);
    }
}

const ETABadge = ({
    po
}) => <div
    className={
        po.ETA_VS_DELIVERY <= 1 ?
            "badge_ badge_-2"
            :
            po.ETA_VS_DELIVERY > 7
                ?
                "badge_ badge_-3"
                :
                "badge_"
    }
    style={{
        fontSize: 15
    }}
>
        {po.ETA_VS_DELIVERY} days
    </div>



export {
    ETABadge,
    formatter
}