import React from "react";

import Moment from 'react-moment';
import { Circles } from "react-loader-spinner";
import FileNotes from "./FileNotes";
import API from '../../api/ReportsAPI';

import { ETABadge, formatter } from './index';

import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const VendorPO = ({
    po,
    fileDetails
}) => {
    const dispatch = useDispatch();
    const [followupDate, setFollowupdate] = React.useState(po.followupDT ? new Date(po.followupDT) : null);
    const [vendorPOUpdating, setVendorPOUpdating] = React.useState();
    const VendorPOUpdate = async ({
        fileId,
        vendorPOId,
        followupDate
    }) => {
        setVendorPOUpdating(true);
        const data = await API.File({
            fileId,
            vendorPOId,
            method: 'POST',
            body: {
                followupDate
            }

        });
        setVendorPOUpdating(null);
        if (data.error) {
            alert(data.msg);
        };
        dispatch({
            type: 'PENDING_POS_REFRESH',
            PENDING_POS_REFRESH: true
        });
    }

    if (!po || !fileDetails) return null;
    return <div className="div-col" style={{ marginTop: 10 }}>
        <div style={{ fontWeight: 'bold' }}>PO# {po.vendorPONumber} - {po.vendorName}</div>

        {!!po.vendorPOReceiveDate ?
            <div>
                <div style={{ color: 'green' }} className="div-row-space-between">
                    <div>
                        ETA <Moment add={{ days: po.vendorQuoteDeliveryDays }} date={po.vendorPODate} format="MMM DD YY" tz="America/New_York" />
                        <br />
                        Delivered <Moment date={po.vendorPOReceiveDate} format="MMM DD YY" tz="America/New_York" />

                    </div>

                    <div>
                        <ETABadge
                            po={po}
                        />
                    </div>

                </div>

            </div>
            :
            <div>
                <div className="div-row-space-between">
                    <div><Moment date={po.vendorPODate} format="MMM DD YY" tz="America/New_York" /> + {po.vendorQuoteDeliveryDays} days</div>

                    <div>ETA <Moment add={{ days: po.vendorQuoteDeliveryDays }} date={po.vendorPODate} format="MMM DD YY" tz="America/New_York" /></div>

                </div>
                <div style={{ backgroundColor: '#c0c0c0', margin: '10px 0', padding: 10, borderRadius: 5, width: 'fit-content' }}>
                    <div className="div-row-space-between" style={{ width: 'fit-content' }}>
                        <div>
                            Follow Up<br />
                            <DatePicker
                                className="date-picker-custom" selected={followupDate}
                                onChange={(date) => setFollowupdate(date)}
                                minDate={new Date()}
                            />
                        </div>
                        <div>
                            <br />
                            &nbsp;
                            <button
                                disabled={vendorPOUpdating}
                                onClick={() => {

                                    VendorPOUpdate({
                                        fileId: fileDetails.fileId,
                                        vendorPOId: po.vendorPOId,
                                        followupDate
                                    });

                                }}
                            >
                                {vendorPOUpdating ? "   ...   " : "Follow Up"}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        }

        <div>
            <div>{po.vendorContactName} <a target='_blank' rel="noreferrer" href={`mailto:${po.vendorContactEmail}`}>{po.vendorContactEmail}</a></div>
            <div>{po.vendorContactPhone}</div>
            <div>{po.vendorPhone1} {po.vendorPhone2}</div>
        </div>



    </div>
}

const VendorPOs = ({
    fileDetails
}) => {


    if (!fileDetails) return null
    return <div>{
        fileDetails.vendor_pos.map((po, index) => {
            return <VendorPO
                po={po}
                key={index}
                fileDetails={fileDetails}
            />

        })
    }</div>

}

const FileQuoteDetails = ({
    data,
    fileId,
    clientPOId
}) => {
    const [fileDetails, setFileDetails] = React.useState(data);
    const [fetching, setFetching] = React.useState();
    const [poStatus, setPOStatus] = React.useState("");
    const dispatch = useDispatch();

    const [clientPOUpdating, setClientPOUpdating] = React.useState();

    const FileFetch = async ({
        fileId,
        clientPOId
    }) => {
        if (fetching === 'file') return;
        setFetching("file");
        const data = await API.File({
            fileId,
            clientPOId,
            method: 'GET'
        });

        setPOStatus(data.data?.po?.clientPOStatusDesc || "");
        data.data.fetchedFromAPI = true;
        setFileDetails(data.data);
        setFetching(null);

    }

    const ClientPOUpdate = async ({
        fileId,
        clientPOId,
        poStatus
    }) => {
        if (!!clientPOUpdating) return;
        setClientPOUpdating(true);
        const data = await API.File({
            fileId,
            clientPOId,
            method: 'POST',
            body: {
                clientPOStatusDesc: poStatus,
            }
        });
        setClientPOUpdating(null);
        if (data.error) {
            alert(data.msg);
        };
        dispatch({
            type: 'PENDING_POS_REFRESH',
            PENDING_POS_REFRESH: true
        });
    }

    React.useLayoutEffect(() => {
        if (!fileId || !clientPOId || fetching === 'file') return;
        FileFetch({
            fileId,
            clientPOId
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (fetching === 'file') return (<div className="div-col" style={{ alignItems: 'center', margin: 50 }}>
        <Circles color="#000" height={70} width={70} />
    </div>);


    if (!fileDetails.po) {
        return <div>
            {fileId}
            {clientPOId}
        </div>
    }

    return <div>
        <div className="div-row-space-between" >
            <div>PO# {fileDetails.po.clientPONumber}</div>
            <div><Moment date={fileDetails.po.clientPODate} format="MMM DD YY" tz="America/New_York" /> </div>
        </div>
        <div>{fileDetails.clientName}</div>
        <div className="div-row-space-between" >
            <div>
                <div>{fileDetails.country}</div>
                <div> {fileDetails.salesRepName}</div>
                <div>{fileDetails.rfqName}</div>

                <div  >
                    {!fileDetails.fileFinished ?
                        <div style={{ color: 'blue' }}>{fileDetails.fileStatusName}</div>
                        :
                        <div style={{ color: 'green' }}>{fileDetails.finishStatusName}</div>
                    }
                    <div>{fileDetails.contactName}<br /><a href={`mailto:${fileDetails.contactEmail}`}>{fileDetails.contactEmail}</a></div>
                    <div>{fileDetails.contactPhone}</div>




                    {
                        !fileDetails.fileFinished && fileDetails.fileStatusId === 4
                        &&

                        <div className="div-row-space-between"

                            style={{ marginTop: 10 }}
                        >
                            <button
                            >
                                Approve
                            </button>

                            <button>
                                Reject
                            </button>
                            <select>

                            </select>


                            <button>
                                Follow Up
                            </button>
                        </div>
                    }



                </div>
            </div>
            <div
                style={{
                    textAlign: 'right'
                }}
            >
                <div>{formatter(fileDetails.po?.itemsAmount, fileDetails.rfqCurrency)}</div>
                <div>{formatter(fileDetails.po?.otherCostAmount, fileDetails.rfqCurrency)}</div>
                <div><b>{formatter(fileDetails.po?.itemsAmount + fileDetails.po?.otherCostAmount, fileDetails.rfqCurrency)}</b></div>
            </div>

            <div style={{ backgroundColor: '#c0c0c0', margin: '10px 0', padding: 10, borderRadius: 5 }}>

                <div className="div-row-space-between" style={{ width: 'fit-content' }}>
                    <div>
                        Client PO Status<br />
                        <input
                            style={{
                                width: 320
                            }}
                            type="text"
                            value={poStatus}
                            key="search"
                            onKeyDown={e => {


                            }}
                            onChange={(e) => setPOStatus(e.target.value)}
                        />
                    </div>
                    <div>
                        <br />
                        &nbsp;
                        <button
                            disabled={clientPOUpdating}
                            onClick={() => {
                                ClientPOUpdate({
                                    fileId: fileDetails.fileId,
                                    clientPOId: fileDetails.po.clientPOId,
                                    poStatus
                                });

                                return;

                            }}

                        >
                            {
                                clientPOUpdating ? "..." : "Update Status"
                            }

                        </button>
                    </div>
                </div>

            </div>
        </div>
        <hr />
        <div>
            <VendorPOs
                fileDetails={fileDetails}
            />

        </div>

        <hr />
        <div style={{
            marginBottom: 150
        }}>
            <FileNotes notes={fileDetails.notes} />
        </div>

        ./
    </div >

}


export default FileQuoteDetails;