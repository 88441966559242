import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Styles from "../styles";
import Menu from '../app/components/Menu';
import Header from '../app/components/HeaderWH';
import Moment from 'react-moment';
import 'moment-timezone';
import Modal from 'react-modal';

import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";

import { IoLogoWhatsapp, IoNotifications, IoWarningOutline, IoWarningSharp, IoCheckmarkCircle, IoCameraOutline, IoCloseCircleSharp } from "react-icons/io5";
import { Circles } from "react-loader-spinner";

import { useDispatch, useSelector } from 'react-redux';

///import WRPhoto from "../app/components/WRPhoto"
import ClientContactEdit from "../app/components/ClientContactEdit";

import FileDocuments from "../app/components/FileDocuments";
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const VendorPOItems = ({
    vendorPOId
}) => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;

    if (!file) return null;

    return <div
        style={{
            flex: 1,
            display: 'flex',
            width: '100%',
            flexDirection: 'column',

        }}
    >
        <hr />

        {file.vendorPOItems.filter(item => item.vendorPOId === vendorPOId).map((item, ix) => {

            return <div key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    borderBottomWidth: 1, borderBottomColor: '#c0c0c0', borderBottomStyle: 'solid',
                    borderTopWidth: ix === 0 ? 1 : 0,
                    borderTopColor: '#c0c0c0', borderTopStyle: 'solid',
                    padding: 5,
                }}
            >
                <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    color: (item.vendorPOItemQuantity !== item.unitsInWRIdentified) ? 'red' : 'green'
                }}>
                    <span style={{ color: 'blue', fontWeight: 'bold', flex: 1 }}>{ix + 1})</span>
                    <span style={{ flex: 15 }}>{item.vendorPOItemQuantity} x {item.vendorPOItemPartNumber}</span>
                    <span style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        marginRight: 5,
                        flex: 1
                    }} >
                        {item.unitsInWRIdentified || ""}
                        &nbsp;
                        {(item.vendorPOItemQuantity !== item.unitsInWRIdentified) ?
                            <span>
                                {
                                    !item.unitsInWRIdentified ?

                                        <IoWarningSharp size={25} />
                                        :
                                        <IoWarningOutline size={20} />
                                }
                            </span>
                            :
                            <IoCheckmarkCircle size={20} />
                        }
                    </span>
                </div>
                <span style={{ fontSize: 13, marginRight: 20 }}>{item.vendorPOItemDescription}</span>
            </div>
        })}
    </div>
}

const WHReceipts = () => {

    const navigate = useNavigate();
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */


    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;

    if (!file) return null;


    return <div>

        <div>
            {file.vendorPOs.map((po, ix) => <div
                key={ix}
                style={{
                    marginRight: 5
                }}
            >
                <button
                    onClick={() => navigate(`/admin/wh/vendorPO/${po.vendorPOId}/receiveV2/`)}

                >
                    Receive W/R for Vendor PO {po.vendorPONumber}
                </button>
            </div>)}
        </div>
        {file.whReceipts.length === 0 && <div
            style={{
                paddingTop: 20,
                textAlign: 'center'
            }}
        >
            No W/R have been received for this file
        </div>}
        {file.whReceipts.map((wr, ix) => {
            let showHeader = true;
            if (ix > 0) {
                showHeader = (wr.vendorPOId !== file.whReceipts[ix - 1].vendorPOId)
            }
            return <div
                key={ix}
                style={{
                    marginBottom: 10,
                    marginTop: 10
                }}
            >
                {showHeader && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 5,
                        paddingLeft: 10,
                        backgroundColor: '#c0c0c0',
                        alignItems: 'center',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                    }}
                >

                    Vendor PO# {wr.vendorPONumber}
                </div>
                }
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        borderTopWidth: 1,
                        borderTopColor: '#c0c0c0',
                        borderTopStyle: 'solid',
                        paddingLeft: 10
                    }}
                >
                    <div

                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 5,
                        }}
                    >
                        <div
                            style={{
                                marginRight: 25,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {wr.whName} WH
                            <Link to={`/admin/wh/file/${file.file.fileId}/wr/${wr.id}`} style={{ color: 'blue' }}>W/R {file.file.fileId}{wr.id}</Link>

                            <Moment date={wr.receivedDateTime} format="MMM-DD HH:mm" tz="America/New_York" />


                            <div
                                style={{
                                    alignContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <IoCameraOutline color={wr.photoCount === 0 ? "red" : "blue"} size={17} />&nbsp;{wr.photoCount}
                            </div>
                            <div>{wr.remarks}</div>

                        </div>
                        <div
                            style={{

                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >

                            {wr.isRepackedWR !== null &&
                                <span style={{ color: 'red' }}>{wr.isRepackedWR === 'consolidate' ? "Consolidated" : `Split ${file.file.fileId}${wr.splitFromWRId}`}</span>
                            }
                            {file.wr_packages.filter(w => w.wrId === wr.id).map((p, ix) => <div key={ix}>
                                <div>{p.p} {p.ptype}</div>
                                <div style={{ marginLeft: 10 }}>{p.rm}</div>
                            </div>)}


                        </div>

                    </div>

                </div>

                {file.WRItems.filter(i => i.wrId === wr.id).length === 0 ?

                    <div style={{ margin: 5, color: '#fff', display: 'flex', flexDirection: 'row', backgroundColor: 'red', width: 150, padding: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 5, justifyContent: 'center' }}><IoWarningSharp color="#fff" size={17} />&nbsp;W/R Content</div>
                    :
                    <div
                        style={{
                            margin: 10,
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                    >

                        <React.Fragment>
                            {
                                file.WRItems.filter(i => i.wrId === wr.id).map((item, ix) => <div key={ix} style={{ display: 'flex' }}>
                                    <span style={{ fontWeight: 'bold', width: 160 }}>{item.partNo}</span>
                                    <span style={{ fontWeight: 'bold', color: item.unitsReceived !== item.unitsOrdered ? 'orange' : 'green' }}>{item.unitsReceived} of {item.unitsOrdered} </span>
                                </div>)}
                        </React.Fragment>

                    </div>}


                <div style={{ width: 120, marginLeft: 20, textAlign: 'center' }}>
                    {(wr.releaseAuthorized === 1 && wr.released === 0) &&
                        <div style={{ backgroundColor: 'green', borderRadius: 5, padding: 5, color: '#fff' }}>Authorized</div>
                    }
                    {(wr.released === 1) &&
                        <div style={{ backgroundColor: 'blue', borderRadius: 5, padding: 5, color: '#fff' }}>Released</div>

                    }

                    {wr.poOrderItemsCount > 0 && wr.releaseAuthorized === 0 &&
                        <div style={{ width: 100, backgroundColor: '#c0c0c0', borderRadius: 5, padding: 5, }}>Ready</div>

                    }
                </div>

            </div>
        })}
    </div >

}




const ClientPOItems = ({
    items
}) => {
    return <div style={{


    }}>
        {items.map((item, ix) => <div
            style={{
                borderBottomWidth: 1, borderBottomColor: '#c0c0c0', borderBottomStyle: 'solid',
                padding: 5,
            }}
            key={ix}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <span style={{ color: 'blue', fontWeight: 'bold', flex: 1 }}>{ix + 1})</span>

                <span style={{ flex: 10 }}>{item.clientPOItemQuantity} x {item.rfqItemPartNumber}</span>

                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    color: (item.clientPOItemQuantity !== item.unitsInWRIdentified) ? 'red' : 'green'
                }}>
                    {item.unitsInWRIdentified || ""}
                    &nbsp;
                    {(item.clientPOItemQuantity !== item.unitsInWRIdentified) ?
                        <span>
                            {
                                item.unitsInWRIdentified === 0 ?

                                    <IoWarningSharp size={25} />
                                    :
                                    <IoWarningOutline size={20} />
                            }
                        </span>
                        :
                        <IoCheckmarkCircle size={20} />
                    }
                </div>

            </div>

            <div style={{ fontSize: 13 }}>{item.rfqItemDescription}</div>
            {item.invoiceId === null ?
                <div style={{ color: 'red', fontSize: 13 }}>Not Invoiced</div>
                :
                <div style={{ fontSize: 13, color: 'green' }}>Invoice {item.invoiceId}</div>
            }

        </div>)

        }
    </div>
}

const ClientPO = ({ cpo, items }) => {

    return <div
        style={{
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 5,
                backgroundColor: "#e0e0e0",
            }}
        >
            <span style={{ fontWeight: 'bold' }}>PO {cpo.clientPONumber}</span>
            <span><Moment date={cpo.clientPODate} format="MMM-DD HH:mm" tz="America/New_York" /></span>
        </div>

        <ClientPOItems items={items.filter(i => i.clientPOId === cpo.clientPOId)} />
    </div>
}

const ClientInvoices = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;
    if (!file) return null;

    if (file?.invoices?.length === 0) return null;

    return <div>
        {
            file.invoices.map((i, ix) => <div
                key={ix}
            >

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 5,
                        backgroundColor: "#e0e0e0",
                    }}
                >
                    <Link
                        to={`/admin/file/${file.file.fileId}/invoice/${i.invoiceId}`}
                        style={{ fontSize: 20, color: 'blue' }}
                    >
                        Invoice {i.invoiceId}
                    </Link>
                    <Moment date={i.invoiceDate} format="MMM-DD HH:mm" tz="America/New_York" />
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'flex-start',
                        marginBottom: 10, marginTop: 10,
                        fontSize: 14
                    }}
                >
                    <div style={{ flex: 1, }} dangerouslySetInnerHTML={{ __html: i.invoiceComment.replaceAll("\n", "<br/>") }} ></div>

                    <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: i.packingListComments.replaceAll("\n", "<br/>") }} ></div>
                </div>

                <div style={{ marginTop: 10, fontSize: 14 }}>
                    <span><b>Items</b></span>
                    {file.invoiceItems?.filter(ioc => ioc.invoiceId === i.invoiceId).map((item, ix2) => <Styles.DivRow
                        key={ix2 + "__"}
                    >
                        <div>
                            {ix2 + 1}) {item.q} x {item.partNumber}
                        </div>
                        <div>
                            {formatter.format(item.amount)}
                        </div>
                    </Styles.DivRow>)
                    }
                </div>
                <div style={{ marginTop: 10, fontSize: 14 }}>


                    <span><b>Other Costs</b></span>
                    {file?.invoiceOtherCosts?.filter(ioc => ioc.invoiceId === i.invoiceId).map((item, ix2) => <Styles.DivRow
                        key={ix2 + "_M_"}
                    >
                        <div>
                            {ix2 + 1})  {item.name}
                        </div>
                        <div>
                            {formatter.format(item.amount)}
                        </div>
                    </Styles.DivRow>)
                    }
                </div>


            </div>)
        }
    </div>
}

const ClientInfo = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;
    if (!file) return null;

    return <div
        style={{ padding: 10 }}
    >
        <div><b>{file.client.clientName}</b></div>
        <div>{file.client.clientTaxId}</div>
        <div>{file.client.clientAddress1}</div>
        <div>{file.client.clientAddress2}</div>
        <span style={{ fontSize: 10 }}>{file.client.clientId}</span>
    </div>

}


const ClientContacts = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;

    const [contactEdit, setContactEdit] = React.useState(null);

    const [search, setSearch] = React.useState("");
    const [contactListStatus, setContactListStatus] = React.useState("active");
    const [contacts, setContacts] = React.useState(file.clientContacts);


    const fnListFilter = () => {
        setContacts(file.clientContacts.filter(c => {

            return (
                (c.firstName.toUpperCase() + " " + c.lastName.toUpperCase()).indexOf(search.toUpperCase()) !== -1)
                &&
                (
                    contactListStatus === 'all' ?
                        true
                        :
                        contactListStatus === 'active' ? c.active : !c.active
                )

        }
        ));
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnListFilter();
    }, [search, contactListStatus]);
    /* eslint-enable */
    if (!file) return null;


    /* if (contactEdit !== null) return <ClientContactEdit 
             fileId={file.file.fileId} 
             clientId={file.client.clientId}
             fnClose={() => {
                 fnListFilter();
                 
                 setContactEdit(null)
             }} 
             contactEdit={contactEdit} 
         />*/

    return <div>
        {contactEdit !== null &&
            <Modal
                isOpen={true}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <ClientContactEdit
                    fileId={file.file.fileId}
                    clientId={file.client.clientId}
                    fnClose={() => {
                        fnListFilter();

                        setContactEdit(null)
                    }}
                    contactEdit={contactEdit}
                />
            </Modal>
        }

        <input
            style={{
                width: 200,
                fontSize: 17,
                marginRight: 10
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={"Search"}
        />
        <button
            onClick={() => {
                setContactEdit({});
            }}
        >Add Contact</button>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5,
        }}>
            <Styles.ButtonTab
                active={contactListStatus === 'active'}
                onClick={() => setContactListStatus('active')}
            >
                Active
            </Styles.ButtonTab>
            <Styles.ButtonTab
                active={contactListStatus === 'inactive'}
                onClick={() => setContactListStatus('inactive')}
            >
                Inactive
            </Styles.ButtonTab>
            <Styles.ButtonTab
                active={contactListStatus === 'all'}
                onClick={() => setContactListStatus('all')}
            >
                All
            </Styles.ButtonTab>
        </div>
        <div
            style={{
                marginBottom: 50,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#c0c0c0',
                borderBottomStyle: 'none',
                minHeight: 300
            }}
        >

            {contacts.map((contact, ix) => <div
                key={contact.id}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: '#c0c0c0',
                    padding: 5,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: 'blue',
                        justifyContent: 'space-between'
                    }}
                    onClick={() => setContactEdit(contact)}
                >

                    <span style={{
                        textDecoration: contact.active ? 'underline' : 'line-through',
                        color: contact.active ? 'blue' : 'red'
                    }}><b>{contact.lastName}</b>, {contact.firstName}</span>

                    <div
                        style={{
                            borderColor: contact.whatsAppAccess && contact.notify ? "green" : '#c0c0c0',
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: 5
                        }}
                    >
                        <IoLogoWhatsapp size={20} color={contact.whatsAppAccess ? "green" : "#c0c0c0"} style={{ marginRight: 10 }} />
                        <IoNotifications size={20} color={contact.whatsAppAccess && file.whatsapp_notify[contact.id] ? "green" : "#c0c0c0"} />
                    </div>
                </div>

                <div><i>{contact.contactType}</i></div>
                <div>
                    {contact.phone || ""}
                    <br />
                    {contact.email}
                </div>

            </div>)

            }
        </div>
    </div>
}

const ClientDetails = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;
    if (!file) return null;

    return <div>
        <ClientInfo />

        <ClientContacts />
    </div>

}


const ClientTab = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    const [section, setSection] = React.useState(searchParams.get("sc") || "pos");
    /* eslint-disable */
    React.useEffect(() => {
        setSearchParams({ sc: section, s: "client" })
    }, [section]);
    /* eslint-enable */
    return <div
        style={{
            paddingLeft: 5,
            paddingRight: 5
        }}
    >
        <Styles.ButtonTab2
            onClick={() => setSection("client")}
            active={section === 'client'}
        >
            Client
        </Styles.ButtonTab2>
        <Styles.ButtonTab2
            onClick={() => setSection("pos")}
            active={section === 'pos'}
            style={{ marginLeft: 10, marginRight: 10 }}
        >
            POS
        </Styles.ButtonTab2>
        <Styles.ButtonTab2
            onClick={() => setSection("invoices")}
            active={section === 'invoices'}

        >
            Invoices
        </Styles.ButtonTab2>

        {section === 'pos' && <React.Fragment>
            <h3>Client POs</h3>
            {file.clientPOs.map((cpo, ix) => <ClientPO cpo={cpo} items={file.clientPOItems} key={ix} />)}
        </React.Fragment>
        }
        {section === 'invoices' && <React.Fragment>
            <h3>Client Invoices</h3>
            <ClientInvoices />
        </React.Fragment>
        }
        {section === 'client' && <React.Fragment>

            <ClientDetails />
        </React.Fragment>
        }
    </div>
}

const FilesTab = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;

    /*
    removed this because uses up too much bandwidth
        const WRDocuments = () => <div style={{
            display: 'flex',
            flexDirection: 'row', 
            flexWrap: 'wrap', 
            paddingTop: 20,
        }}>
            {
                file?.photos.length === 0 &&
                <div style={{
                    padding: 50,
                }}>
                    No files yet
                </div>
            } 
            {file?.photos.map((photo, ix) => <WRPhoto 
                    key={ix} 
                    photo={photo} 
                    fileId={file.file.fileId} 
                    width={150}
                />)}
        </div>
    */

    if (!file) return null;



    return <div>
        <FileDocuments />
    </div>
}

const File = () => {

    const navigate = useNavigate();
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    const [section, setSection] = React.useState(searchParams.get("s") || 'wrs');
    /* eslint-enable */

    /* eslint-disable */
    React.useEffect(() => {
        setSearchParams({ s: section, sc: searchParams.get("sc") || "pos" })
    }, [section]);
    /* eslint-enable */


    const [showOptions, setShowOptions] = React.useState(false)
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        file,
    } = dataReducer;

    if (!file) return null;



    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: 650
        }}
    >
        <div style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <span><Moment date={file.file.fileCreateDateTime} format="MMM-DD-YY" tz="America/New_York" /></span>
            <span>{file.client.clientName}</span>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            marginBottom: 10,
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
            }}>
                <Styles.ButtonTab
                    onClick={() => setSection("client")}
                    active={section === "client"}
                >
                    Client
                </Styles.ButtonTab>
                <Styles.ButtonTab
                    onClick={() => setSection("vendor")}
                    active={section === "vendor"}
                >
                    Vendor
                </Styles.ButtonTab>
                <Styles.ButtonTab
                    onClick={() => setSection("wrs")}
                    active={section === "wrs"}
                >
                    W/R
                </Styles.ButtonTab>
                {process.env.REACT_APP_MODULE_FILE_DOCS &&
                    <Styles.ButtonTab
                        onClick={() => setSection("files")}
                        active={section === "files"}
                    >
                        Files
                    </Styles.ButtonTab>
                }

            </div>



            <div>
                {showOptions &&
                    <div
                        onClick={() => setShowOptions(false)}
                        style={{
                            position: 'absolute', top: 0, left: 0, backgroundColor: "#00000090",
                            height: '100%',
                            width: '100%',
                            zIndex: 100
                        }}

                    />
                }
                {file.whReceipts.length > 0 &&
                    <div style={{ position: 'relative' }}>
                        <Styles.ButtonMain
                            onClick={() => setShowOptions(true)}
                            style={{ marginRight: 5, fontSize: 13, color: '#000', marginBottom: 2, height: 28 }}
                        >
                            Options
                        </Styles.ButtonMain>
                        {showOptions &&

                            <div
                                style={{
                                    position: 'absolute',
                                    background: '#fff',
                                    padding: 5,
                                    borderStyle: 'solid', borderWidth: 1, borderColor: '#000',
                                    borderRadius: 5,
                                    borderTopRightRadius: 0,
                                    zIndex: 101,
                                    width: 210,
                                    height: 270,
                                    right: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    top: 0
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}
                                >
                                    <div style={{ width: 25 }} />
                                    <span style={{ fontSize: 17 }}>File Options</span>
                                    <IoCloseCircleSharp
                                        onClick={() => setShowOptions(false)}
                                        size={25}
                                    />
                                </div>
                                <hr style={{ width: '100%' }} />

                                <Styles.ButtonMain
                                    onClick={() => navigate(`/admin/wh/file/${file.file.fileId}/release`)}
                                    style={{ width: 140, color: '#fff', backgroundColor: 'blue' }}
                                >
                                    Release W/R
                                </Styles.ButtonMain>
                                <Styles.ButtonSecondary
                                    onClick={() => navigate(`/admin/wh/file/${file.file.fileId}/repack`)}
                                    style={{ width: 140, }}
                                >
                                    Repack
                                </Styles.ButtonSecondary>
                                <hr style={{ width: '100%' }} />
                                <Styles.ButtonCancel
                                    onClick={() => navigate(`/admin/wh/file/${file.file.fileId}/release`)}
                                    style={{ width: 100, fontSize: 13 }}
                                >
                                    Reset File
                                </Styles.ButtonCancel>
                            </div>
                        }
                    </div>
                }
            </div>



        </div>
        {section === "wrs" && <WHReceipts />

        }

        {section === "vendor" &&
            <div>
                {file.vendorPOs.map((vendorPO, ix) => <div key={ix}>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: 5,
                        backgroundColor: '#e0e0e0'
                    }}>
                        <span style={{ fontWeight: 'bold' }}>Vendor PO# {vendorPO.vendorPONumber}</span>
                        <span><Moment date={vendorPO.vendorPODate} format="MMM-DD HH:mm" tz="America/New_York" /></span>

                    </div>

                    <span style={{ padding: 5 }}>{vendorPO.vendorName}</span>


                    <div style={{ padding: 5 }}>{file.vendorPOTracking.map((t, ix) => <div key={ix}>
                        <span>{t.carrier.toUpperCase()}</span> <span style={{ fontWeight: 'bold' }}>{t.trackingNumber}</span>
                    </div>)}</div>

                    <VendorPOItems
                        vendorPOId={vendorPO.vendorPOId}
                    />

                </div>)}
            </div>
        }
        {section === "client" && <ClientTab />

        }
        {section === "files" && <FilesTab />

        }
    </div >
}





const WHFile = () => {

    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();
    let fileId = useParams().fileId || "";
    const {
        fetching,
        file,
        MODAL_BACKGROUND
    } = dataReducer;


    /* eslint-disable */
    React.useEffect(() => {
        //if (file?.fileId === fileId) return null;
        dispatch({
            type: "FILE_FETCH",
            fileId
        })
    }, []);
    /* eslint-enable */



    if (file?.authenticationFailed) {
        return <div style={{ padding: 50, color: 'red' }}>
            Unathorized
            <br />
            WR.jsx
            File {fileId}
            <br />
            <a href="/login">Login</a>
        </div>
    }


    return (<div style={{ height: '100%' }}  >
        <HelmetProvider>
            <Helmet>
                <title>File {fileId}</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <Link to={`/admin/wh/`} >Warehouse</Link>
            <Styles.H1 >File {fileId}</Styles.H1>


            {fetching === true ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={50}
                    />
                </div>
                :

                <File />

            }




        </div>
        <Styles.DevNote>
            FileWarehouse.jsx
        </Styles.DevNote>



        {!!MODAL_BACKGROUND &&
            <Styles.ModalDiv
                style={{
                    height: Math.max(
                        document.body.scrollHeight, document.documentElement.scrollHeight,
                        document.body.offsetHeight, document.documentElement.offsetHeight,
                        document.body.clientHeight, document.documentElement.clientHeight
                    )
                }}
                onClick={() => dispatch({ type: 'MODAL_BACKGROUND', show: false })}
            ></Styles.ModalDiv>
        }

    </div>
    );
}

export default WHFile;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};