import React from "react";
import Moment from 'react-moment';
import 'moment-timezone';

import { useSelector } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";

import { Circles } from "react-loader-spinner";
import Modal from 'react-bootstrap/Modal';

//import 'moment/locale/es';

import API from "../../api/publicAPI";

import { formatter } from '../../app/components/index';


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'

import { useDispatch } from 'react-redux';


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const Quotes = (
    {
        token
    }
) => {
    const dispatch = useDispatch();

    const [show, setShow] = React.useState(false);
    const handleCloseConfirm = () => setShow(false);
    const handleShowConfirm = () => setShow(true);
    const [confirmationType, setConfirmationType] = React.useState(null);
    const [rejectReason, setRejectReason] = React.useState(null);


    const [fetching, setFetching] = React.useState();
    const [quote, setQuote] = React.useState();
    const [showModal, setShowModal] = React.useState(false);
    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CLIENT_INFO,
    } = dataReducer;


    let quotes = CLIENT_INFO.files.filter(f => !f.fileHasPO && !f.fileHasInvoice && !f.fileFinished);

    if (!CLIENT_INFO || !CLIENT_INFO.files) return null;

    const QuoteFetch = async ({
        fileId,
        quoteId,

    }) => {
        if (fetching === 'file') return;
        setFetching("file");


        let data = await API.fileFetchUpdate({
            fileId,
            quoteId,
            token,
            method: 'GET'
        });
        setQuote(data.data);
        setFetching(null);

    }

    const fnQuoteAccept = async () => {

        setConfirmationType('accept');
        handleShowConfirm()

    }
    const fnQuoteReject = async () => {

        setConfirmationType('reject');
        handleShowConfirm()
    }
    const fnQuoteNA = async () => {

        setConfirmationType('no_response');
        handleShowConfirm()
    }
    const handleAcceptRejectConfirm = async () => {

        let payload = {
            fileId: quote.quote.fileId,
            quoteId: quote.quote.clientQuoteId,
            token,
            method: confirmationType === 'accept' ? 'POST' : confirmationType === "no_response" ? "PUT" : 'DELETE',
            body: confirmationType === 'reject' ? { rejectReason } : null

        }
        setFetching(true);
        await API.quoteAcceptReject(payload);
        let res = await API.fetchV2({ token });
        if (res.success) {
            dispatch({
                type: 'CLIENT_INFO_SET',
                CLIENT_INFO: res
            });
        }
        setFetching(false);
        setShowModal(false);
        setShow(false);
    }


    return <>

        <Modal

            show={show}
            centered
            onHide={() => {
                handleCloseConfirm();
                setRejectReason(null);
            }}
            animation={true}
            backdrop="static"
            style={{
                backgroundColor: "#c0c0c0",
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    style={{
                        color: confirmationType === 'accept' ? "green" : "red",
                    }}
                >
                    {
                        confirmationType === 'accept' ?

                            t('Accept')
                            :
                            confirmationType === 'no_response' ?

                                t('No Response')
                                :

                                t('Reject')

                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    minHeight: 200,


                }}
            >
                {
                    confirmationType === 'accept' &&
                    <Trans i18nKey="quote_accept_confirm">
                    </Trans>
                }

                {
                    confirmationType === 'no_response' &&
                    <Trans i18nKey="quote_no_response_confirm">
                    </Trans>
                }
                {
                    confirmationType === 'reject' &&

                    <div className="div-col">
                        <Trans i18nKey="quote_reject_confirm">
                        </Trans>
                        <br /><br />
                        <Form>
                            {[
                                {
                                    "id": 3,
                                    "name": "Price"

                                },
                                {
                                    "id": 4,
                                    "name": "Tech Specs"

                                },
                                {
                                    "id": 5,
                                    "name": "Delivery time"

                                },
                                {
                                    "id": 6,
                                    "name": "Late quote"

                                },
                                {
                                    "id": 8,
                                    "name": "Suspended project"

                                },
                                {
                                    "id": 9,
                                    "name": "Not interested"

                                },
                                {
                                    "id": 10,
                                    "name": "Not approved budget"

                                },
                                {
                                    "id": 11,
                                    "name": "Cancelled deal"

                                },
                                {
                                    "id": 7,
                                    "name": "Other"

                                },
                            ].map((item, index) => {
                                return <Form.Check
                                    type={"radio"}
                                    label={`${item.name}`}
                                    id={`${item.id}`}
                                    key={index}
                                    name="reason"
                                    onClick={() => setRejectReason({
                                        id: item.id,
                                        name: item.name
                                    })}
                                />
                            })}


                        </Form>
                    </div>

                }
            </Modal.Body>
            <Modal.Footer>
                <Button

                    disabled={fetching || (confirmationType === 'reject' && !rejectReason)}
                    onClick={handleAcceptRejectConfirm}
                    variant={confirmationType === 'accept' ? "success" : "danger"}
                >
                    {

                        fetching ?
                            <Spinner size="sm" />
                            :
                            confirmationType === 'accept' ?

                                t('Accept')
                                :
                                confirmationType === 'no_response' ?
                                    t("No Response")
                                    :
                                    t('Reject')

                    }
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            contentLabel="Invoice"
            backdrop="static"
            keyboard={false}
            size={isMobile ? "fullscreen" : "lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {!fetching && quote?.quote?.rfqName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body

            >

                {!!fetching ?
                    <div className="fetching-div">
                        <Circles
                            color="#FF9400"
                            height={80}
                        />
                    </div>
                    :
                    <div>
                        <div className="div-row-space-between">
                            <div>File {quote?.quote?.fileId}</div>

                            <div><Moment date={quote?.quote?.clientQuoteDate} format="MMM DD YYYY" tz="America/New_York" /></div>
                        </div>

                        <div>{quote?.quote?.rfqName}</div>
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            {formatter(quote?.quote?.totalAmount, quote?.quote?.currency)}
                        </div>



                        <div>
                            {quote?.quote_items.map((q, ix) => <div
                                key={ix}
                            >
                                <div>{ix + 1}) {q.partnumber}</div>

                                <div>{q.qty} x {formatter(q.amt, quote?.quote?.currency)}</div>
                                <div style={{
                                    fontSize: 13,
                                }}>{q.description}</div>
                            </div>)}
                        </div>

                        <div style={{ marginTop: 70, fontSize: 12, color: '#808080' }}>{"QuoteId " + quote?.quote?.clientQuoteId}</div>
                    </div>
                }



            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={!!fetching}
                    onClick={fnQuoteNA}
                    variant="dark"
                    size="sm"
                >
                    {t("No Response")}
                </Button>
                &nbsp;&nbsp;
                <Button
                    disabled={!!fetching}
                    onClick={fnQuoteReject}
                    variant="danger"
                    size="sm"
                >
                    {t("Reject")}
                </Button>

                <Button
                    disabled={!!fetching}
                    onClick={fnQuoteAccept}
                    variant="success"
                    size="sm"
                >
                    {t("Accept")}
                </Button>
            </Modal.Footer>
        </Modal>
        <div
            style={{
                borderTop: "1px solid #000",
                width: "100%"
            }}
        >


            {
                quotes.map((q, ix) => <div
                    key={q.fileId}
                    className="div-table-row-2"
                >

                    <div className="div-row"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div>File {q.fileId}</div>
                        <div><Moment date={q.clientQuoteDate} format="MMM DD YYYY" tz="America/New_York" /></div>
                    </div>
                    <div className="div-row-space-between">

                        <Moment locale="en" date={q.fileDate} format="MMM DD YYYY" tz="America/New_York" />
                        <Moment locale="en" date={q.quoteValidUntil} format="MMM DD YYYY" tz="America/New_York" />
                    </div>

                    <div>{q.rfqName}</div>

                    <div
                        style={q.fileStatusId === 4 ? styles.badge1 : styles.badge2}
                    >
                        {t(q.fileStatusName)}
                    </div>


                    <div className="div-row-space-between">
                        {
                            !!q.clientQuoteId ?
                                <Button
                                    variant="outline-dark"
                                    size="sm"
                                    onClick={() => {
                                        QuoteFetch({
                                            fileId: q.fileId,
                                            quoteId: q.clientQuoteId,

                                        });
                                        setShowModal(true);

                                    }}
                                >
                                    {t("Details")}
                                </Button>
                                :
                                <></>
                        }
                        {!!q.totalQuoted ?
                            <div style={{ textAlign: 'right' }}>{formatter(q.totalQuoted, "USD")}</div>
                            :
                            <></>
                        }
                    </div>


                    {!!q.client_response_accept_reject &&

                        <div

                            style={{
                                borderRadius: 5,
                                marginTop: 10,
                                color: '#fff',
                                width: '100%',
                                padding: 5,
                                paddingLeft: 10,
                                textAlign: "center",
                                backgroundColor: q.client_response_accept_reject === 1 ? "green" : "red"
                            }}
                        >
                            {q.client_response_accept_reject === 1 ? t("Accepted") : t("Rejected")}
                            &nbsp;
                            <Moment locale="en" date={q.client_response_dt} format="MMM DD YYYY" tz="America/New_York" />
                            <div>
                                {q.client_response_accept_reject === -1 && q.client_response_reject_reason}
                            </div>
                        </div>

                    }

                </div>)
            }
        </div></>
}


const ActivePOs = ({
    token
}) => {

    // eslint-disable-next-line
    const { t } = useTranslation();
    const [fetching, setFetching] = React.useState();
    const [purchaseOrder, setPurchaseOrder] = React.useState();
    const [showModal, setShowModal] = React.useState(false);

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CLIENT_INFO,
    } = dataReducer;


    let purchaseOrders = CLIENT_INFO.files.filter(f => f.fileHasPO && !f.fileFinished);


    const POFetch = async ({
        fileId,
        poId,

    }) => {
        if (fetching === 'file') return;
        setFetching("file");
        let data = await API.fileFetchUpdate({
            fileId,
            poId,
            token,
            method: 'GET'
        });
        setPurchaseOrder(data.data);
        setFetching(null);

    }


    if (!CLIENT_INFO || !CLIENT_INFO.files) return null;


    return <div
        style={{
            borderTop: "1px solid #000",
            width: "100%"
        }}
    >

        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            contentLabel="Invoice"
            backdrop="static"
            keyboard={false}

            size={isMobile ? "fullscreen" : "lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {!fetching && "PO " + purchaseOrder?.po?.clientPONumber}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div   >


                    {!!fetching ?
                        <div className="fetching-div">
                            <Circles
                                color="#FF9400"
                                height={80}
                            />
                        </div>
                        :
                        <div>
                            <div className="div-row-space-between">
                                <div>File {purchaseOrder?.po?.fileId} </div>

                                <div style={{
                                    textAlign: 'right',
                                }}>
                                    <div>PO <Moment date={purchaseOrder?.po?.clientPODate} format="MMM DD YYYY" tz="America/New_York" /></div>

                                    <div>ETA <Moment date={purchaseOrder?.po?.ETA} format="MMM DD YYYY" tz="America/New_York" /></div>
                                </div>
                            </div>


                            {
                                !!purchaseOrder?.po?.clientPOStatusDT
                                &&
                                (purchaseOrder?.po?.clientPOStatusDesc || "") !== ""
                                &&
                                <Alert variant='success'
                                    style={{
                                        maxWidth: 400
                                    }}
                                >
                                    <Moment date={purchaseOrder?.po?.clientPOStatusDT} format="MMM DD YYYY " tz="America/New_York" />
                                    <div>{purchaseOrder?.po?.clientPOStatusDesc}</div>
                                </Alert>
                            }



                            <div>PO {purchaseOrder?.po?.clientPONumber} </div>

                            <div>{purchaseOrder?.po?.rfqName}</div>



                            <div>
                                {purchaseOrder?.items?.map((q, ix) => <div
                                    key={ix}
                                    style={{
                                        padding: '5px 0'
                                    }}
                                >
                                    <div>{ix + 1}) {q.partnumber}</div>


                                    <div style={{
                                        fontSize: 13,
                                    }}>{q.description}</div>
                                </div>)}
                            </div>
                        </div>
                    }


                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>



        {
            purchaseOrders.sort((b, a) => new Date(b.clientPODeliveryDate) - new Date(a.clientPODeliveryDate)).map((q) => <div
                key={q.fileId}
                className="div-table-row-2"
            >
                <div className="div-row"
                    style={{ justifyContent: "space-between", borderBottom: "1px solid #e0e0e0" }}
                >
                    <div><b>PO {q.clientPONumber}</b></div>
                    <div
                        style={{ textAlign: 'right' }}
                    >
                        PO <Moment date={q?.clientPODate} format="MMM DD YYYY" tz="America/New_York" />
                        <br />
                        ETA <Moment date={q.clientPODeliveryDate} format="MMM DD YYYY" tz="America/New_York" /></div>
                </div>
                {!!q.invoiceId &&
                    <div className="div-row"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div><b>Invoice {q.invoiceId}</b></div>
                        <div><Moment date={q.invoiceDate} format="MMM DD YYYY" tz="America/New_York" /></div>
                    </div>
                }

                <div className="div-row"
                    style={{ justifyContent: "space-between" }}
                >
                    <div><b>File {q.fileId}</b></div>
                    <div><Moment date={q.fileDate} format="MMM DD YYYY" tz="America/New_York" /></div>
                </div>
                <div>{q.rfqName}</div>
                {!!q.clientPOStatusDesc &&
                    <Alert variant='success'
                        style={{
                            maxWidth: 400
                        }}
                    >

                        <div className='div-row-space-between'>
                            {t('followups')}
                            <Moment date={q.clientPOStatusDT} format="MMM DD YYYY" tz="America/New_York" />
                        </div>

                        <div style={{ fontWeight: 'bold' }} >
                            {q.clientPOStatusDesc}
                        </div>

                    </Alert>
                }
                <div className="div-row"
                >

                    <button className="btn-notify-me"
                        onClick={() => {
                            POFetch({
                                fileId: q.fileId,
                                poId: q.clientPOId,

                            });
                            setShowModal(true)
                        }}
                    >
                        {t("Details")}
                    </button>
                </div>



            </div>)
        }
    </div >
}



const FinishedPOs = ({
    token,
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [fetching, setFetching] = React.useState();
    const [selectedInvoice, setSelectedInvoice] = React.useState({});
    // eslint-disable-next-line
    const { t } = useTranslation();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CLIENT_INFO,
    } = dataReducer;

    let invoices = CLIENT_INFO.files.filter(f =>
        f.fileHasPO
        &&
        f.fileHasInvoice
    );
    invoices.sort((a, b) => a.invoiceId < b.invoiceId ? 1 : -1);

    const InvoiceFetch = async ({
        fileId,
        invoiceId,

    }) => {
        if (fetching === 'file') return;
        setFetching("file");
        let data = await API.fileFetchUpdate({
            fileId,
            invoiceId,
            token,
            method: 'GET'
        });
        console.log(data)
        if (data.data.error) {
            alert(data.data.msg)
        }
        setSelectedInvoice(data.data);
        setFetching(null);
    }

    if (!CLIENT_INFO || !CLIENT_INFO.files) return null;


    return <div
        style={{
            borderTop: "1px solid #000",
            width: "100%"
        }}
    >
        <Modal
            show={showModal}
            onHide={() => {
                setShowModal(false);
            }}
            contentLabel="Invoice"
            backdrop="static"
            keyboard={false}

            size={isMobile ? "fullscreen" : "lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {!fetching && "Invoice " + selectedInvoice?.invoice?.invoiceId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div
                >


                    {!!fetching ?
                        <div className="fetching-div">
                            <Circles
                                color="#FF9400"
                                height={80}
                            />
                        </div>
                        :
                        <div>
                            <div><Moment date={selectedInvoice?.invoice?.clientPODate} format="MMM DD YYYY" tz="America/New_York" /></div>

                            <div className="div-row-space-between" >
                                <div>
                                    <div >File {selectedInvoice?.invoice?.fileId}</div>
                                    <div >Invoice {selectedInvoice?.invoice?.invoiceId}</div>

                                </div>

                                <div style={{
                                    textAlign: 'right'
                                }}>
                                    <div>
                                        {formatter(selectedInvoice?.invoice?.totalItems, selectedInvoice?.invoice?.currency)}
                                    </div>
                                    <div>
                                        {formatter(selectedInvoice?.invoice?.totalOthersCosts, selectedInvoice?.invoice?.currency)}
                                    </div>
                                    <div>
                                        <b>{formatter(selectedInvoice?.invoice?.totalAmount, selectedInvoice?.invoice?.currency)}</b>
                                    </div>
                                </div>
                            </div>

                            <div >{selectedInvoice?.invoice?.rfqName}</div>
                            <div>
                                {selectedInvoice?.items?.map((q, ix) => <div
                                    key={ix}
                                    style={{
                                        padding: '5px 0'
                                    }}
                                >
                                    <div>{ix + 1}) {q.partnumber}</div>
                                    <div className="div-row-space-between" >
                                        <div>
                                            <span style={{ fontSize: 12 }}>{q.units} x {formatter(q?.unitCost, selectedInvoice?.invoice?.currency)}</span>
                                        </div>
                                        <div>
                                            {formatter(q?.totalCost, selectedInvoice?.invoice?.currency)}
                                        </div>

                                    </div>

                                    <div style={{
                                        fontSize: 13,
                                    }}>{q.description}</div>

                                </div>)}
                            </div>
                        </div>
                    }

                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>



        {invoices.map((q, ix) => <div
            key={q.fileId}
            className="div-table-row-2"
        >

            <div className="div-row"
                style={{ justifyContent: "space-between" }}
            >
                <div>PO {q.clientPONumber}</div>
                <div>
                    <Moment date={q.clientPODeliveryDate} format="MMM DD YYYY" tz="America/New_York" />
                </div>
            </div>
            <div className="div-row"
                style={{ justifyContent: "space-between" }}
            >
                <div>Invoice {q.invoiceId}</div>
                <div><Moment date={q.invoiceDate} format="MMM DD YYYY" tz="America/New_York" /></div>
            </div>

            <div className="div-row"
                style={{ justifyContent: "space-between" }}
            >
                <div>File {q.fileId}</div>
                <div><Moment date={q.fileDate} format="MMM DD YYYY" tz="America/New_York" /></div>
            </div>

            <div>{q.rfqName}</div>
            {!!q.finishStatusName
                ?
                <div style={styles.badge1}>{t(q.finishStatusName)}</div>
                :
                <div style={styles.badge2}>{q.clientPOStatusName}</div>
            }

            <div style={{ textAlign: 'right' }}>{formatter(q.totalInvoiced, 'USD')}</div>
            <button className="btn-details"
                onClick={() => {
                    InvoiceFetch({
                        fileId: q.fileId,
                        invoiceId: q.invoiceId,

                    });
                    setShowModal(true)
                }}
            >
                {t("Details")}
            </button>

        </div>)}
    </div>
}

const files = {
    ActivePOs,
    FinishedPOs,
    Quotes,
}
export default files;


const styles = {
    badge1: {
        color: 'green'
    },
    badge2: {
        color: '#000'
    }

}


