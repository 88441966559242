import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/HeaderWH';

import Moment from 'react-moment';
import 'moment-timezone';

import { IoCameraOutline, IoWarningOutline, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { Circles } from "react-loader-spinner";

import WHAPI from "../api/WHAPI";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width: width,
        height
    };
}
const InventoryItems = ({
    inventory,
    fnSearch
}) => {

    const navigate = useNavigate();
    /* eslint-disable */
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    React.useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /* eslint-enable */

    const SearchBar = ({
        fnSearch
    }) => {

        const [fileId, setFileNumber] = React.useState("");
        const [vendorPONumber, setVendorPONumber] = React.useState("");
        const [search, setSearch] = React.useState("");

        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                maxWidth: 600,
                minWidth: 350,
                alignItems: 'flex-end',
                padding: 5,
            }}
        >

            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 10
                }}
            >
                File#
                <input
                    style={{
                        width: 60,
                        fontSize: 17
                    }}
                    value={fileId || ""}
                    onChange={e => {
                        let _v = e.target.value.replace(/\D/g, '');
                        setFileNumber(_v)
                    }}
                    type="number"
                />
            </label>

            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 10
                }}
            >
                Vendor PO
                <input
                    style={{
                        width: 60,
                        fontSize: 17
                    }}
                    value={vendorPONumber || ""}
                    onChange={e => setVendorPONumber(e.target.value)}
                    type="number"
                />
            </label>
            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                Search
                <input
                    style={{
                        width: 120,
                        fontSize: 17
                    }}
                    value={search || ""}
                    onChange={e => setSearch(e.target.value)}

                />
            </label>

            <Styles.ButtonCircular
                onClick={() => fnSearch({
                    vendorPONumber,
                    fileId,
                    search
                })}
                style={{
                    marginTop: 10
                }}
            >
                <IoSearch />
            </Styles.ButtonCircular>

        </div>
    }


    return <div
        style={{
            width: windowDimensions.width,
            maxWidth: 600,
            minWidth: 350,
        }}

    >
        <SearchBar
            fnSearch={fnSearch}
        />
        {inventory.whr.map((i, ix) => {
            let showHeader = true;
            if (ix > 0) {
                showHeader = (i.fileId !== inventory.whr[ix - 1].fileId)
            }

            return <div
                key={ix}
                style={{
                    padding: 5,
                    borderTopWidth: showHeader ? 1 : 0,
                    borderTopColor: '#000',
                    borderTopStyle: 'solid'
                }}
            >
                {showHeader &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <div
                            style={{
                                marginTop: 5,
                                marginBottom: 5
                            }}

                        >
                            <div>
                                <span style={{ fontWeight: 'bold' }}>{i.fileId} {inventory.clients[i.clientId]}</span>

                            </div>
                            <div style={{ marginTop: 5 }}>
                                <span style={{ fontWeight: 'bold' }}>{i.vendorPONumber}</span> <span>{inventory.vendors[i.vendorId]}</span>
                            </div>
                            {i.filePhotoCount > 0 ? <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                fontSize: 18,
                                color: 'blue',
                                marginTop: 5,
                            }}>
                                {i.filePhotoCount}
                                <IoCameraOutline />
                            </div>
                                :
                                <IoCameraOutline color={"red"} size={18} style={{ marginTop: 5 }} />
                            }

                        </div>
                    </div>
                }
                <div style={{
                    paddingTop: 5,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    display: 'flex'
                }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div style={{ color: 'blue', textDecoration: 'underline' }}
                            onClick={() => navigate(`/admin/wh/file/${i.fileId}?vendorPOId=${i.vendorPOId}`)}

                        >W/R {i.fileId}{i.id}</div>

                        <Moment date={i.receivedDateTime} format="MMM-DD HH:mm" tz="America/New_York" />
                        {i.released === 1 ?
                            <span>Released</span>
                            :
                            i.releaseAuthorized === 1 &&
                            <span style={{ padding: 5, borderRadius: 5, backgroundColor: 'green', color: '#fff' }}>Release Authorized</span>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <span style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            color: i.unitsInWRIdentified === 0 ? 'red' : 'green'
                        }}>
                            {i.desc}
                            {i.unitsInWRIdentified === 0 &&
                                <IoWarningOutline color={"red"} size={18} />
                            }
                        </span>
                        {i.unitsInWRIdentified !== 0 &&
                            <span style={{ color: 'green' }} >{i.unitsInWRIdentified} items</span>
                        }
                    </div>
                </div>
            </div>
        }
        )}


    </div>
}

const WHInventory = () => {
    const [fetching, setFetching] = React.useState(false);
    const [inventory, setInventory] = React.useState({ success: false, whr: [] });


    const DataFetch = async () => {
        setFetching(true);
        WHAPI.inventoryFetch({
            vendorPONumber: null,
            fileId: null,
            search: null,
            released: true
        }).then(res => {
            setFetching(false)

            if (res.success) {
                setInventory(res)
            } else {
                alert(res.msg)
            }
        });
    }
    const fnSearch = (args) => {
        console.warn("search", args)
    }
    /* eslint-disable */
    React.useEffect(() => {
        DataFetch()
    }, []);
    /* eslint-enable */



    return <div>

        {fetching ?
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    padding: 20
                }}
            >
                <Circles
                    color="#FF9400"
                    height={50}
                />
            </div>
            :
            <div>

                <InventoryItems
                    inventory={inventory}
                    fnSearch={fnSearch}
                />
            </div>
        }


    </div>

}


const AdminMain = () => {

    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            maxWidth: 600
        }}>


            <Styles.H1 >Warehouse Released</Styles.H1>




            <WHInventory />

        </div>
    </div>
    );
}

export default AdminMain;