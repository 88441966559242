import React from "react";
import Styles from "../../styles/";
import Header from './Header';

import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import ClientContactEdit from "../../app/components/ClientContactEdit";

import { IoLogoWhatsapp, IoNotifications, } from "react-icons/io5";
import Modal from 'react-modal';

import { NavBarClients } from './Components/NavBarClients';
import Container from 'react-bootstrap/Container';
import Moment from 'react-moment';
import 'moment-timezone';

const Contacts = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();

    const [search, setSearch] = React.useState("");
    const [CONTACTS, setContacts] = React.useState([]);
    const [contactListStatus, setContactListStatus] = React.useState("active");

    const {
        notificationContacts,
        fetching
    } = dataReducer;

    let {
        contacts,
        fileNotifications
    } = notificationContacts || {};

    const fnListFilter = () => {
        if (contacts) {
            setContacts(contacts.filter(c => {
                return (c.firstName.toUpperCase() + " " + c.lastName.toUpperCase()).indexOf(search.toUpperCase()) !== -1

                    &&
                    (
                        contactListStatus === 'all' ?
                            true
                            :
                            contactListStatus === 'active' ? c.active : !c.active
                    )
            }

            ));
        }
    }
    /* eslint-disable */

    React.useEffect(() => {
        fnListFilter();

    }, [search, contactListStatus]);
    React.useEffect(() => {
        fnListFilter();
    }, []);
    React.useEffect(() => {
        if (fetching === 'contact_updated' || fetching === 'contact_deleted') {
            dispatch({
                type: 'FETCHING',
                fetching: null
            });
            dispatch({
                type: "NOTIFICATIONS_CLIENTS_FETCH",
                clientId: notificationContacts?.company?.clientId
            });
        }
    }, [fetching]);
    /* eslint-enable */
    const [contactEdit, setContactEdit] = React.useState(null);

    const ContactFileNotifications = (fileNotifications) => {

        if (fileNotifications.fileNotifications.length === 0) return <div

        >

        </div>;
        return <div
            style={{ color: 'green ' }}
        >
            {fileNotifications.fileNotifications.map((f, ix) => <div
                key={ix}
            >
                <a style={{ color: 'blue' }} href={`/admin/wh/file/${f.fileId}?sc=client&s=client`}>{f.fileId}</a>
            </div>)}
        </div>
    }

    if (!notificationContacts) return null;

    return <div>
        {contactEdit !== null &&
            <Modal
                isOpen={true}
                contentLabel="Example Modal"
                style={customStyles}
            >

                <ClientContactEdit
                    fileId={0}
                    clientId={notificationContacts?.company?.clientId}
                    fnClose={() => setContactEdit(null)}
                    contactEdit={contactEdit}
                />
            </Modal>
        }

        <input
            style={{
                width: 200,
                fontSize: 17,
                marginRight: 10
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={"Search"}
        />
        <button
            style={{
                margin: 5,
                marginLeft: 0,
            }}
            onClick={() => {
                setContactEdit({
                    active: 1
                });
            }}
        >Add Contact</button>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5,
            marginBottom: 5,
        }}>
            <Styles.ButtonTab
                active={contactListStatus === 'active'}
                onClick={() => setContactListStatus('active')}
            >
                Active
            </Styles.ButtonTab>
            <Styles.ButtonTab
                active={contactListStatus === 'inactive'}
                onClick={() => setContactListStatus('inactive')}
            >
                Inactive
            </Styles.ButtonTab>
            <Styles.ButtonTab
                active={contactListStatus === 'all'}
                onClick={() => setContactListStatus('all')}
            >
                All
            </Styles.ButtonTab>
        </div>
        <div
            style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#000',
                borderBottomStyle: 'none',
                width: '100%',
            }}
        >
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#000',
                    padding: 5
                }}
            >
                <div style={{
                    flex: 8,
                    color: '#fff',
                    textAlign: 'center',
                    fontSize: 19
                }}>
                    Contact
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    color: '#fff',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 50
                }}>
                    <IoNotifications size={25} />
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    color: '#fff',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 50
                }}>
                    <IoLogoWhatsapp size={25} />
                </div>

            </div>


            {CONTACTS.map((c, ix) => {

                return <div
                    key={ix}
                    style={{
                        backgroundColor: ix % 2 ? '#c0c0c0' : '#fff',
                        padding: 5,
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#000'
                    }}
                >


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div style={{
                            flex: 8
                        }}>
                            <button
                                onClick={() => setContactEdit(c)}
                                style={{
                                    color: c.active ? 'blue' : 'red',
                                    borderStyle: 'none',
                                    backgroundColor: 'transparent',
                                    padding: 0,
                                    width: '100%',
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    textDecoration: c.active ? 'underline' : 'line-through',

                                }}
                            ><b>{c.lastName}</b>, {c.firstName}
                            </button>
                            <div><i>{c.contactType}</i></div>
                            <div>
                                {c.phone}
                                <br />
                                {c.email}</div>
                        </div>

                        <div style={{
                            flex: 1,
                            width: 50,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <ContactFileNotifications
                                fileNotifications={fileNotifications.filter(f => f.clientContactId === c.clientContactId)}
                            />
                        </div>

                        <div style={{
                            flex: 1,

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {c.whatsAppAccess === 1 &&
                                <div style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'green'
                                }}>
                                    <IoLogoWhatsapp size={19} style={{ color: '#fff' }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>

            })

            }

        </div>
    </div>
}

const ClientPOS = () => {

    const dataReducer = useSelector((state) => state.dataReducer);

    const {
        notificationContacts
    } = dataReducer;

    if (!notificationContacts) return null;
    return <div>

        <div>
            <b>Client Purchase Orders</b>
        </div>


        {notificationContacts.pos.map((po, ix) => <div
            style={{
                padding: 5,
            }}
        >


            <div
                key={ix}
                style={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'row',


                }}
            >
                <div
                    style={{
                        width: 90
                    }}
                >
                    {po.fileId}
                </div>
                <div style={{ width: 150 }}><Moment date={po.poDT} format="MMM DD 'YY" tz="America/New_York" /></div>

                <a target="_blank" rel="noreferrer" alt={`File ${[po.fileId]}`} href={`/admin/wh/file/${po.fileId}?sc=pos&s=client`}>{po.poNumber}</a>

            </div>
        </div>)
        }

        <div
            style={{
                marginTop: 20
            }}
        >
            <b>Vendor Purchase Orders</b>
        </div>

        {notificationContacts.vendorPOs.map((po, ix) => <div>


            <div
                key={ix}
                style={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'row',

                }}>

                <div
                    style={{
                        width: 90
                    }}
                >
                    {po.fileId}
                </div>

                {po.receivedDT === null ?
                    <div style={{ width: 150 }}>
                        ETA<br />
                        <Moment date={po.etaDT} format="MMM DD 'YY" tz="America/New_York" />
                    </div>
                    :
                    <div style={{ width: 150, color: 'green' }}>
                        Delivered<br />
                        <Moment date={po.receivedDT} format="MMM DD 'YY" tz="America/New_York" />
                    </div>
                }


                <a target="_blank" rel="noreferrer" alt={`File ${[po.fileId]}`} href={`/admin/wh/file/${po.fileId}?s=vendor&sc=pos`}>{po.poNumber}</a>

            </div>
        </div>)
        }

    </div>
}

function NotificationsContacts() {

    let clientId = useParams().clientId || "";
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();
    const {
        fetching,
        notificationContacts
    } = dataReducer;

    const [section, setSection] = React.useState('contacts')

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "NOTIFICATIONS_CLIENTS_FETCH",
            clientId
        });
    }, []);
    /* eslint-enable */
    return (<>
        <HelmetProvider>
            <Helmet>
                <title>{notificationContacts ? notificationContacts.company.name : ""} Contacts</title>
            </Helmet>
        </HelmetProvider>
        <Header />


        <Container
            style={{
                marginTop: 60,
                maxWidth: 650,
                padding: 0
            }}
        >
            <div style={{
                padding: 10
            }}>
                <NavBarClients />
            </div>

            {fetching === "client_notifications" ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={50}
                    />
                </div>
                :
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <div style={{ marginLeft: 10 }}>
                        <b>{notificationContacts?.company?.name}</b>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        borderBottomColor: '#000',
                        marginBottom: 10,
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 5,
                        }}>
                            <Styles.ButtonTab
                                onClick={() => setSection("contacts")}
                                active={section === "contacts"}
                            >
                                Contacts
                            </Styles.ButtonTab>
                            <Styles.ButtonTab
                                onClick={() => setSection("pos")}
                                active={section === "pos"}
                            >
                                Active Files
                            </Styles.ButtonTab>
                        </div>
                    </div>

                    {section === 'pos' && <div
                        style={{
                            padding: 5
                        }}
                    >
                        <ClientPOS />
                    </div>}
                    {section === 'contacts' && <Contacts />}


                </div>

            }


        </Container>

    </>
    );
}

export default NotificationsContacts;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};