const token = localStorage.getItem("token") || "";


const clientsAPI = {


    clientNotificationsFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_notifications_contacts_fetch
        return new Promise((resolve, reject) => {

            let url = ` https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/notifications/clients/`;

            if (args.clientId) {
                url = ` https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/notifications/clients/${args.clientId}/contacts`;

            }

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    contactDelete: (args) => {

        return new Promise((resolve, reject) => {


            let {
                clientId,
                clientContactId
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/client/${clientId}/contact/${clientContactId}`;
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'DELETE',
                //body: JSON.stringify(args.clientContact)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    contactUpdate: (args) => {

        return new Promise((resolve, reject) => {
            //arn:aws:lambda:us-east-1:645160913840:function:PRK_ClientContactUpdate
            //CALL client_contact_update

            let {
                clientId,
                clientContactId
            } = args.clientContact;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/client/${clientId}/contact/${clientContactId}`;
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            console.log(JSON.stringify(args.clientContact))

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args.clientContact)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


}

export default clientsAPI           