import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import WHAPI from "../api/WHAPI";
import { Circles } from "react-loader-spinner";
import ImageUploading from 'react-images-uploading';
import { useParams, useSearchParams } from "react-router-dom";
import { IoCloudUploadSharp } from "react-icons/io5";

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';


import { useDispatch, useSelector } from 'react-redux';


function resizeBase64Img(base64Str) {
    return new Promise((resolve, reject) => {
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 800;
        var img = new Image();
        img.src = base64Str;
        img.onload = () => {
            var canvas = document.createElement('canvas');

            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            resolve({
                data_url: canvas.toDataURL(),
                w: width,
                h: height
            });
        }
    });
}
const WRPhotos = ({
    vendorPOId,
    photos,
    fileId,
    wrId,
    wrNumber,
    wh,
    fnRefresh
}) => {
    const dispatch = useDispatch();
    const [images, setImages] = React.useState(photos);
    const [isPackingList, setIsPackingList] = React.useState(false);
    const maxNumber = 2;


    const onChange = (
        imageList,
        addUpdateIndex
    ) => {

        let randomid = Math.floor(Math.random() * 100000);


        dispatch({
            type: 'FETCHING',
            fetching: "uploading_photo"
        });

        if (addUpdateIndex !== undefined) {    // not deleting
            imageList[addUpdateIndex].photoId = randomid;
            resizeBase64Img(imageList[addUpdateIndex].data_url).then(res => {
                imageList[addUpdateIndex].data_url = res.data_url;

                WHAPI.wrPhotoUpload({
                    data: imageList[addUpdateIndex],
                    vendorPOId,
                    photoId: randomid,
                    wrId,
                    isPackingList
                }).then(r => {
                    dispatch({
                        type: 'FETCHING',
                        fetching: false
                    });
                    fnRefresh();
                })
            });

        }

    };

    const PhotoDelete = ({ id }) => {

        WHAPI.wrPhotoDelete({
            id,
            vendorPOId,
        }).then(r => {
            console.warn("Deleted !!")
        })
    }




    return (
        <div>

            <ImageUploading
                multiple={false}
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    <div style={{
                        margin: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 300,
                    }}>

                        <div>
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }}><a href={`/admin/wh/file/${fileId}/wr/${wrId}`}>W/R {wrNumber}</a></div>
                            <div style={{ textAlign: 'center' }}>{wh.packages} {wh.packageType}</div>
                            <div style={{ textAlign: 'center' }}>{wh.remarks}</div>
                        </div>


                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Styles.ButtonSecondary
                                style={isDragging ? { color: 'red' } : undefined}
                                onClick={() => {
                                    setIsPackingList(false);
                                    onImageUpload()
                                }}
                                {...dragProps}
                            >
                                <IoCloudUploadSharp />
                                &nbsp;
                                Photo
                            </Styles.ButtonSecondary>

                            <Styles.ButtonMain
                                //style={isDragging ? { color: 'red' } : undefined}
                                style={{
                                    marginLeft: 10
                                }}
                                onClick={() => {
                                    setIsPackingList(true);
                                    onImageUpload()
                                }}
                                {...dragProps}
                            >
                                <IoCloudUploadSharp />
                                &nbsp;
                                Packing List
                            </Styles.ButtonMain>
                        </div>
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item" style={{ marginTop: 10, marginBottom: 20 }}>
                                <div style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    {image.isPackingList === 1 &&
                                        <div style={{
                                            backgroundColor: '#FF9400',
                                            color: '#fff',
                                            padding: 5,
                                            paddingRight: 10,
                                            paddingLeft: 10,
                                            borderColor: '#000',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderBottomStyle: 'none',
                                            fontWeight: 'bold'
                                        }}>
                                            PACKING LIST
                                        </div>}
                                    <Zoom>
                                        <img src={image['data_url']} alt="" style={{
                                            borderWidth: 1,
                                            borderColor: '#000',
                                            borderStyle: 'solid',
                                            minWidth: 200,
                                            maxWidth: 350
                                        }} />
                                    </Zoom>

                                </div>

                                <div style={{ marginTop: 3 }}>
                                    <button onClick={() => {
                                        ///console.log("eliminar => ", images[index])
                                        if (window.confirm("Are you sure you wish to delete this file?")) {
                                            PhotoDelete({
                                                id: images[index].id
                                            });
                                            let _images = [...images];
                                            _images.splice(index, 1);
                                            setImages(_images);
                                        }

                                    }}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>



        </div>
    );
}

function WHRPhotos() {

    let vendorPOId = useParams().vendorPOId;
    const [fetching2, setFetching2] = React.useState(false);
    const [vendorPO, setVendorPO] = React.useState({});

    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    let wrId = searchParams.get("wr");

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;

    console.warn("fetching", fetching)

    const vendorPOFetch = async () => {
        setFetching2(true);
        setVendorPO({});
        WHAPI.vendorPOFetch({
            vendorPOId
        }).then(res => {
            setFetching2(false)

            if (res.success) {
                setVendorPO(res)
            } else {
                alert(res.msg)
            }
        });
    }
    /* eslint-disable */
    React.useEffect(() => {
        vendorPOFetch()
    }, []);
    /* eslint-enable */

    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>


            {(fetching2 || fetching === 'uploading_photo') ? <Circles
                color="#FF9400"
                height={30}
                width={150}
                timeout={5000}
            />

                :
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <a href={`/admin/wh/file/${vendorPO?.vendorPO?.fileId}`}>File {vendorPO?.vendorPO?.fileId}</a> | <a href={`/admin/wh/vendorPO/${vendorPO?.vendorPO?.vendorPOId}/receiveV2/`} >Vendor PO {vendorPO?.vendorPO?.vendorPONumber}</a>
                    </div>
                    {(vendorPO.vendorPO) && <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 10
                    }}>
                        <span style={{ fontWeight: 'bold' }}>{vendorPO.vendorPO.vendorName}</span>

                        <div style={{
                            marginTop: 10
                        }}>
                            {vendorPO.whReceipts.map((wh, ix) => {
                                if (wrId && parseInt(wrId) !== wh.id) return null;
                                return <div
                                    key={ix}
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderColor: '#c0c0c0',
                                        padding: 5,
                                        marginBottom: 10,
                                        backgroundColor: '#e0e0e0'
                                    }}
                                >

                                    <WRPhotos
                                        fnRefresh={() => vendorPOFetch()}
                                        wh={wh}
                                        vendorPOId={vendorPOId}
                                        fileId={vendorPO.vendorPO.fileId}
                                        wrNumber={vendorPO.vendorPO.fileId + '' + wh.id}
                                        wrId={wh.id}
                                        photos={vendorPO.photos.filter(p => p.wrId === wh.id)}
                                    />
                                </div>
                            }
                            )}
                        </div>
                    </div>
                    }



                </div>
            }



        </div>
    </div >
    );
}

export default WHRPhotos;