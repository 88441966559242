import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Circles } from "react-loader-spinner";
const QRReader = () => {

    const navigate = useNavigate();
    const { wrId, fileId } = useParams();

    const [showWR, setShowWR] = React.useState(false)
    /* eslint-disable */
    React.useEffect(() => {
        if (!localStorage.getItem("token") || localStorage.getItem('token') !== "undefined") {
            navigate(`/admin/wh/file/${fileId}/wr/${wrId}`)
        } else {
            setShowWR(true)
        }
    }, []);
    /* eslint-enable */



    return <div>
        {showWR ?
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    padding: 20,

                }}
            >
                <h1>WR {fileId}{wrId}</h1>

                <div
                    style={{
                        textAlign: 'center',
                        fontSize: 20
                    }}
                >
                    <b>Prodek Inc</b>
                    <br/>
                    10900 NW 21st suite 190
                    <br/>
                    Miami, FL. 33172 USA
                    <br/>
                    <br/>
                    <a href="tel:13055944488">+1(305)594-4488</a>
                </div>

            </div>
            :
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    padding: 20,

                }}
            >
                <Circles
                    color="#FF9400"
                    height={150}
                />




            </div>
        }

    </div>
}

export default QRReader;