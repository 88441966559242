import React from "react";  
import Styles from "../../styles"; 
 

import {IoLogoWhatsapp ,IoNotifications } from "react-icons/io5";
import {  ThreeDots } from "react-loader-spinner";

import { useDispatch, useSelector } from 'react-redux';



const ClientContactEdit = ({
    fileId,
    clientId,
    contactEdit,
    fnClose
}) => {

 

    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch(); 
    const {
        fetching, 
        file,
        recordId
    } = dataReducer;

    const [firstName, setFirstName] = React.useState(contactEdit.firstName || "");
    const [lastName, setLastName] = React.useState(contactEdit.lastName || "");
    const [email, setEmail] = React.useState(contactEdit.email || "");
    const [phone, setPhone] = React.useState(contactEdit.phone || "");
    const [notify, setNotify] = React.useState( (fileId !== 0) &&  file.whatsapp_notify[contactEdit.id] ? 1 : 0);
    const [whatsAppAccess, setWhatsAppAccess] = React.useState(contactEdit.whatsAppAccess || 0);
    const [active, setActive] = React.useState(contactEdit.active || 0);
    const [contactType, setContactType] = React.useState(contactEdit.contactType || "");

    const [changesNotSaved, setChangesNotSaved] = React.useState(false);

    const [clientContactId , setClientContactId ] = React.useState(contactEdit.id || 0)
    /* eslint-disable */ 
    React.useEffect(() => { 
       setChangesNotSaved(true); 
    }, [firstName, lastName, email, phone, notify, whatsAppAccess, active, contactType ]);
    React.useEffect(() => { 
        setChangesNotSaved(false); 
     }, []);
   React.useEffect(() => { 
        if (fetching === 'contact_deleted'){
            dispatch({
                type: 'FETCHING',
                fetching : null
            });
            fnClose();
        }
        if (fetching === 'contact_updated'){
            dispatch({
                type: 'FETCHING',
                fetching : null
            });
            setClientContactId(recordId);
        }
        setChangesNotSaved(false);
    }, [fetching]);
    /* eslint-enable */

    const fnSave = () => {
        if (
            firstName.trim() === "" 
            || 
            lastName.trim() === "" 
            ||
            email.trim() === ""
            //||  phone.trim() === ""
        ){
            return alert("All fields are required")
        }

        
        let clientContact = {
            firstName,
            lastName,
            email,
            phone,
            notify : notify ? 1 : 0,
            active : active ? 1 : 0,
            whatsAppAccess: whatsAppAccess ? 1 : 0,
            fileId,
            clientId,
            clientContactId,
            contactType
        }  

      
        setChangesNotSaved(false); 
        dispatch({
            type: 'CLIENT_CONTACT_UPDATE',
            clientContact 
        });
        
    }

    const fnDelete = () => {
        if (!window.confirm("Are you sure you wish to delete this contact?")){
            return;
        }
        dispatch({
            type: 'CLIENT_CONTACT_DELETE',
            clientContactId:  contactEdit.id,
            clientId
        });
    }

    return <div>
      
        <div  />
        <div
            style={{
                position: 'relative', 
                width: 350,  
                backgroundColor: '#e0e0e0', 
                borderRadius:  5,
                padding: 10,
                borderStyle: 'solid', 
                borderWidth: 1, 
                borderColor: '#c0c0c0',  
                margin: 'auto',
                //marginTop: -280
            }}
        >
            
            <button
                style={{
                    position: 'absolute', 
                    right: 5, top: 5
                }}
                onClick={() => {
                    if (changesNotSaved){
                        if(!window.confirm("Are you sure you wish to abandon changes?")){
                            return;
                        }
                    } 
                    fnClose();

                }}
            >
                {fetching === "client_contact_updating" ?
                    <ThreeDots
                    height={10}
                    />
                :
                    "Close"
                }
            </button>

            <button
                onClick={() =>  setActive(!active) }
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor:  active ? 'green' : "red",
                    color:    '#fff'  ,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: 100
                }}
            > 
                { active ? 'Enabled' : "Disabled"}
                    
            </button>
            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column', 
                }}
            >
                First Name
                <input
                    style={{
                        width: 200,
                        fontSize: 17
                    }}
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    
                />
            </label>
            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column', 
                }}
            >
                Last Name
                <input
                    style={{
                        width: 200,
                        fontSize: 17
                    }}
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    
                />
            </label>
            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column', 
                }}
            >
                Mobile Phone
                <input
                    style={{
                        width: 200,
                        fontSize: 17
                    }}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    
                />
            </label>
            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column', 
                }}
            >
            Email
                <input
                    style={{
                        width: 300,
                        fontSize: 17
                    }}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    
                />
            </label> 

            <label
            style={{
                display: 'flex',
                flexDirection: 'column', 
            }}
            >
                Type
                <select
                    onChange={(e) => {
                        setContactType(e.target.value)
                    }}
                    value={contactType}
                    style={{
                        width: 150,
                        marginTop: 5,
                        fontSize: 17
                    }}
                > 
                <option 
                    value={""}
                >--Select--</option>
                    <option 
                        value={"Purchasing"}
                    >Purchasing</option>
                    <option 
                    value={"Logistics"}
                    >Logistics</option>
                    <option 
                    value={"Operations"}
                    >Operations</option>
                    <option 
                    value={"Accounting"}
                    >Accounting</option>
                </select>
            </label>

            <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
            >

                <button
                    onClick={() =>  setWhatsAppAccess(!whatsAppAccess) }
                    style={{
                        marginTop: 10,
                        backgroundColor:  whatsAppAccess ? 'green' : "#c0c0c0",
                        color:  whatsAppAccess ? '#fff' : "#000000",
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        width: 150,
                        height: 30
                    }}
                >
                        <IoLogoWhatsapp 
                            color={ whatsAppAccess ? '#fff' : "#000000"}
                        />
                        { whatsAppAccess ? 'Allow Access' : "No Access"}
                        
                </button>
                
                { !!whatsAppAccess 
                    &&
                    fileId !== 0
                    
                    &&
                    <button
                        onClick={() =>  setNotify(!notify) }
                        style={{
                            marginTop: 10,
                            backgroundColor:  notify ? 'green' : "#c0c0c0",
                            color:  notify ? '#fff' : "#000000",
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: 160
                        }}
                    >
                        <IoNotifications 
                            color={ notify ? '#fff' : "#000000"}
                        />
                        { notify ? `File ${file.file.fileId} Notifications` : "No File Notifications"}
                        
                    </button>
                }
                


                
            </div>

            <hr/>
            <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
            >
                <Styles.ButtonMain
                    onClick={() => fnSave()}
                    disabled={!changesNotSaved || fetching === "client_contact_updating"}
                    style={{
                        backgroundColor: !changesNotSaved ? '#c0c0c0' : '#FF9400',
                        width: 200
                    }}
                    
                >
                    {fetching === "client_contact_updating" ?
                        <ThreeDots
                           height={10}
                        />
                    :
                        "Save"
                    }
                </Styles.ButtonMain>
                <Styles.ButtonDelete
                    onClick={fnDelete}
                    disabled={fetching === "client_contact_updating"}
                >
                {fetching === "client_contact_updating" ?
                        <ThreeDots
                            height={10}
                            color="red"
                        />
                    :
                        "Delete"
                    }
                </Styles.ButtonDelete>
            </div>
            <span style={{ fontSize: 10, color: '#808080'}}>{clientContactId}</span>
        </div>
    </div>
}


export default ClientContactEdit;