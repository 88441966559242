import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/HeaderWH';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import Moment from 'react-moment';
import 'moment-timezone';

import { useNavigate, Link } from "react-router-dom";
import { IoCameraOutline, IoWarningOutline } from "react-icons/io5";


import { Circles } from "react-loader-spinner";

import WHAPI from "../api/WHAPI";


const WHInventory = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = React.useState(false);
    const [inventory, setInventory] = React.useState({ success: false, whr: [] });
    const [fileId, setFileNumber] = React.useState("");
    const [vendorPONumber, setVendorPONumber] = React.useState("");
    const [search, setSearch] = React.useState("");


    const DataFetch = async ({
        vendorPONumber,
        fileId,
        search
    }) => {
        setFetching(true);


        WHAPI.inventoryFetch({
            vendorPONumber,
            fileId,
            search

        }).then(res => {
            setFetching(false)
            if (res.success) {
                setInventory(res)
            } else {
                if (res.authenticationFailed) {
                    navigate("/?unauthorized=1")
                }

                setInventory(res)
            }
        });
    }
    const fnSearch = (args) => {
        DataFetch(args);

    }
    /* eslint-disable */
    React.useEffect(() => {
        DataFetch({})
    }, []);
    /* eslint-enable */



    return <div>

        {fetching ?
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    padding: 20,
                }}
            >
                <Circles
                    color="#FF9400"
                    height={50}
                />
            </div>
            :
            <div>



                <div
                    style={{
                        maxWidth: 650,
                        margin: 'auto',

                        marginBottom: 200,
                    }}

                >

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            padding: 5,
                            width: 300,
                            alignItems: 'flex-end',
                            margin: 'auto'
                        }}
                    >

                        <div

                        >
                            File#
                            <input
                                style={{
                                    fontSize: 17,
                                    width: 80
                                }}
                                value={fileId || ""}
                                onChange={e => {
                                    let _v = e.target.value.replace(/\D/g, '');
                                    setFileNumber(_v)
                                }}
                                type="number"
                            />
                        </div>

                        <label
                        >
                            Vendor PO
                            <input

                                style={{
                                    fontSize: 17,
                                    width: 80,
                                    marginLeft: 5,
                                    marginRight: 5
                                }}
                                value={vendorPONumber || ""}
                                onChange={e => setVendorPONumber(e.target.value)}
                                type="number"
                            />
                        </label>
                        <label
                        >
                            Search
                            <input
                                style={{
                                    fontSize: 17,
                                    width: 80,
                                }}
                                value={search || ""}
                                onChange={e => setSearch(e.target.value)}

                            />
                        </label>

                        <button
                            width={60}
                            onClick={() => fnSearch({
                                vendorPONumber,
                                fileId,
                                search
                            })}
                            style={{

                                marginLeft: 5
                            }}
                        >
                            Search
                        </button>

                    </div>


                    {inventory.whr.map((i, ix) => {
                        let showHeader = true;
                        if (ix > 0) {
                            showHeader = (i.fileId !== inventory.whr[ix - 1].fileId)
                        }

                        return <div
                            key={ix}
                        >
                            {showHeader &&
                                <div
                                    style={{
                                        padding: 5,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flex: 1,
                                        backgroundColor: '#00000030',

                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 5,
                                            flex: 1
                                        }}

                                    >
                                        <div>
                                            <Link to={`/admin/wh/file/${i.fileId}`} style={{ color: 'blue' }}>
                                                <span style={{ fontWeight: 'bold' }}>File {i.fileId}</span>
                                            </Link>
                                            <br />
                                            <div>{inventory.clients[i.clientId]}</div>
                                        </div>
                                        <div>
                                            <span>{i.vendorPONumber}</span> <span>{inventory.vendors[i.vendorId]}</span>
                                        </div>
                                        {i.filePhotoCount > 0 ? <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            fontSize: 18,
                                            color: 'blue',
                                            marginTop: 5,
                                        }}>
                                            {i.filePhotoCount}
                                            <IoCameraOutline />
                                        </div>
                                            :
                                            <IoCameraOutline color={"red"} size={18} style={{ marginTop: 5 }} />
                                        }



                                        {inventory?.wrReleasedCount[i.fileId]?.released > 0 &&
                                            <div
                                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                                            >
                                                <div style={{
                                                    width: 120,
                                                    padding: 5,
                                                    textAlign: 'center',
                                                    backgroundColor: '#000',
                                                    color: '#fff',
                                                    borderRadius: 5,
                                                    fontSize: 13
                                                }}>
                                                    {inventory.wrReleasedCount[i.fileId].released} RELEASED W/R
                                                </div>
                                            </div>
                                        }


                                    </div>

                                </div>
                            }
                            <div style={{
                                padding: 5,
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                display: 'flex',
                                paddingLeft: 20
                            }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >

                                    <Link style={{ color: 'blue' }} to={`/admin/wh/file/${i.fileId}/wr/${i.id}`} >W/R {i.fileId}{i.id}</Link>

                                    <span style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        color: i.unitsInWRIdentified === 0 ? 'red' : 'green'
                                    }}>
                                        {i.unitsInWRIdentified === 0 &&
                                            <IoWarningOutline color={"red"} size={18} />
                                        }
                                    </span>
                                    {i.unitsInWRIdentified !== 0 &&
                                        <span style={{ color: 'green' }} >{i.unitsInWRIdentified} items</span>
                                    }


                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {i.whName}
                                    <Moment date={i.receivedDateTime} format="MMM-DD HH:mm" tz="America/New_York" />
                                    {i.released === 1 ?
                                        <span>Released</span>
                                        :
                                        i.releaseAuthorized === 1 &&
                                        <span style={{ padding: 5, borderRadius: 5, backgroundColor: 'green', fontSize: 13, color: '#fff', textAlign: 'center' }}>Authorized</span>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    )}


                </div>


            </div>
        }


    </div>

}


const AdminMain = () => {

    const navigate = useNavigate();


    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Warehouse</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>
            <div>
                <Link to="/admin/wh/inTransit">In Transit</Link>
                |
                <Link to="/admin/wh/release">For Release</Link>

            </div>


            <button
                onClick={() => navigate("/admin/wh/receive")}
                style={{
                    marginTop: 10
                }}
            >
                Receive W/R
            </button>
            <Styles.H1 >Warehouse</Styles.H1>





        </div>
        <WHInventory />
    </div>
    );
}

export default AdminMain;