const token = localStorage.getItem("token") || "";

const WHAPI = {


    wrUpateV2: (args) => {

        return new Promise((resolve, reject) => {

            //arn:aws:lambda:us-east-1:645160913840:function:PRK_WR_UPDATE_V2
            //

            let {
                fileId,
                vendorPOId,
                wrId,
                wrRemarks,
                deletePackageId,
                updatePackage,
                newPackage,
                warehouseId
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/vendorPO/${vendorPOId}/wr/${wrId}/v2`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    fileId,
                    vendorPOId,
                    wrId,
                    wrRemarks,
                    deletePackageId,
                    updatePackage,
                    newPackage,
                    warehouseId
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.vendorPOTrackinNumberAdd", err)
                    reject(err);
                });
        })
    },



    wrUpdate: (args) => {

        return new Promise((resolve, reject) => {

            let {
                wrId,
                fileId,
                width,
                length,
                height,
                weight,
                remarks,
                packageType,
                packages
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/wr/${wrId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    width,
                    length,
                    height,
                    weight,
                    remarks,
                    packageType,
                    packages
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.vendorPOTrackinNumberAdd", err)
                    reject(err);
                });
        })
    },


    ///
    wrFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRKWRFetch
        let {
            wrId,
            fileId
        } = args;
        return new Promise((resolve, reject) => {

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/wr/${wrId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.inventoryFetch", err)
                    reject(err);
                });
        })
    },


    wrReleaseStatusUpdate: (args) => {

        return new Promise((resolve, reject) => {

            let {
                fileId,
                wrId,
                remarks,
                statusUpdate,
                undo,
                signature,
                license
            } = args;

            if (
                statusUpdate !== "releaseAuthorize" &&
                statusUpdate !== "release"
            ) {

                return alert("WHAPI.wrReleaseStatusUpdate Status Update Wrong params")
            }

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/wr/${wrId}/release`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: undo ? 'DELETE' : 'POST',
                body: JSON.stringify({
                    remarks,
                    statusUpdate,
                    signature,
                    license
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.vendorPOTrackinNumberAdd", err)
                    reject(err);
                });
        })
    },
    vendorPOTrackinNumberAdd_Delete: (args) => {

        return new Promise((resolve, reject) => {

            let {
                vendorPOId,
                delete_
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/vendorPO/poId/${vendorPOId}/trackingNumber`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: delete_ ? 'DELETE' : 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.vendorPOTrackinNumberAdd", err)
                    reject(err);
                });
        })
    },


    incomingFetch: (args) => {

        return new Promise((resolve, reject) => {

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/incoming`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.inventoryFetch", err)
                    reject(err);
                });
        })
    },

    wrUpdatePOUnits: (args) => {

        return new Promise((resolve, reject) => {
            let {
                fileId,
                vendorPOId,
                wrId,
                units_by_vendorItem
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/file/${fileId}/vendorPO/${vendorPOId}/wr/${wrId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(units_by_vendorItem)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },




    inventoryFetch: (args) => {

        return new Promise((resolve, reject) => {
            //arn:aws:lambda:us-east-1:645160913840:function:PRKWHInventoryGet
            //CALL wh_inventory_get ( ? , ?, ?, ? )
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("WHAPI.inventoryFetch", err)
                    reject(err);
                });
        })
    },


    wrDelete: (args) => {

        return new Promise((resolve, reject) => {
            let {
                vendorPOId,
                wrId
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/${vendorPOId}/wh/${wrId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'DELETE',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    wrAdd: (args) => {

        return new Promise((resolve, reject) => {
            let {
                vendorPOId
            } = args;
            console.warn(args)
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/${vendorPOId}/wh`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },



    wrPhotoDelete: (args) => {

        return new Promise((resolve, reject) => {
            let {
                vendorPOId,
                id
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/${vendorPOId}/photo/${id}`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'DELETE',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    wrPhotoUpload: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRKWHPhotoUpload
        return new Promise((resolve, reject) => {
            let {
                vendorPOId
            } = args;

            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/wh/${vendorPOId}/photo`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    vendorPOFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:prodekFileDetailsGet

        return new Promise((resolve, reject) => {
            let {
                vendorPONumber,
                vendorPOId
            } = args;

            let url;

            if (vendorPOId) {
                url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/vendorPO/poId/${vendorPOId}`;

            } else {
                url = ` https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/admin/vendorPO/poNumber/${vendorPONumber}`;

            }


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


}

export default WHAPI;