import React from "react";
import Header from './Header';
import { Helmet, HelmetProvider } from "react-helmet-async";

import Fetching from "../Client/Fetching";

import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import { NavBarSetup } from './Components/NavBarSetup'
import API from "../../api/CustomerServiceAPI";

function AgentDetails() {
    const navigate = useNavigate();
    const agentId = useParams().agentId;
    const [fetching, setFetching] = React.useState(false);
    const [data, setData] = React.useState();
    const dataSearch = async () => {
        if (agentId !== 'new') {
            API.agents({
                id: agentId
            }).then(res => {
                setFetching(false);
                setData(res.data.agent)
            });
        } else {
            setFetching(false);
            setData({
                userId: 0,
                userName: '',
                userEmail: '',
                userPhone: '',
                whatsAppReceiveNotifications: false
            });
        }


    }
    /* eslint-disable */
    React.useEffect(() => {

        setFetching(true);
        dataSearch();


    }, []);
    /* eslint-enable */


    const Details = ({ item }) => {



        /* eslint-disable */
        const [searchParams, setSearchParams] = useSearchParams();
        /* eslint-enable */
        const [userName, setUserName] = React.useState(item.userName);
        const [userEmail, setUserEmail] = React.useState(item.userEmail);
        const [userPhone, setUserPhone] = React.useState(item.userPhone);
        const [receiveNotifications, setReceiveNotifications] = React.useState(item.whatsAppReceiveNotifications);
        const [saving, setSaving] = React.useState(false);
        const [deleting, setDeleting] = React.useState(false);
        const [showMessage, setShowMessage] = React.useState(false);

        React.useLayoutEffect(() => {
            if (searchParams.get('msg') === 'user_created') {
                setShowMessage('User created');
                setTimeout(() => {
                    setShowMessage(false);
                    setSearchParams({});
                }, 4000);
            }
        }, [searchParams, setSearchParams]);



        const fnSave = () => {
            setSaving(true);
            let args = {
                id: item.userId,
                body: {
                    id: item.userId,
                    userName,
                    userEmail,
                    userPhone,
                    whatsAppReceiveNotifications: receiveNotifications
                }

            }

            API.agents(args).then(res => {
                setSaving(false);
                if (!res.success) {
                    alert(res.msg);
                } else {
                    setShowMessage(res.msg);
                    if (agentId === 'new') {

                        setData(res.data.agent)
                        navigate(`/admin/customer-service/setup/agent/${res.data.agent.userId}?msg=user_created`);
                    }
                    setTimeout(() => {
                        setShowMessage(false);

                    }, 4000);
                }
            });
        }
        const fnDelete = () => {
            if (!window.confirm("Are you sure you wish to delete this user?")) {
                return;
            }
            setDeleting(true);
            let args = {
                id: item.userId,
                delete: true,
            }

            API.agents(args).then(res => {
                setDeleting(false);
                if (!res.success) {
                    console.log(res);
                    alert(res.msg);
                } else {
                    navigate(`/admin/customer-service/setup?msg=user_deleted`);
                }
            });
        }

        return <div
            style={{
                padding: 10
            }}
        >


            <div className="div-col">
                <label>Name</label>
                <input
                    type="text"
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    style={{
                        width: 250
                    }}
                />
            </div>
            <br />
            <div className="div-col">
                <label>Phone</label>
                <input
                    type="text"
                    value={userPhone}
                    autoComplete="off"
                    onChange={e => setUserPhone(e.target.value)}
                    style={{
                        width: 150
                    }}
                />
            </div>

            <br />
            <div className="div-col">
                <label>Email</label>
                <input
                    type="text"
                    value={userEmail}
                    onChange={e => setUserEmail(e.target.value)}
                    style={{
                        width: 250
                    }}
                />
            </div>

            <br />
            <div className="div-col">
                <label>Receive Notifications</label>
                <button
                    style={{
                        width: 80,
                        backgroundColor: receiveNotifications ? '#00ff00' : '#ff0000',
                        color: receiveNotifications ? '#000000' : '#ffffff',
                    }}
                    onClick={() => setReceiveNotifications(!receiveNotifications ? 1 : 0)}
                >
                    {
                        receiveNotifications ? 'Yes' : 'No'
                    }
                </button>
            </div>

            <br />
            <div className="div-row" style={{
                justifyContent: "space-between"
            }}>
                <div className="div-col"  >
                    <button
                        onClick={fnSave}
                        className="btn btn-primary"
                        style={{
                            width: 150
                        }}
                        disabled={saving}
                    >
                        {saving ?
                            <ThreeDots height={10} />
                            :
                            "Save"
                        }
                    </button>
                    {!!showMessage && <div className="confirmation-message">{showMessage}</div>}
                </div>
                {agentId !== 'new' && <div>
                    <button
                        className="btn btn-danger"
                        disabled={deleting}
                        onClick={fnDelete}
                    >
                        {
                            deleting ?

                                <ThreeDots height={10} />
                                :
                                "Delete"
                        }
                    </button>
                </div>
                }
            </div>
        </div>

    }


    return (<>
        <HelmetProvider>
            <Helmet>
                <title>Agent</title>
            </Helmet>
        </HelmetProvider>
        <Header />
        <Container
            style={{
                marginTop: 70,
                maxWidth: 650
            }}
        >


            <NavBarSetup
                agentId={agentId}
            />

            {fetching ?
                <Fetching />
                :
                <div
                    style={{
                        width: '100%',
                        maxWidth: 550,
                        marginTop: 10,
                    }}
                >
                    {!!data &&
                        <Details
                            item={data}
                        />
                    }

                </div>
            }

        </Container>
    </>
    );
}

export default AgentDetails;