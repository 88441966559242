
import React from "react";

import Moment from 'react-moment';

const FileNotes = ({
    notes
}) => {


    return notes?.map((note, index) => {
        return <div key={index} className="div-col"
            style={{
                margin: '5px 0px',
                padding: '5px 0px',
                backgroundColor: index % 2 ? '#fff' : '#f5f5f5',
                borderBottom: '1px solid #ccc',
            }}>
            <div className="div-row-space-between">
                <div>{note.userName}</div>
                <div><Moment date={note.noteDT} format="MMM DD YY" tz="America/New_York" /> </div>
            </div>
            <div style={{ fontWeight: 'bold' }} >{note.title}</div>
            <div style={{
                maxWidth: 500,
                fontSize: 14
            }} dangerouslySetInnerHTML={{ __html: note.content.replaceAll('%20', " ").replaceAll('%3C', ' ').replaceAll("\n", "<br/>") }}></div>
        </div>
    })

}

export default FileNotes;