import React from "react";
import Header from './Header';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Circles, } from "react-loader-spinner";

import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IoAttach, } from "react-icons/io5";
import CustomerServiceAPI from "../../api/CustomerServiceAPI";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

import { NavBarTickets } from './Components/NavBarTickets';


function Tickets() {

    let userId = useParams().userId;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CS_REQUESTS,
        CS_AGENTS
    } = dataReducer;

    const [status, setStatus] = React.useState({ value: 'all', label: 'All' });
    const [fetching, setFetching] = React.useState(false);
    const [startDate, setStartDate] = React.useState(userId ? '' : new Date(Date.now() - 604800000));
    const [endDate, setEndDate] = React.useState(new Date());
    const [search, setSearch] = React.useState('');
    const [agentId, setAgentId] = React.useState(userId || '0');

    const [recordCount, setRecordCount] = React.useState(null)







    const dataSearch = async () => {
 
        if (CS_AGENTS?.length === 0) {
            let agents = await CustomerServiceAPI.agents({});
            dispatch({
                type: 'CS_AGENTS_SET',
                data: agents.data.agents.map(a => {
                    return ({
                        'id': a.userId,
                        'name': a.userName
                    })
                })
            });
        }


        let res = await CustomerServiceAPI.fetch({
            type: 'requests',
            status: status,
            search: search.trim() === '' ? null : search,
            startDate,
            endDate,
            agentId
        });

        setFetching(false);
        setRecordCount(res.data.recordCount);
        dispatch({
            type: 'CS_REQUESTS_SET',
            data: res.data
        });
        const scrollPosition = sessionStorage.getItem('scrollPosition');
        if (scrollPosition) {
            setTimeout(() => {
                window.scrollTo(0, parseInt(scrollPosition, 10));
                sessionStorage.removeItem('scrollPosition');

            }, 200);

        }

    }

    /* eslint-disable */
    React.useLayoutEffect(() => {
        if (!CS_REQUESTS || CS_REQUESTS?.length === 0) {
            setFetching(true);
        }
        dataSearch();
    }, []);

    React.useEffect(() => {
        setFetching(true);
        dataSearch();
    }, [status, startDate, endDate, agentId]);

    /* eslint-enable */



    return (<>
        <HelmetProvider>
            <Helmet>
                <title>Customer Service Request</title>
            </Helmet>
        </HelmetProvider>
        <Header />

        <Container
            style={{
                marginTop: 60,
                maxWidth: 650
            }}
        >

            <NavBarTickets
                activeKey={"tickets"}
            />
            <div
                className="div-row"
                style={{
                    justifyContent: 'space-between',

                    padding: '10px',
                    flexWrap: 'wrap'
                }}
            >
                <div
                    className="div-row"
                >
                    <div className="div-col"
                        style={{
                            marginRight: 5
                        }}
                    >
                        From
                        <DatePicker className="date-picker-custom" selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="div-col"
                        style={{
                            marginRight: 5
                        }}
                    >
                        To
                        <DatePicker className="date-picker-custom" selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>

                    <div
                        className="div-col"
                        style={{

                            marginRight: 5
                        }}
                    >
                        Status<br />
                        <select
                            className="select-custom"
                            onChange={(e) => {
                                setStatus(e.target.value);
                            }}
                            defaultValue={status}
                            style={{
                                fontSize: 17
                            }}
                        >
                            <option value="all">All</option>
                            <option value="open">Open</option>
                            <option value="active">Active</option>
                            <option value="closed">Closed</option>

                            <option value="archived" >Archived</option>
                        </select>
                    </div>
                    <div className="div-col"
                        style={{
                            marginRight: 10
                        }}
                    >
                        Agent<br />
                        <select
                            className="select-custom"
                            onChange={(e) => {
                                setAgentId(e.target.value);
                            }}
                            defaultValue={agentId}
                            style={{
                                fontSize: 17,
                                width: 150
                            }}
                        >
                            <option value="0">Any</option>

                            <option value="-1">Unassigned</option>
                            {CS_AGENTS.map((a, ix) => {
                                return <option key={ix} value={a.id}>{a.name}</option>
                            })

                            }
                        </select>

                    </div>

                    <div

                    >
                        Search<br />
                        <input
                            style={{
                                width: 150
                            }}
                            type="text"
                            value={search}
                            key="search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>


            </div>
            <div
                style={{
                    paddingLeft: 10
                }}
            >
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setFetching(true);
                        dataSearch();
                    }}
                    style={{
                        width: 120
                    }}
                    disabled={fetching}
                >
                    {fetching ? "Searching" : "Search"}
                </button>
            </div>
            {!!recordCount &&


                <div
                    style={{
                        textAlign: 'center',
                        padding: 5
                    }}
                >
                    {recordCount} requests
                </div>
            }
        </Container>
        <Container
            style={{
                maxWidth: 650,
                padding: 0
            }}
        >


            {
                fetching ?
                    <div
                        style={{
                            margin: '50px auto',
                            width: 'fit-content'
                        }}
                    >
                        <Circles />
                    </div>
                    :
                    <div
                        style={{
                            marginBottom: 50
                        }}
                    >

                        {CS_REQUESTS?.requests?.length === 0 ?
                            <div
                                style={{
                                    padding: 50,
                                    textAlign: 'center'
                                }}
                            >
                                No records found
                            </div>
                            :
                            <Container >

                                {

                                    CS_REQUESTS?.requests?.map((item, index) => {

                                        return <Row key={item.id}
                                            style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#e0e0e0' }}
                                        >


                                            <Col
                                                xs={12}
                                                md={8}
                                            >
                                                <Moment date={item.requestDT} format="MMM-DD HH:mm" tz="America/New_York" />
                                                <br />
                                                {!!item.whatsAppNewContactId && <div style={{ margin: "0px 5px 0px 0px", backgroundColor: 'yellow', color: 'red' }} className="badge_ badge_-2">New Contact</div>}
                                                {item.clientName}
                                                <br />
                                                {item.countryName}
                                                <br />
                                                {item.contactName}

                                                <div
                                                    style={{
                                                        padding: 5
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: (item.message || "").replace(/\n/g, '<br />') }}
                                                >

                                                </div>

                                            </Col>
                                            <Col
                                                xs={12}
                                                md={4}
                                            >

                                                {
                                                    item.archivedDT &&
                                                    <div className="badge_" style={{ backgroundColor: 'red', color: '#fff' }}>
                                                        ARCHIVED&nbsp;<Moment date={item.archivedDT} format="MMM-DD HH:mm" tz="America/New_York" />

                                                    </div>
                                                }
                                                {!!item.assignedToUserName &&
                                                    <h5><Badge>{item.assignedToUserName}</Badge></h5>

                                                }

                                                {!!item.closedDT &&
                                                    <div className="badge_ badge_-3">Closed</div>

                                                }
                                                {!!item.isRFQ && <Badge
                                                    variant="dark"
                                                >
                                                    New RFQ
                                                </Badge>
                                                }
                                                {!!item.file_key && <div
                                                    className="badge_"
                                                    style={{
                                                        backgroundColor: 'olive'
                                                    }}
                                                >

                                                    <IoAttach size={18} color="#fff" />
                                                </div>
                                                }
                                                <button

                                                    style={{
                                                        width: 80,
                                                        margin: '10px 5px',
                                                        justifyContent: 'center',
                                                        alignContent: 'center',
                                                    }}
                                                    onClick={() => {


                                                        dispatch({
                                                            type: 'CS_REQUEST_SET',
                                                            data: item
                                                        });
                                                        sessionStorage.setItem('scrollPosition', window.pageYOffset);
                                                        navigate(`/admin/customer-service/tickets/${item.id}`);
                                                    }}
                                                >
                                                    Details
                                                </button>
                                            </Col>






                                        </Row>
                                    })
                                }
                            </Container>
                        }
                    </div>
            }
        </Container>
    </>
    );
}

export default Tickets;

