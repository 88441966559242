
import { call, put, takeLatest, select } from 'redux-saga/effects';

import pageUserAPI from "../api/pageUserAPI";
import FileAPI from "../api/FileAPI";
import WHAPI from '../api/WHAPI';
import ReportsAPI from '../api/ReportsAPI';
import clientsAPI from '../api/clientsAPI';

export const getPhone = (state) => state.dataReducer.phone;

function* fn_userAccountFetch(action) {
    try {
        let phone = yield select(getPhone);
        if (!action.no_fetch_indicator) {
            yield put({
                type: "FETCHING",
                fetching: true,
            });
        }
        const data = yield call(pageUserAPI.fetch, action, (phone || action.phone));
        yield put({ type: "USER_DATA_SET", data });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}


function* fn_fileFetch(action) {
    try {
        if ( !action.NO_FETCH_INDICATOR ){
        yield put({
            type: "FETCHING",
            fetching: true,
        });
    }
        const data = yield call(FileAPI.fileFetch, action);
        yield put({ type: "FILE_SET_SAGA", data });
        yield put({
            type: "FETCHING",
            fetching: false,
        });

    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_fileFetch", message: e.message });
    }
}

function* fn_WHForReleaseFetch(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: true,
        });
        const data = yield call(FileAPI.whForReleaseFetch, action);
        yield put({ type: "WH_FOR_RELEASE_SET", data });
        yield put({
            type: "FETCHING",
            fetching: false,
        });

    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_WHForReleaseFetch", message: e.message });
    }
}


function* fn_WRFetch(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: action.fetching || true,
        });
        const data = yield call(WHAPI.wrFetch, action); 
        
        yield put({ type: "WR_SET", data });
        yield put({
            type: "FETCHING",
            fetching: false,
        });

    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_WRFetch", message: e.message });
    }
}


function* fnWRUpdate(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: "wr_updating",
        });
        const data = yield call(WHAPI.wrUpdate, action);
        yield put({
            type: "FETCHING",
            fetching: "wr_updating_complete",
            fetching_data: data
        });

    } catch (e) {
        console.warn(e)
        yield put({ type: "fnWRUpdate", message: e.message });
    }
}
function* fnReportsFetch(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: "reports_wh_fetching",
        });
        const data = yield call(ReportsAPI.whInventoryFetch, action);
        yield put({ type: "REPORTS_WH_SET", data });
        yield put({
            type: "FETCHING",
            fetching: false
        });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fnReportsFetch", message: e.message });
    }
}

function* fnClientContactUpdate(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: "client_contact_updating",
        }); 
        const data = yield call(clientsAPI.contactUpdate, action);
         
        if (!data.success){
           
            yield put({
                type: "FETCHING",
                fetching:  false 
            });
            alert(data.message || data.msg);
        } else{

            yield put({ type: "FILE_CONTACTS_UPDATE" , data });
            yield put({
                type: "FETCHING",
                fetching: "contact_updated",
                recordId: data.clientContactId
            });
        }
        


    } catch (e) {
        console.warn(e)
        yield put({ type: "fnClientContactUpdate", message: e.message });
    }
}

function* fnClientContactDelete(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: "client_contact_updating",
        }); 
        const data = yield call(clientsAPI.contactDelete, action);
         
        if (!data.success){
            alert(data.message || data.msg)
        } else {
            yield put({ type: "FILE_CONTACTS_DELETE" , data });
        }
        yield put({
            type: "FETCHING",
            fetching: "contact_deleted"
        }); 
    } catch (e) {
        console.warn(e)
        yield put({ type: "fnClientContactDelete", message: e.message });
    }
}




function* fnNotificationsClientsFetch(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: "client_notifications",
        });
        const data = yield call(clientsAPI.clientNotificationsFetch, action);
        
        if (action.clientId){
            yield put({ type: "NOTIFICATIONS_CLIENT_CONTACTS_SET", data });
        }else{
            yield put({ type: "NOTIFICATIONS_CLIENTS_SET", data });
        }
       
        
        yield put({
            type: "FETCHING",
            fetching: false
        });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fnNotificationsClientsFetch", message: e.message });
    }
}



function* fnVendorPOFetch(action) {
    try {
        yield put({
            type: "FETCHING",
            fetching: "vendor_po_fetching",
        });
        const data = yield call(WHAPI.vendorPOFetch, action);
         let vendorPO = data 
        yield put({ type: "VENDOR_PO_SET", vendorPO });
          
        yield put({
            type: "FETCHING",
            fetching: false
        });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fnNotificationsClientsFetch", message: e.message });
    }
}


function* fnWRUpdateV2(action) {
    try {
        let pck = action.updatePackage || action.newPackage;
        if (pck 
            && 
            (
                !pck.packageType
                ||
                !parseInt(pck.packages)
                ||
                !parseInt(pck.length)
                ||
                !parseInt(pck.width)
                ||
                !parseInt(pck.height)
                ||
                !parseInt(pck.weight)
            )

            ){

                alert("Please specify package type and dimensions");
                return;

        } 

        yield put({
            type: "FETCHING",
            fetching: action.deletePackageId ? "wr_deleting_v2" : "wr_updating_v2",
        });
       const data = yield call(WHAPI.wrUpateV2, action);
       
        yield put({
            type: "FETCHING",
            fetching: data.success ? "wr_updating_v2_success" : "wr_updating_v2_error", 
            message: data.msg,
            recordId : data.wrId
        });

    } catch (e) {
        console.warn(e)
        yield put({ type: "fnWRUpdate", message: e.message });
    }
}

export default function* rootSaga() {

    yield takeLatest('USER_DATA_FETCH', fn_userAccountFetch);
    yield takeLatest('FILE_FETCH', fn_fileFetch);
    yield takeLatest('WH_FOR_RELEASE_FETCH', fn_WHForReleaseFetch);
    yield takeLatest('WR_FETCH', fn_WRFetch);
    yield takeLatest('WR_UPDATE', fnWRUpdate);


    yield takeLatest('WR_UPDATE_V2', fnWRUpdateV2);

    yield takeLatest('REPORTS_WH_FETCH', fnReportsFetch);
 

    yield takeLatest('CLIENT_CONTACT_UPDATE', fnClientContactUpdate);
    yield takeLatest('CLIENT_CONTACT_DELETE', fnClientContactDelete);


    yield takeLatest('NOTIFICATIONS_CLIENTS_FETCH', fnNotificationsClientsFetch);
    yield takeLatest('NOTIFICATIONS_CLIENT_CONTACTS_FETCH', fnNotificationsClientsFetch);

    yield takeLatest('VENDOR_PO_FETCH', fnVendorPOFetch);


}

