import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';


const NavBarSetup = ({
    activeKey,
    agentId
}) => <Nav
    activeKey={activeKey}
    variant="pills"
    style={{
        marginBottom: 5
    }}
>
        <Nav.Item>
            <LinkContainer to={`/admin/customer-service/`}>
                <Nav.Link href="/home">Home</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        <Nav.Item key='tickets'>
            <LinkContainer to={`/admin/customer-service/setup/`}>
                <Nav.Link href="#">Agents</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        {
            !!agentId &&

            <Nav.Item  >
                <LinkContainer to={`/admin/customer-service/setup/agent/${agentId}`}>
                    <Nav.Link href="/home">Agent</Nav.Link>
                </LinkContainer>
            </Nav.Item>
        }

    </Nav>

export {
    NavBarSetup
};