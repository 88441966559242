
import React from "react";
import { Circles } from "react-loader-spinner";

import ProdekLogo from '../../assets/prodek.png';
const Fetching = () => {
    return <div
        className="fetching-div"

    >
        <div>
            <Circles
                color="#FF9400"
                height={80}
            />
        </div>
        <br /><br />
        <img src={ProdekLogo} alt="Prodek Logo" height={50} style={{ opacity: 0.5 }} />
    </div>
}

export default Fetching;