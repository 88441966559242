import React from "react";
import Header from './Header';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, } from "react-redux";
import { Circles, ThreeDots } from "react-loader-spinner";
import Moment from 'react-moment';
import { IoLogoWhatsapp, IoMail, IoAttach, } from "react-icons/io5";
import Modal from 'react-modal';

import CustomerServiceAPI from "../../api/CustomerServiceAPI";

import { NavBarTickets } from './Components/NavBarTickets';

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';

function Ticket() {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const id = useParams().id;
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CS_REQUEST
    } = dataReducer;

    const [fetching, setFetching] = React.useState(false);
    const [updating, setUpdating] = React.useState();
    const [requestUserId, setRequestUserId] = React.useState();
    const [showModal, setShowModal] = React.useState(false);


    const fnReopen = () => {
        if (window.confirm('Are you sure you want to reopen to this ticket')) {
            setUpdating("reopen");
            CustomerServiceAPI.fetch({
                type: 'requests',
                id: CS_REQUEST.id,
                data: {
                    status: 'reopen'
                }
            }).then(res => {
                setUpdating(null);
                dispatch({
                    type: 'CS_REQUEST_SET',
                    data: res.data.request
                });
            });
        }
    }

    const fnRespond = () => {
        if (window.confirm('Are you sure you want to respond to this ticket')) {
            setUpdating("respond");
            CustomerServiceAPI.fetch({
                type: 'requests',
                id: CS_REQUEST.id,
                data: {
                    status: 'respond'
                }
            }).then(res => {
                setUpdating(null);
                if (res.success) {
                    dispatch({
                        type: 'CS_REQUEST_SET',
                        data: res.data.request
                    });
                } else {
                    alert(res.msg);

                    dispatch({
                        type: 'CS_REQUEST_SET',
                        data: res.data.request
                    });
                }
            });
        }
    }

    const fnArchive = ({
        undo,
        delete_
    }) => {
        if (window.confirm(`Are you sure you want to ${undo ? 'reactivate' : 'archive'} this ticket`)) {
            setUpdating("archive");
            CustomerServiceAPI.fetch({
                type: 'requests',
                id: CS_REQUEST.id,
                data: {
                    status: delete_ ? 'delete' : (undo ? 'unarchive' : 'archive')
                }
            }).then(res => {
                setUpdating(null);
                if (res.success) {
                    if (delete_) {

                        navigate(`/admin/customer-service/tickets`);
                    }
                    dispatch({
                        type: 'CS_REQUEST_SET',
                        data: res.data.request
                    });
                } else {
                    alert(res.msg);
                    dispatch({
                        type: 'CS_REQUEST_SET',
                        data: res.data.request
                    });
                }
            });
        }
    }


    const fnRelease = () => {
        if (window.confirm('Are you sure you want to release to this ticket')) {
            setUpdating("release");
            CustomerServiceAPI.fetch({
                type: 'requests',
                id: CS_REQUEST.id,
                data: {
                    status: 'release'
                }
            }).then(res => {
                setUpdating(null);
                dispatch({
                    type: 'CS_REQUEST_SET',
                    data: res.data.request
                });
            });
        }
    }




    const RequestActions = ({
        data
    }) => {

        const fnCloseTicket = () => {

            if (closeResult === "--Select--") {
                alert("Please select a result");
                return;
            }

            setUpdating("close");
            CustomerServiceAPI.fetch({
                type: 'requests',
                id: CS_REQUEST.id,
                data: {
                    status: 'close',
                    comments,
                    closeResult
                }
            }).then(res => {
                setUpdating(null);
                dispatch({
                    type: 'CS_REQUEST_SET',
                    data: res.data.request
                });
                setShowModal(false);
            });
        }
        const [comments, setComments] = React.useState("");

        const [closeResult, setCloseResult] = React.useState("--Select--");
        if (!data) return null;


        if (data.archivedDT) {
            return <div
                className="div-row"
                style={{
                    justifyContent: 'space-between',
                    backgroundColor: 'red',
                    alignItems: 'center',
                    padding: 10,
                }}
            >
                <span style={{ color: '#ffffff' }}>Ticket is Archived</span>
                <button

                    className="btn btn-danger"
                    style={{
                        width: 150,
                    }}
                    onClick={() => { fnArchive({ undo: true }) }}
                >
                    Reactivate Ticket
                </button>

                <button

                    className="btn btn-danger"
                    style={{
                        width: 150,
                    }}
                    onClick={() => { fnArchive({ delete_: true }) }}
                >
                    Delete Ticket
                </button>
            </div>
        }

        return <div>
            <Modal
                isOpen={showModal}
                contentLabel="Change Status"
                style={customStyles}
            >
                <div style={{
                    width: 300,
                    height: 200,

                }}>

                    <div
                        className="div-row"
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <h3>Close Ticket</h3>
                        <button
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </button>
                    </div>


                    <select
                        className="select-custom"
                        onChange={(e) => {
                            setCloseResult(e.target.value);
                        }}
                        defaultValue={closeResult}
                    >
                        <option value="--Select--">--Select--</option>
                        <option value="RFQ">RFQ</option>
                        <option value="Not Interested">Not Interested</option>
                        <option value="Contact Again">Contact Again</option>
                    </select>

                    <br />
                    <label>Comments</label>
                    <textarea
                        value={comments}
                        key="comments"
                        onChange={(e) => setComments(e.target.value)}
                        style={{
                            width: 290
                        }}
                        autoFocus
                    >

                    </textarea>
                    <br />

                    <button
                        className="btn"
                        style={{
                            width: 120
                        }}
                        onClick={() => {
                            fnCloseTicket();
                        }}
                        disabled={updating}
                    >
                        {updating === 'close' ? <ThreeDots width={50} height={10} /> : "Close Ticket"}
                    </button>
                </div>
            </Modal>
            <br />
            {!data.assignedToUserId ?
                <div className="div-row-space-between">
                    <button
                        disabled={updating === 'respond'}
                        onClick={fnRespond}
                        style={{
                            height: 35,
                            width: 100
                        }}
                    >
                        {updating === 'respond' ?
                            <ThreeDots height={10} />
                            :
                            "Respond"
                        }
                    </button>


                    <button
                        disabled={updating === 'archive'}
                        style={{
                            height: 35,
                            width: 150,
                            textDecoration: 'underline',
                            color: 'red'
                        }}
                        onClick={fnArchive}
                        className="btn-invisible"
                    >
                        {updating === 'archive' ?
                            <ThreeDots height={10} />
                            :
                            "Archive Ticket"
                        }
                    </button>
                </div>
                :
                <h5>
                    <Badge
                    >Assigned to {data.assignedToUserName}</Badge>
                </h5>
            }


            {data.closedDT === null ?
                <div>
                    {requestUserId === data.assignedToUserId
                        &&
                        <div>

                            {!!data.whatsAppNewContactId &&
                                <div
                                    style={{
                                        margin: '10px 0px',
                                    }}
                                >
                                    <div style={{ margin: "0px 5px 0px 0px", backgroundColor: 'yellow', color: 'red' }} className="badge_ badge_-2">New Contact</div>
                                    <div style={{
                                        padding: 5
                                    }}> {data.clientName} - {data.countryName}</div>
                                    <button
                                        className="btn-primary"
                                    >Create New Client Record</button>
                                </div>
                            }


                            <button
                                disabled={updating === 'close'}
                                style={{
                                    height: 35,
                                    width: 100
                                }}
                                onClick={() => setShowModal(true)}
                            >
                                {updating === 'close' ?
                                    <ThreeDots height={10} />
                                    :
                                    "Close"
                                }
                            </button>
                            &nbsp;
                            <button
                                className="btn-undo"
                                disabled={updating === 'release'}
                                style={{
                                    height: 35,
                                    width: 100
                                }}
                                onClick={fnRelease}
                            >
                                {updating === 'release' ?
                                    <ThreeDots height={10} />
                                    :
                                    "Release"
                                }
                            </button>
                        </div>

                    }
                </div>
                :
                <div>
                    <div className="badge_ badge_-3">
                        Closed
                    </div>

                    <div
                        style={{
                            padding: 10
                        }}
                    >
                        <b>{data.closeResults}</b>
                        <br />
                        {data.comments}
                    </div>


                    {requestUserId === data.assignedToUserId &&
                        <button
                            disabled={updating === 'reopen'}
                            style={{
                                height: 35,
                                width: 100
                            }}
                            onClick={fnReopen}
                            className="btn-undo"
                        >
                            {updating === 'reopen' ?
                                <ThreeDots height={10} />
                                :
                                "Reopen"
                            }
                        </button>
                    }


                </div>
            }



        </div>
    }

    const CSRequest = ({
        data
    }) => {
        const location = useLocation();
        if (!data) return null;
        return <div
            style={{
                padding: 10,
            }}
        >




            <div>

                <div className="div-row-space-between" >
                    <b>Client</b>
                    <b>
                        <Moment date={data.requestDT} format="MMM DD HH:mm" tz="America/New_York" />
                    </b>
                </div>

                {!data.whatsAppNewContactId &&

                    <div>
                        <Link style={{ color: 'blue' }} to={`/admin/customer-service/clients/${data.clientId}?back=${location.pathname + location.search}`} rel="noopener noreferrer" >
                            {data.clientName}
                        </Link>
                        &nbsp;
                        &nbsp;
                        {data.countryName}
                    </div>
                }


            </div>
            <div>
                <b>Contact Name</b>
                <br />
                {data.contactName}
            </div>
            {!!data.file_key && <div
                style={{
                    margin: '5px 0px',
                    overflow: 'hidden',
                }}
            >
                <b>Files</b>
                {data.files?.map((file, index) => {
                    return <div key={index}>
                        <IoAttach color='blue' />
                        <a href={`${file.file}`} target="_blank" rel="noopener noreferrer" >{(file.file_key || "").replace(`${data.id}.`, '')}</a>
                    </div>
                })}
            </div>

            }

            {!!data.isRFQ && <div
                className="badge_"
            >
                New RFQ
            </div>}
            <b>Content</b>
            <div
                style={{

                    paddingLeft: 10,
                    borderLeftStyle: 'solid',
                    borderLeftColor: 'blue',
                    borderLeftWidth: 1,
                }}
                dangerouslySetInnerHTML={{ __html: data.message.replace(/\n/g, '<br />') }}
            >

            </div>

            {requestUserId === data.assignedToUserId &&
                <div

                    style={{
                        padding: 10,
                        marginTop: 10
                    }}

                >
                    <a
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            color: 'blue',
                            width: 'fit-content',
                        }}
                        target={'_blank'}
                        rel='noreferrer'
                        href={`https://wa.me/${data.contactPhone}`}
                    >
                        <IoLogoWhatsapp size={20} />{data.contactPhone}
                    </a>

                    <a
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            color: 'blue',
                            width: 'fit-content',
                            marginTop: 10
                        }}
                        href={`mailto:${data.contactEmail}`}
                    >
                        <IoMail size={20} />{data.contactEmail}
                    </a>



                </div>
            }



            {RequestActions({ data })}

        </div>
    }



    /* eslint-disable */
    React.useLayoutEffect(() => {
        if (CS_REQUEST?.id != id) {
            setFetching(true);
        }
        CustomerServiceAPI.fetch({
            type: 'requests',
            id: id
        }).then(res => {

            setFetching(false);
            setRequestUserId(res.requestUserId);
            dispatch({
                type: 'CS_REQUEST_SET',
                data: res.data.request
            });
        })
    }, []);
    /* eslint-enable */


    return (<>
        <HelmetProvider>
            <Helmet>
                <title>Ticket</title>
            </Helmet>
        </HelmetProvider>
        <Header />
        <Container
            style={{
                marginTop: 60,
                maxWidth: 650
            }}
        >
            <NavBarTickets
                activeKey={"tickets"}
                ticketId={id}
            />


            {/*searchParams.get('back')
                ?
                <div className="navigation-bar" >
                    <div className="navigation-bar-content" >
                        <Link to={searchParams.get('back')}><IoChevronBack /></Link>


                        <Link to={searchParams.get('back')}>Back</Link>
                        <IoChevronForward />
                        <b>Ticket {id}</b>

                    </div>

                </div>

                :
                <NavBarTickets
                    activeKey={"ticket"}
                />
        */}


            <div className="content">
                {
                    fetching ?
                        <div className="div-col" style={{
                            padding: 20,
                            alignItems: 'center',
                        }}>
                            <Circles />
                        </div>
                        :
                        <CSRequest data={CS_REQUEST} />

                }
            </div>

        </Container>
    </>
    );
}

export default Ticket;


const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        background: "#00000090"
    }
};