const token = localStorage.getItem("token") || "";
const api = process.env.REACT_APP_API;


const ReportsAPI = {

    PurchaseOrdersFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_REPORT_PURCHASE_ORDERS
        //CALL report_purchase_orders_pending_fetch

        return new Promise((resolve, reject) => {

            let url;

            let {
                reportType,
                search,
                startDate,
                endDate,
                sort
            } = args;


            url = `${api}/admin/reports/purchase-orders/${reportType}/?1=1`;
            if (search) {
                url += `&search=${encodeURI(args.search)}`;
            }
            if (startDate) {
                url += `&startDate=${encodeURI(args.startDate)}`;
            }
            if (endDate) {
                url += `&endDate=${encodeURI(args.endDate)}`;
            }

            if (sort) {
                url += `&sort=${encodeURI(args.sort)}`;
            }

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: "GET",
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    File: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_FILE_FETCH_UPDATE
        //CALL file_quote_details_fetch

        return new Promise((resolve, reject) => {

            let url;

            let {
                method,
                fileId,
                quoteId,
                clientPOId,
                vendorPOId,
                body
            } = args;

            if (quoteId) {

                url = `${api}/admin/reports/files/${fileId}/quotes/${quoteId}`;
            }
            if (clientPOId) {
                url = `${api}/admin/reports/files/${fileId}/pos/${clientPOId}`;
            }
            if (vendorPOId) {
                url = `${api}/admin/reports/files/${fileId}/vendor-pos/${vendorPOId}/`;
            }

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method,
                body: JSON.stringify(body)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    QuotesFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_REPORT_QUOTES
        //CALL reports_quotes_fetch ('details', null , 28)

        return new Promise((resolve, reject) => {

            let url;

            let {
                reportType,
                salespersonId,
                countryCode
            } = args;


            url = `${api}/admin/reports/quotes/${reportType}/`;
            if (salespersonId) {
                url += `?salespersonId=${salespersonId}`;
            }
            if (countryCode) {
                url += `?countryCode=${countryCode}`;
            }
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: "GET",
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },




    ReporteGestionFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_REPORT_BY_YEARMONTH
        //CALL wh_reports_get (  )

        return new Promise((resolve, reject) => {

            let url;

            let {
                yearMonth,
                groupBy,
                method
            } = args;

            if (args.method === 'GET') {
                url = `${api}/admin/reports/gestion/${yearMonth}?groupBy=${groupBy}`;
                method = 'GET';
            }
            else {
                url = `${api}/admin/reports/gestion/${yearMonth}/xls?groupBy=${groupBy}`;
                method = 'POST';
            }
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );



            console.log(url)
            console.log('x-api-key', process.env.REACT_APP_API_KEY)
            console.log("Authorization", token)

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method,
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    whInventoryFetch: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRKWHReportsDataFetch
        //CALL wh_reports_get (  )

        return new Promise((resolve, reject) => {

            let url = `${api}/admin/wh/reports`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


}

export default ReportsAPI;