const api = process.env.REACT_APP_API;

const pAPI = {


    quoteAcceptReject: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_client_quote_accept_reject
        //CALL client_quote_reject
        //CALL client_quote_accept

        return new Promise((resolve, reject) => {

            let url;
            let {
                token,
                fileId,
                quoteId,
                method,
            } = args;
            url = `${api}/whatsapp/token/${token}/files/${fileId}/quotes/${quoteId}/update`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsIm5hbWUiOiJyZ2FyY2lhIiwiU01TQXV0aFRva2VuIjoiNW56dyIsInBob25lIjoiMTIwMjgwMjMxMzAiLCJyb2xlcyI6WzEsMiwzLDQsNSw2LDcsOF0sImlhdCI6MTY3MjA4OTI3NX0.zE9m0KZo9hbycL7rTp6xbDRJR4fB1zMzoQiVfFNwEqM");
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);

            fetch(url, {
                headers,
                method,
                body: args.body && JSON.stringify(args.body)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    fileFetchUpdate: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_WHATSAPP_FILE_FETCH_UPDATE
        //CALL   
        return new Promise((resolve, reject) => {

            let url;

            let {
                token,
                fileId,
                quoteId,
                poId,
                method,
                invoiceId
            } = args;


            if (quoteId) {
                url = `${api}/whatsapp/token/${token}/files/${fileId}/quotes/${quoteId}`;
            }
            if (poId) {
                url = `${api}/whatsapp/token/${token}/files/${fileId}/pos/${poId}`;
            }
            if (invoiceId) {
                url = `${api}/whatsapp/token/${token}/files/${fileId}/invoices/${invoiceId}`;
            }


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            //headers.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsIm5hbWUiOiJyZ2FyY2lhIiwiU01TQXV0aFRva2VuIjoiNW56dyIsInBob25lIjoiMTIwMjgwMjMxMzAiLCJyb2xlcyI6WzEsMiwzLDQsNSw2LDcsOF0sImlhdCI6MTY3MjA4OTI3NX0.zE9m0KZo9hbycL7rTp6xbDRJR4fB1zMzoQiVfFNwEqM");
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    newContact: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:PRK_WhatsApp_NewClient
        // SP CALL whatsapp_newclient_save
        return new Promise((resolve, reject) => {
            let method = 'GET';
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/whatsapp/token/${args.token}/new-contact`;
            let body = undefined;

            if (args.data) {
                method = 'POST';
                body = JSON.stringify(args.data);
            }


            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);

            fetch(url, {
                headers,
                method,
                body
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn("pAPI.newContacts", err)
                    reject(err);
                });
        })
    },

    fetchV2: (args) => {
        //trae toda la informacion del cliente que se muestra en el app de whatsapp

        return new Promise((resolve, reject) => {
            //  arn:aws:lambda:us-east-1:645160913840:function:PRK_CLIENT_INFO_FETCH
            // CALL client_info_fetch(token);
            let {
                token
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/whatsapp/tokenv2/${token}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    RFQCreate: (args) => {

        return new Promise((resolve, reject) => {
            //   arn:aws:lambda:us-east-1:645160913840:function:PRK_RFQ_RECEIVE
            //  

            let {
                token
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/whatsapp/token/${token}/rfq`;

            var headers = new Headers(
                {
                    //'Content-Type': 'application/json',

                }
            );

            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    console.warn(err)
                    reject(err);
                });
        })
    },

    fetch: (args) => {

        return new Promise((resolve, reject) => {
            //  arn:aws:lambda:us-east-1:645160913840:function:PRK_whatsapp_token_info_fetch
            // CALL whatsapp_info_get_by_token (  token )
            let {
                token
            } = args;
            let url = `https://h57hz2lls4.execute-api.us-east-1.amazonaws.com/prod/whatsapp/token/${token}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


}

export default pAPI           