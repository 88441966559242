import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/Header';
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Circles } from "react-loader-spinner";
import Moment from 'react-moment';
import 'moment-timezone';
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const ByClient = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        report_data
    } = dataReducer;

    if (!report_data) return null;
    return <div
        style={{
            maxWidth: 650,
            margin: 'auto',
            borderWidth: 1, borderStyle: 'solid', borderColor: '#000',
            borderBottomStyle: 'none',
            paddingTop: 10,
        }}
    >
        {report_data.byClient.map((i, ix) => <div
            key={ix}
            style={{
                backgroundColor: ix % 2 ? '#c0c0c0' : '#fff',
                padding: 5,
                borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 4
                }}
            >
                <div>{i.clientName}</div>
                <div>
                    <Moment date={i.oldest} format="MMM-DD" tz="America/New_York" />

                    {i.oldest !== i.newest && <span>  /  <Moment date={i.newest} format="MMM-DD" tz="America/New_York" />
                    </span>
                    }
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flex: 3
                }}
            >

                <div style={{ flex: 2, textAlign: 'right' }}>
                    {formatter.format(i.clientPOItemTotal)}
                    <br />
                    {i.wr_count}<span style={{ fontSize: 10 }}> w/r </span>
                    <br />
                    {(i.total_wt || 0).toLocaleString('en-US')}<span style={{ fontSize: 10, width: 100 }}> lb </span>
                    <br />
                    {(i.total_vol || 0).toLocaleString('en-US')}<span style={{ fontSize: 10, width: 100 }}> sqf</span>
                </div>
            </div>

        </div>)

        }
    </div>
}



const ByWR = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        report_data
    } = dataReducer;

    if (!report_data) return null;
    return <div
        style={{
            maxWidth: 650,
            margin: 'auto',
            borderWidth: 1, borderStyle: 'solid', borderColor: '#000',
            borderBottomStyle: 'none',
            paddingTop: 1,

        }}
    >


        {report_data.byWR.map((i, ix) => {
            let showHeader = true;
            if (ix > 0) {
                showHeader = (i.clientId !== report_data.byWR[ix - 1].clientId)
            }
            return <div
                key={ix}
                style={{

                    borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {showHeader &&
                    <div
                        style={{
                            padding: 5,
                            borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000',
                            color: '#fff',
                            backgroundColor: '#0073BB',
                            textAlign: 'center'
                        }}
                    >{i.clientName}</div>

                }
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flex: 2,
                        padding: 5,
                        alignContent: 'center',
                        backgroundColor: ix % 2 ? '#c0c0c0' : '#fff',
                    }}
                >
                    <div style={{ flex: 2, textAlign: 'right' }}>
                        <Link style={{ color: 'blue', fontWeight: 'bold' }} to={`/admin/wh/file/${i.fileId}/wr/${i.wrId}`} >W/R {i.fileId}{i.wrId}</Link>
                        <br />
                        <Moment date={i.receivedDT} format="MMM-DD" tz="America/New_York" />

                    </div>
                    <div style={{ flex: 1, textAlign: 'right' }}>{i.itemCount}<span style={{ fontSize: 10 }}>items</span></div>

                    <div style={{ flex: 1, textAlign: 'right' }}>{(i.weight || 0).toLocaleString('en-US')} <span style={{ fontSize: 10 }}>lb</span></div>
                    <div style={{ flex: 2, textAlign: 'right' }}>{(i.volume || 0).toLocaleString('en-US')} <span style={{ fontSize: 10 }}>sqft</span></div>
                </div>

            </div>
        })

        }
    </div>
}
const ByItem = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        report_data
    } = dataReducer;

    if (!report_data) return null;
    return <div
        style={{
            maxWidth: 650,
            margin: 'auto',
            borderWidth: 1, borderStyle: 'solid', borderColor: '#000',
            borderBottomStyle: 'none',
            paddingTop: 1,

        }}
    >


        {report_data.byItem.map((i, ix) => {
            let showHeader = true;
            if (ix > 0) {
                showHeader = (i.clientId !== report_data.byItem[ix - 1].clientId)
            }
            return <div
                key={ix}
                style={{

                    borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {showHeader &&
                    <div
                        style={{
                            padding: 5,
                            borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000',
                            color: '#fff',
                            backgroundColor: '#0073BB',
                            textAlign: 'center'
                        }}
                    >{i.clientName}</div>

                }
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 5,
                        alignContent: 'center',
                        backgroundColor: ix % 2 ? '#c0c0c0' : '#fff',
                    }}
                >
                    <div style={{
                        flex: 4
                    }}>
                        <Link style={{ color: 'blue', fontWeight: 'bold' }} to={`/admin/wh/file/${i.fileId}/wr/${i.wrId_min}`} >{i.units} x {i.partno}</Link>
                        <div style={{
                            fontSize: 13
                        }}>
                            {i.desc}
                        </div>
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        <div>{i.receivedDT}</div>
                        <div>{formatter.format(i.clientPOItemTotal)}</div>
                        <Link target={'_blank'} rel='noreferrer' style={{ color: 'blue', }} to={`/admin/wh/file/${i.fileId}`} >File {i.fileId}</Link>
                    </div>
                </div>

            </div>
        })

        }
    </div>
}

function ReportsWH() {

    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    let s = searchParams.get("s");
    const [section, setSection] = React.useState(s || "client");

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "REPORTS_WH_FETCH"
        })
    }, []);
    React.useEffect(() => {
        setSearchParams({ s: section })
    }, [section]);
    /* eslint-enable */


    return (<div style={{ marginBottom: 50 }} >
        <Menu />
        <Header />
        <div style={{
        }}>

            <Styles.H1 style={{ textAlign: 'center' }} >Warehouse Inventory</Styles.H1>

            {fetching &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flex: 1,
                        padding: 20,
                        top: 0,
                        position: 'absolute',
                        backgroundColor: '#e0e0e090',
                        borderRadius: 50,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                        paddingTop: 150
                    }}
                >
                    <Circles
                        color="#FF9400"
                    />
                </div>
            }

            {!fetching &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 5,
                    maxWidth: 650,
                    margin: 'auto',
                }}>
                    <Styles.ButtonTab
                        onClick={() => setSection("client")}
                        active={section === "client"}
                    >
                        By Client
                    </Styles.ButtonTab>
                    <Styles.ButtonTab
                        onClick={() => setSection("wr")}
                        active={section === "wr"}
                    >
                        By W/R
                    </Styles.ButtonTab>
                    <Styles.ButtonTab
                        onClick={() => setSection("item")}
                        active={section === "item"}
                    >
                        By Item
                    </Styles.ButtonTab>

                </div>
            }

            {section === "client" && <ByClient />}
            {section === "wr" && <ByWR />}
            {section === "item" && <ByItem />}

        </div>
    </div>
    );
}

export default ReportsWH;