import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Styles from "../styles";
import Menu from '../app/components/Menu';
import Header from '../app/components/Header';

import Moment from 'react-moment';
import { Circles } from "react-loader-spinner";
import Modal from 'react-modal';

import { IoChevronForward, IoHomeSharp } from "react-icons/io5";

import FileAPI from "../api/FileAPI";
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width: width - 10,
        widthCol: width >= 1400 ? 600 : width >= 400 ? ((width / 2) - 20) : width,
        height
    };
}

const VendorFileStatus = ({
    vendorFile
}) => <div>
        <div
            style={{
                color: 'green'
            }}
        >
            Vendor Quote <b>{vendorFile.vendorQuoteStatus}</b>
        </div>

        {
            vendorFile.vendorPOId !== null &&
            <div>
                Vendor PO {vendorFile.vPOStatus}
            </div>
        }
    </div>

const VendorQuoteComments = ({
    comments
}) => {

    return <div>{
        comments.length > 0 &&
        <div
            style={{
                paddingLeft: 10,
                fontSize: 13
            }}
        >
            {
                comments.map((i, ix) => <div key={ix}>
                    <span>
                        {i.comment}
                    </span>
                </div>)
            }
        </div>

    }
    </div>
}

const VendorQuoteItems = ({
    vendorQuoteItems
}) => {

    return <div
        style={{
            fontSize: 12,
            overflow: 'scroll',
            backgroundColor: '#c0c0c0',
            padding: 5,
            borderRadius: 5,
            maxHeight: 400
        }}

    >
        <div style={{ fontSize: 15, fontWeight: 'bold' }}>{vendorQuoteItems.length} item(s)</div>

        {
            vendorQuoteItems.map((i, ix) => <div key={ix}>
                <div>
                    {i.q} x {formatter.format(i.amt)}
                    <br />
                    {i.itemName}
                </div>
            </div>)
        }
    </div>
}


const VendorQuote = ({ vendorQuote }) => {
    return <div
        className="div-row"
    >
        <div className="div-col"
            style={{ width: 300 }}
        >
            <h3>Vendor Quote</h3>
            {vendorQuote.vendorQuoteId}
            &nbsp;
            {vendorQuote.vendorName}
            &nbsp;
            {vendorQuote.vendorQuoteResponseDT !== null &&
                <Moment date={vendorQuote.vendorQuoteResponseDT} format="MMM-DD-YY" tz="America/New_York" />
            }
            <VendorFileStatus vendorFile={vendorQuote} />

            <div
                style={{
                    color: 'olive'
                }}
            >
                File Status <b>{vendorQuote.fileStatus} {vendorQuote.fileFinishStatus}</b>
            </div>

            <VendorQuoteComments
                comments={vendorQuote.comments}
            />
            <VendorQuoteItems
                vendorQuoteItems={vendorQuote.vendorQuoteItems}
            />
        </div>

        <div className="div-col" style={{ width: 300, paddingLeft: 5 }}>
            <h3>Notes</h3>
            <div>
                <textarea
                    style={{
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: '#000000',
                    }}
                ></textarea>
                <br />
                <button>Add Note</button>
            </div>
        </div>
    </div>
}


const Items = ({
    items
}) => {

    const [showModal, setShowModal] = React.useState(false);
    const [vendorQuote, setVendorQuote] = React.useState({});
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    const navigate = useNavigate();
    /* eslint-disable */
    React.useLayoutEffect(() => {
        const USER_ROLES = (localStorage.getItem("userRoles") || "").split(",");

        if (USER_ROLES.indexOf("9") === -1) {
            navigate("/admin/?unauthorized=potracking");
        }
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /* eslint-enable */

    return <div
        style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }}

    >

        <Modal
            isOpen={showModal}
            contentLabel="Example Modal"
            style={customStyles}
        >

            <button
                style={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                }}
                onClick={() => {
                    setShowModal(false)

                }}
            >Close</button>
            <div
                style={{
                    maxHeight: windowDimensions.height - 150,
                    maxWidth: windowDimensions.width - 80,
                    overflow: 'hidden'
                }}
            >
                <VendorQuote
                    vendorQuote={vendorQuote}
                />
            </div>
        </Modal>

        <div
            style={{
                borderTopWidth: 1, borderTopColor: '#c0c0c0', borderTopStyle: 'solid',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                margin: 'auto',
            }}
        >
            {items.map((i, ix) => <Styles.DivRow
                key={ix}
                style={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}
            >
                <div className="div-row">

                    <div
                        style={{
                            width: 350,
                            paddingBottom: 20,
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'bold',
                                flex: 1,
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={() => {
                                setVendorQuote(i);
                                setShowModal(true);
                            }}
                        >
                            {i.vendorQuoteId}
                            &nbsp;
                            {i.vendorName}
                        </div>
                        <div
                            style={{
                                paddingLeft: 10,
                            }}
                        >

                            {
                                i.vendorQuoteResponseDT !== null ?
                                    <div>
                                        <Moment date={i.vendorQuoteResponseDT} format="MMM-DD-YY" tz="America/New_York" />
                                    </div>
                                    :
                                    <div>
                                        Requested <Moment date={i.vendorQuoteRequestedDT} format="MMM-DD-YY" tz="America/New_York" />
                                    </div>

                            }
                            <div>
                                {i.vendorContactName}
                            </div>
                            <div>
                                {i.vendorContactPhone}
                            </div>
                            <div style={{
                                maxWidth: 300,
                                overflow: 'hidden'
                            }}>
                                {i.vendorContactEmail}
                            </div>


                        </div>

                        <VendorFileStatus vendorFile={i} />


                    </div>



                    <div
                        style={{
                            width: 320,
                            marginLeft: 10
                        }}
                    >
                        <div style={{
                            color: 'olive',
                        }}>
                            <a rel="noreferrer" target="_blank" style={{ color: 'blue' }} href={`https://www.prodekinc.net/search?fileReference=${i.fileId}&option=file`}>File {i.fileId}</a>
                            &nbsp;
                            {i.clientName}

                            <div
                                style={{
                                    color: 'olive'
                                }}
                            >
                                File Status <b>{i.fileStatus} {i.fileFinishStatus}</b>
                            </div>
                        </div>


                        <VendorQuoteComments
                            comments={i.comments}
                        />
                    </div>


                </div>
            </Styles.DivRow >)

            }
        </div>
    </div>
}

const List = () => {
    const [fetching, setFetching] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [recordCount, setRecordCount] = React.useState(0);
    const [clientName, setClientName] = React.useState('');
    const [vendorName, setVendorName] = React.useState('');
    const [search, setSearch] = React.useState('');


    /* eslint-disable */
    React.useEffect(() => {
        DataFetch();

    }, []);
    /* eslint-enable */


    const DataFetch = async () => {
        setFetching(true);
        FileAPI.vendorQuotesFetch({
            clientName: clientName.trim() === '' ? null : clientName,
            vendorName: vendorName.trim() === '' ? null : vendorName,
            search: search.trim() === '' ? null : search,
            statusId: null
        }).then(res => {


            setFetching(false);
            setItems(res.files);
            setRecordCount(res.recordCount);

        });
    }




    return <div>

        <div
            className="div-row-space-between"

        >

            <input
                placeholder="Vendor"
                style={{
                    fontSize: 17,
                    width: 150,
                    margin: 5
                }}
                value={vendorName}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        DataFetch();
                    }

                }}
                onChange={e => {
                    setVendorName(e.target.value)
                }}

            />


            <input
                placeholder="Client"
                style={{
                    fontSize: 17,
                    width: 150,
                    margin: 5
                }}
                value={clientName}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        DataFetch();
                    }

                }}
                onChange={e => setClientName(e.target.value)}

            />



            <input
                placeholder="Search"
                style={{
                    fontSize: 17,
                    width: 150,
                    margin: 5,

                }}
                value={search}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        DataFetch();
                    }

                }}
                onChange={e => setSearch(e.target.value)}

            />
            <div className="div-col" style={{
                width: 170,
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <button
                    className="btn btn-primary"
                    onClick={() => DataFetch()}
                    style={{
                        margin: 5,
                        width: 150,
                    }}
                >
                    Search
                </button>
            </div>

        </div>


        {
            fetching ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        padding: 20,

                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={50}
                    />
                </div>
                :
                <div
                    style={{
                        marginBottom: 50,
                    }}
                >

                    <div style={{
                        textAlign: 'center',
                        padding: 5,
                    }}>{recordCount} quotes found</div>
                    <Items items={items} />
                </div>
        }


    </div >

}


const VendorQuotes = () => {

    return (<div   >
        <Menu />
        <Header />

        <div className="navigation-bar" >
            <div className="navigation-bar-content" >
                <Link to={`/admin/`}><IoHomeSharp /></Link>

                <IoChevronForward />

                <b>Vendor Quotes Tracking</b>
            </div>
        </div>
        <div style={{
            margin: "auto",
            marginTop: 5,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center"
        }}>

            <List />


        </div>
    </div>
    );
}

export default VendorQuotes;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        background: "#00000090"
    }
};