

import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';


const NavBarClients = ({
    activeKey
}) => <Nav
    activeKey={activeKey}
    variant="pills"
>
        <Nav.Item>
            <LinkContainer to={`/admin/customer-service/`}>
                <Nav.Link href="/home">Home</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        <Nav.Item key='clients'>
            <LinkContainer to={`/admin/customer-service/clients/`}>
                <Nav.Link href="/home">Clients</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        <Nav.Item key="active-clients">
            <LinkContainer to={`/admin/customer-service/clients/active-files`}>
                <Nav.Link href="/home">Active Clients</Nav.Link>
            </LinkContainer>
        </Nav.Item>

    </Nav>

export {
    NavBarClients
};