import React from "react";
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/HeaderWH';
import { Circles } from "react-loader-spinner";

import { useDispatch, useSelector } from 'react-redux';

import Moment from 'react-moment';
import 'moment-timezone';

const WHReleaseAuthorizedList = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        wh_for_release_list
    } = dataReducer;


    if (!wh_for_release_list) return;
    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            borderTopWidth: 1, borderTopColor: '#c0c0c0', borderTopStyle: 'solid',
            width: '100%'
        }}
    >

        {wh_for_release_list.data.map((wr, ix) => <div
            key={ix}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: 5,
                flexWrap: 'wrap',
                backgroundColor: ix % 2 ? '#f0f0f0' : "#fff",
                borderBottomWidth: 1, borderBottomColor: '#c0c0c0', borderBottomStyle: 'solid',
            }}
        >

            <div
                style={{
                    marginTop: 5,
                    marginBottom: 5,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: 150
                    }}
                >
                    <a style={{ fontWeight: 'bold', color: 'blue' }}
                        href={`/admin/wh/file/${wr.fileId}/release/?wr=${wr.wrId}&b=release`}
                    >W/R {wr.fileId}{wr.wrId}</a>
                    <br />
                    <Moment date={wr.releaseAuthorizedDT} format="MMM-DD HH:mm" tz="America/New_York" />


                </div>
                <div
                    style={{
                        flex: 2,
                    }}
                >
                    {wr.releaseAuthorizedRemarks || ""}
                </div>

            </div>



        </div>)
        }
    </div >

}

function AdminRelease() {

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "WH_FOR_RELEASE_FETCH"
        })
    }, []);
    /* eslint-enable */


    return (<div   >
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            maxWidth: 600
        }}>
            <div>
                <a href="/admin/wh/inTransit">In Transit</a>|<a href="/admin/wh/">Warehouse</a>
            </div>
            <Styles.H1 >W/Rs for Release</Styles.H1>




            {fetching ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        paddingTop: 200,
                        position: 'absolute',
                        backgroundColor: '#ffffff80',
                        width: '100%',
                        height: '1000'
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={100}
                    />
                </div>
                :
                <WHReleaseAuthorizedList />
            }


        </div>
    </div>
    );
}

export default AdminRelease;