import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Styles from "../styles";
import Menu from '../app/components/MenuWarehouse';
import Header from '../app/components/HeaderWH';
import { Circles, ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";

import Moment from 'react-moment';
import 'moment-timezone';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';


import WRPhoto from "../app/components/WRPhoto";

import WRDetails from "../app/components/WRDetails";

import WHRepackAPI from "../api/WHRepackAPI";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width: width,
        height
    };
}



const WRSpecs = () => {

    //const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);

    const {
        //fetching,
        wr,
        //fetching_data
    } = dataReducer;

    const WR = wr?.wr;
    const { items } = wr;

    const navigate = useNavigate();
    const [undoing, setUndoing] = React.useState(false);
    const [editableWR, setEditableWR] = React.useState(false);


    const RepackedWRs = ({
        repackType,
        repack_wrs
    }) => {





        if (repackType === 'split') return <fieldset>
            <legend>Split from </legend>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                W/R {WR.fileId}{repack_wrs[0].wrId} {repack_wrs[0].packages} {repack_wrs[0].packageType}
                <button
                    onClick={() => {
                        alert("Pending Split UNDO")
                    }}
                    style={{
                        marginLeft: 20,
                        width: 80,
                        backgroundColor: '#c0c0c0',
                        borderRadius: 5,
                        textAlign: 'center',
                    }}
                >
                    {undoing ?
                        <ThreeDots height={10} />
                        :

                        "Undo"

                    }
                </button>

            </div>
        </fieldset>


        if (repackType === 'consolidate') return <fieldset>
            <legend>Consolidated from</legend>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                }}
            >
                <div>
                    {repack_wrs.map((w) => <div
                        key={w.wrId}
                        style={{
                            marginLeft: 10
                        }}
                    >
                        W/R {WR.fileId}{w.wrId} {w.packages} {w.packageType}
                    </div>)}
                </div>
                <button
                    onClick={() => {

                        if (window.confirm("Are you sure you wish to undo ?")) {

                            setUndoing(true);
                            WHRepackAPI.consolidate_undo({
                                fileId: WR.fileId,
                                wrId: WR.id
                            }).then(res => {
                                setUndoing(false);
                                if (res.success) {
                                    navigate(`/admin/wh/file/${WR.fileId}`);
                                } else {
                                    alert(res.msg)
                                }

                            })
                        }
                    }}
                    style={{
                        marginLeft: 20,
                        width: 80,
                        backgroundColor: '#c0c0c0',
                        borderRadius: 5,
                        textAlign: 'center',
                    }}
                >
                    {undoing ?
                        <ThreeDots height={10} />
                        :

                        "Undo"

                    }
                </button>

            </div>
        </fieldset>
    }

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
        }}
    >
        {/*
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}>
            
            <button 
                style={{width: 100}}
                onClick={() => fnDeleteWr()}
                disabled={deleting}
            >
                    {
                            (deleting ) ? <ThreeDots
                            color="red" 
                            height={15}

                            /> :
                            "Delete W/R"
                    }
            </button>
        </div> 
                */}


        <fieldset
            style={{
                padding: 0
            }}
        >
            <div style={{ position: 'relative' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginRight: 10
                    }}
                >
                    {!editableWR &&
                        <button
                            style={{
                                color: 'blue'
                            }}
                            onClick={() => window.open(`/admin/wh/${WR.fileId}/labels/${WR.id}`)}
                        >
                            Print Labels
                        </button>
                    }

                    <button
                        onClick={() => {

                            setEditableWR(!editableWR);
                        }}
                        style={{
                            marginLeft: 10,
                            backgroundColor: editableWR ? '#c0c0c0' : '#ffffff'
                        }}
                    >{
                            editableWR
                                ?
                                "Finish"
                                :
                                "Edit W/R"
                        }</button>
                </div>
            </div>
            <legend style={{ marginLeft: 10 }}>WR Packages</legend>

            <WRDetails
                readOnly={!editableWR}
            />

        </fieldset>
        <RepackedWRs
            repackType={WR.isRepackedWR}
            repack_wrs={wr.repack_wrs}
        />
        <fieldset style={{ position: 'relative', padding: 0 }}>
            <legend style={{ marginLeft: 10 }}>Content</legend>

            <br /><br />
            {items.map((item, ix) => <div
                key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10,
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    borderTopColor: '#c0c0c0',
                    padding: 5
                }}
            >
                <Styles.DivRowSimple>
                    <span style={{ color: 'blue' }}>{item.unitsReceived}  x {item.partNo}</span>
                    <span style={{
                        color: item.invoiceId === null ? 'red' : 'green',
                        textAlign: 'right'
                    }}> {item.invoiceId === null ?
                        "Not Invoiced"
                        :
                        `Invoice ${item.invoiceId}`
                        }
                    </span>
                </Styles.DivRowSimple>


                <span>{item.partDesc}</span>

            </div>)}
            <button
                onClick={() => navigate(`idw`)}
                style={{
                    top: 0,
                    right: 10,
                    position: 'absolute'
                }}
            >Edit Content</button>
        </fieldset>

        <fieldset
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <legend>Status</legend>

            <div
                style={{
                    marginBottom: 10
                }}
            >
                Received <Moment date={WR.receivedDateTime} format="MMM-DD HH:mm" tz="America/New_York" />
                <div
                    style={{
                        fontSize: 13
                    }}
                >
                    {WR.remarks}
                </div>
            </div>

            {WR.releaseAuthorized === 1 ?
                <div>
                    <div style={{
                        backgroundColor: 'green',
                        padding: 5,
                        borderRadius: 5,
                        color: '#fff',
                        width: 330,
                        textAlign: 'center'
                    }} >Released Authorized <Moment date={WR.releaseAuthorizedDT} format="MMM-DD HH:mm" tz="America/New_York" />
                        <div>
                            {WR.releaseAuthorizedRemarks}
                        </div>
                    </div>
                </div>
                :
                <>
                    {wr.items.length === 0 ?
                        <Link style={{ color: 'red' }} to={`/admin/wh/file/${WR.fileId}/wr/${WR.id}/idw`} >
                            Content Not Specified
                        </Link>
                        :
                        <Link
                            to={`/admin/wh/file/${WR.fileId}/release?wr=${WR.id}`}
                        >
                            Authorize Release
                        </Link>
                    }

                </>
            }


            {WR.released === 1 &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <div style={{
                        backgroundColor: 'green',
                        padding: 5,
                        borderRadius: 5,
                        color: '#fff',
                        width: 330,
                        textAlign: 'center',
                        margin: 10
                    }} >Released <Moment date={WR.releasedDT} format="MMM-DD HH:mm" tz="America/New_York" />


                    </div>

                    <img height="120" alt="Signature" src={`${WR.releaseSignature}`} />

                    <Zoom>
                        <img width="200" alt="License" src={`${WR.releaseLicense}`} />
                    </Zoom>

                    <br />

                    <Link to={`/admin/wh/file/${WR.fileId}/release?wr=${WR.id}`}  >
                        Undo
                    </Link>
                    <Link to={`/admin/wh/file/${WR.fileId}/wr/${WR.id}/release`}  >
                        [Undo____] (OLD)
                    </Link>
                </div>
            }

            {
                WR.releaseAuthorized === 1 && WR.released === 0 &&
                <div style={{
                    marginTop: 10
                }}>
                    <Styles.ButtonMain
                        style={{
                            width: 150,
                            color: 'black'
                        }}
                        onClick={() => {

                            navigate(`/admin/wh/file/${WR.fileId}/release/?wr=${WR.id}`)
                            //navigate(`/admin/wh/file/${WR.fileId}/wr/${WR.id}/release`)

                        }}
                    >
                        Release
                    </Styles.ButtonMain>
                </div>
            }
        </fieldset >
        {
        /*
            !editableWR && 
        <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 10
            }}>
                {photos.map((photo, ix) => {

                    return <div
                        key={ix}
                        style={{
                            marginRight: 10
                        }}
                    >
                        <Zoom>
                            <img style={{
                                borderStyle: 'solid', borderWidth: 1, borderColor: '#000'
                            }} src={`${photo.data_url}`} width="100" alt={WR.id} />
                        </Zoom>
                        { !!photo.isPackingList && <span style={{fontSize: 13}}>Packing List <br/></span>  }

                    </div>
                })}
            </div>
        
            */}
    </div >

}

const WRDetails2 = () => {

    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    const [section, setSection] = React.useState("WR");

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
        wr
    } = dataReducer;

    /* eslint-disable */
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    React.useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /* eslint-enable */




    if (fetching === true || !wr) return null;

    let WR = wr.wr;

    return <div
        style={{
            width: windowDimensions.width,
            height: windowDimensions.height,
            maxWidth: 650
        }}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 5,
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: '#000',
            marginBottom: 10,
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        }}>
            <Styles.ButtonTab
                color={"blue"}
                onClick={() => setSection('WR')}
                active={section === 'WR'}
            >
                W/R
            </Styles.ButtonTab>
            <Styles.ButtonTab
                color={"blue"}
                onClick={() => setSection('CLIENT')}
                active={section === 'CLIENT'}
            >
                Client
            </Styles.ButtonTab>
            <Styles.ButtonTab
                color={"blue"}
                onClick={() => setSection('VENDOR')}
                active={section === 'VENDOR'}
            >
                Vendor
            </Styles.ButtonTab>
            <Styles.ButtonTab
                color={"blue"}
                onClick={() => setSection('FILES')}
                active={section === 'FILES'}
            >
                Files
            </Styles.ButtonTab>

            {wr.other_wrs.length > 0 &&
                <Styles.ButtonTab
                    color={"blue"}
                    onClick={() => setSection('OTHER_WRS')}
                    active={section === 'OTHER_WRS'}
                >
                    Other WRs
                </Styles.ButtonTab>
            }
        </div>


        {
            section === 'FILES' && <div
                style={{
                    padding: 5
                }}
            >

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        margin: 'auto',
                        marginTop: 20,
                    }}
                >

                    {
                        wr.photos.map((photo, ix) => <WRPhoto width={140} noTitle={true} key={ix} photo={photo} fileId={wr.wr.fileId} />)
                    }

                    {
                        wr.repack_photos.length > 0 && <div style={{ backgroundColor: 'blue', padding: 5, color: '#fff' }}>Repacked W/R</div>
                    }
                    {
                        wr.repack_photos.map((photo, ix) => <WRPhoto key={ix} photo={photo} fileId={wr.wr.fileId} />)
                    }
                </div>
            </div>
        }

        {
            section === 'WR' && <WRSpecs />
        }

        {
            section === 'CLIENT' &&
            <div style={{
                padding: 5,
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <span style={{ fontWeight: 'bold' }}>File# {WR.fileId}</span>
                <span>{wr.client.clientName}</span>
                <span>{wr.client.clientAddress1}</span>
                <span>{wr.client.clientAddress2}</span>
            </div>
        }


        {
            section === 'VENDOR' &&
            <div style={{
                padding: 5,
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>

                <span style={{ fontWeight: 'bold' }}>Vendor PO Number {WR.vendorPONumber}</span>
                <span>{wr.vendor.vendorName}</span>
            </div>
        }
        {
            section === 'OTHER_WRS' &&
            <div style={{
                padding: 10,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <b>Other W/Rs in File</b>
                {wr.other_wrs.map((w, ix) => <div>

                    <Link
                        to={`/admin/wh/file/${w.fileId}/wr/${w.id}`}
                        style={{
                            color: 'blue',
                            marginRight: 10,
                            width: 150
                        }}
                    >W/R {WR.fileId}{w.id}</Link>
                    <span>{w.description}</span>

                </div>)}

                {wr.repack_wrs.length > 0 &&
                    <div
                        style={{
                            marginTop: 10
                        }}
                    >
                        <b>Repacked W/R</b>
                        {wr.repack_wrs.map((w, ix) => <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',

                            }}
                        >

                            <Link
                                to={`/admin/wh/file/32530/wr_repacked/${w.wrId}`}
                                style={{
                                    fontWeight: 'bold',
                                    marginRight: 10,
                                }}
                            >W/R {WR.fileId}{w.wrId}</Link>
                            <div style={{ width: 100 }}>{w.packages} {w.packageType}</div>
                            <div>{w.repackType}</div>

                        </div>)}
                    </div>
                }

            </div>
        }



    </div >
}

function WRRelease() {

    const { wrId, fileId } = useParams();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
        wr
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        dispatch({
            type: "WR_FETCH",
            fileId,
            wrId
        })
    }, []);
    React.useEffect(() => {
        if (fetching === "wr_updating_v2_success") {
            //si el update fue exitoso, hacer reload de la data del W/R
            dispatch({
                type: 'WR_FETCH',
                fetching: "wr_fetching",
                wrId: wrId,
                fileId: fileId,
            });
        }
    }, [fetching]);
    /* eslint-enable */


    if (wr?.authenticationFailed) {
        return <div style={{ padding: 50, color: 'red' }}>
            Unathorized
            <br />
            WR.jsx
            W/R id {wrId}
            <br />
            <Link to="/login">Login</Link>
        </div>
    }

    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>File {fileId}  | W/R {fileId}{wrId}</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flexShrink: 0,
            flexGrow: 1,
            maxWidth: 600,
        }}>
            <div>
                <Link to={`/admin/wh/`}>Warehouse</Link> | <Link to={`/admin/wh/file/${fileId}`} >File {fileId}</Link>
            </div>
            <Styles.H1 style={{ color: 'blue' }} ><Link to={`/admin/wh/file/${fileId}`} >File {fileId}</Link> &gt; W/R {fileId}{wrId}</Styles.H1>


            {fetching === true ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        paddingTop: 200,
                        position: 'absolute',
                        backgroundColor: '#ffffff80',
                        width: '100%',
                        height: '1000'
                    }}
                >
                    <Circles
                        color="#FF9400"
                        height={100}
                    />
                </div>
                :
                <WRDetails2 />
            }



        </div>
    </div>
    );
}

export default WRRelease;