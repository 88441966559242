import styled from 'styled-components';


/* 
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  color: #444;
  border: 1px solid #1890ff;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #1890ff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: .3s background;
  &:hover {
    background: #40a9ff;
  }
`;

*/



const H1 = styled.h1`
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0px; 
  font-size: 1.4em;
`

const PageTitle = styled.h1`
      
    height: 20px; 
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center; 
    padding: 10px; 
    margin: 0px;
    background-color: #f8f8f8;
    color: #18391A;
    border-bottom-color: #808080;
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;

const ModalDiv = styled.div`
    
    width: 100%;  
    height: 100%;
    minHeight: 1000px;
    
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.80);
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center; 
    padding: 10px; 
    z-index: 2000
`;

const ContentDiv = styled.div`
    margin: auto;
    max-width: 450px; 
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center; 
    padding: 10px; 
`;

const ButtonDetalles = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #808080;
    border-radius: 5px;
    color: #000000
`;



const ButtonCircular = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;    
    border-style: none;
    border-width: 1px;
    border-color: #808080;
    border-radius: 18px;
    background-color: #FF9400;
    color: #fff;
    padding: 0
`;

const ButtonMain = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #808080;
    border-radius: 5px;
    background-color: #FF9400;
    color: #fff; 
    font-size: 17px;
    padding-right: 10px,
    padding-left: 10px;
`;


const ButtonSecondary = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #808080;
    border-radius: 5px;
    background-color: #3482B3;
    color: #fff;
    font-size: 15px;
    padding-right: 10px,
    padding-left: 10px;
`;

const ButtonCancel = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #000000; 
    border-radius: 5px;
    background-color: #e0e0e0;
    color: #000; 
`;
 



const ButtonMD  = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 30px; 
    border-style: solid;
    border-width: 1px;
    border-color: blue; 
    border-radius: 5px;
    background-color: #c0c0c0;
    color: blue; 
    font-size : 12px;
    padding: 5px;
    cursor: pointer;
`;

const ButtonDelete = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 50px;   
    border-style: solid;
    border-width: 1px;
    border-color: #EA4335; 
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #EA4335; 
    font-size : 12px;
    padding: 5px;
    cursor: pointer;
`;

const ButtonTab = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: ${props => props.active ? "32px" : "30px"} ;
    border-style: solid;
    border-width: 1px;
    border-color: #000000 ; 
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-bottom: ${props => props.active ? "-2px" : "-1px"} ;
    background-color:  ${props => props.active ? "#fff" : "#c0c0c0"} ;
    border-bottom-color:  ${props => props.active ? "#fff" : "#000000"} ;
    border-bottom-style: none;
     
    color: ${props => props.color || "#000"} ;
    font-size: 13px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
    font-size: 12px;
`;




const DivTable = styled.div`
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center;  
`;
const DivRow = styled.div`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between; 
    padding: 5px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #c0c0c0;
    border-top-style: none; 
`;

const DivCol = styled.div`
    flex-direction: column;
    display: flex; 
    align-items: flex-start;
`;

const DivRowSimple = styled.div`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;  
`;

const ButtonTab2 = styled.button`
    background-color:  ${props => props.active ? "#000" : "transparent"} ;
    color:  ${props => props.active ? "#fff" : "#000"} ;
`;

const DevNote = styled.div`
  font-size: 12px;
  color: #c0c0c0;  
  margin-top: 100px;
`;


const DivFetching = styled.div`
  position: absolute;
  top: 0px;
  background-color: #00000080; 
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flexDirection: column;
  z-index: 10000;

`;


const Styles = {
    ButtonTab2,
    ButtonCircular,
    ButtonDetalles,
    ButtonMain, ButtonCancel, ButtonDelete,
    ContentDiv,
    ModalDiv,
    ButtonSecondary,
    PageTitle,
    H1,
    ButtonTab,
    DivRow,
    DivTable,
    DevNote,
    DivFetching,
    DivRowSimple,
    DivCol,
    ButtonMD
}
export default Styles;