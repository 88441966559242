import React from "react";
import Menu from './Menu';
import Header from './Header';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
function CSClientPage() {


    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Reports</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 350,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <h2>Reports</h2>

            <br />
            <Link to={`/admin/reports/monthly/`}>Monthly Performance</Link>

            {(process.env.REACT_APP_VERSION >= 2.0) &&
                <React.Fragment>
                    <br />
                    <hr style={{
                        width: '100%'
                    }} />
                    <br />
                    <h4>Quotes</h4>
                    <br />
                    <Link to={`/admin/reports/quotes/`}>Pending Quotes</Link>
                    <br />
                    <hr style={{
                        width: '100%'
                    }} />
                    <br />
                    <h4>Purchase Orders</h4>
                    <br />
                    <Link to={`/admin/reports/pos/pending`}>Pending Purchase Orders</Link>
                    <br />
                    <Link to={`/admin/reports/pos/delivered`}>Delivered Purchase Orders</Link>

                </React.Fragment>
            }


        </div>
    </div>
    );
}

export default CSClientPage;