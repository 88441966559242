import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import {  ThreeDots } from "react-loader-spinner";
import {  useNavigate  } from "react-router-dom";
 
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {  IoCameraOutline  } from "react-icons/io5";

import Styles from "../../styles";
import WRPackage from "./WRPackage";

import WHAPI from "../../api/WHAPI";


const resizeBase64Img = (base64Str) => {
    return new Promise((resolve, reject)=>{
        const MAX_WIDTH =   1500;
        const MAX_HEIGHT =  1500;
        var img = new Image();
        img.src = base64Str;
        img.onload = () => {
                var canvas = document.createElement('canvas');
                
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height); 
                 
                resolve ({
                    data_url: canvas.toDataURL(),
                    w: width,
                    h: height
                });
            } 
    });
}
 
const readURL = file => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = e => res(e.target.result);
        reader.onerror = e => rej(e);
        reader.readAsDataURL(file);
    });
};


const WRPhotos = ({
    wr
}) => {
 
    const dispatch = useDispatch(); 
    const dataReducer = useSelector((state) => state.dataReducer); 
    const { 
        fetching
    } = dataReducer; 
    const [ image, setImage ] = React.useState()

    const fnPhotoUpload = ({packingList}) => {
        let randomid = Math.floor(Math.random() * 100000);
          
        let args = {
            data:  image,
            vendorPOId : wr.wr.vendorPOId ,
            photoId: randomid,
            wrId : wr.wr.id,
            isPackingList : packingList
        }; 
        
        dispatch({
            type: 'FETCHING',
            fetching: "uploading_photo"
        }); 

            
        WHAPI.wrPhotoUpload(args).then(r => {
            setImage(undefined);
            dispatch({
                type: 'FETCHING',
                fetching: false
            });  
            
            dispatch({
                type: "WR_FETCH",
                fileId : wr.wr.fileId,
                wrId: wr.wr.id
            });
        })
        ;

         
    }
    
    const preview = async event => {
        const file = event.target.files[0];
        const url = await readURL(file);
        const resizedImage = await resizeBase64Img(url); 
        setImage(resizedImage)
    };
 
    return  <div>
        {
            !!image && <div
                style={{
                    position: 'absolute', 
                    top: 0, 
                    left: 0,
                    width: '100%', 
                    height: '100%',
                    backgroundColor: '#00000099',
                    zIndex: 2000,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img
                    alt="W/R"
                    src={image.data_url}
                    width={ 330 }
                    height={ parseInt(image.h) * 330 / image.w}
                    style={{
                        borderColor: '#000',
                        borderWidth: 1, 
                        borderStyle: 'solid'
                    }}
                />
                {fetching ? 
                    <ThreeDots 
                        color={'orange'}
                    />
                    :

                    <div
                        style={{
                            width: 330,
                            margin: 10,
                            display: 'flex',
                            flexDirection: 'row', 
                            justifyContent: 'space-between'
                        }}
                    >
                        <button
                            style={{
                                color: 'red'
                            }}
                            onClick={() => {
                                setImage(undefined);
                            }}
                        >Cancel</button>

                        <button
                            onClick={() => fnPhotoUpload({packingList: true})}
                        >Upload Packing List</button>
                        <button
                            onClick={() => fnPhotoUpload({packingList: false})}
                        >Upload W/R Photo</button>

                    </div>
                }
            </div>
        }

        <br/> 
            <div
                style={{
                    display: 'flex', 
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}
            >
                {wr.photos.map((photo, ix) => {

                    return <div
                        key={ix}
                        style={{
                            marginRight: 10,
                            marginBottom: 10,
                            height: 220,
                            display:  'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <Zoom>
                                <img style={{
                                    borderStyle: 'solid', borderWidth: 1, borderColor: '#000'
                                }} src={`${photo.data_url}`} width="100" alt={wr.id} />
                            </Zoom>
                            { !!photo.isPackingList && <span style={{fontSize: 13}}>Packing List <br/></span>  }
                        </div>
                        <button
                            style={{
                                width : 100,
                                backgroundColor: '#c0c0c0',
                                borderStyle: 'none',
                                fontSize: 12, 
                                marginTop: 10,
                                marginBottom: 10
                            }}
                            onClick={() => { 
                                
                                if (window.confirm("Are you sure you wish to delete this photo?")){
                                    WHAPI.wrPhotoDelete({
                                        id: photo.id,
                                        vendorPOId : wr.wr.vendorPOId,
                                    }).then(r => {
                                        console.warn("Deleted !!")
                                        dispatch({
                                            type: "WR_FETCH",
                                            fileId : wr.wr.fileId,
                                            wrId: wr.wr.id
                                        }) ;
                                    });
                                }
                            }}
                        
                        >Delete Photo</button>
                    </div>
                })}
            </div>
            <div
                style={{
                    marginTop: 10, 
                }}
            >
                <label 
                    htmlFor="file-upload" 
                    //for="file-upload" 
                    style={{
                        padding: 5, 
                        paddingRight: 20, paddingLeft: 20,
                        backgroundColor: '#c0c0c0',
                        borderStyle: 'solid',
                        borderWidth: 1, 
                        borderColor: '#000',
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'row', 
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: 200,
                        fontSize: 17, 
                    }}
                >
                    <IoCameraOutline/> &nbsp;Upload W/R Photo
                </label>
                <input
                    style={{
                        display: 'none'
                    }}
                    id="file-upload"
                    type="file" 
                    accept="image/*" 
                    onChange={(event)=> { 
                        preview(event);
                    }}
                />
            </div>
        
</div>
}




const WRDetails = ({
    readOnly,
    vendorPOId
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        wr ,
        fetching,
        message,
        WAREHOUSES
    } = dataReducer;  

  
    let WR = wr?.wr;

    let [ editPackageIndex, setEditPackageIndex ] = React.useState(null);
    const [remarks, setRemarks] = React.useState(WR.remarks || "");
 
    const [warehouseId, setWarehouseId] = React.useState(WR.warehouseId);
    const [deleting, setDeleting] = React.useState(false);

    const fnDeleteWr = () =>{
        if (window.confirm(`Are you sure you wish to delete this W/R?`)) {
            setDeleting(true);
            WHAPI.wrDelete({
                vendorPOId : wr.wr.vendorPOId,
                wrId: wr.wr.id
            }).then(res => {

                setDeleting(false);
                if (res.success) {
                    navigate(`/admin/wh/file/${WR.fileId}`);
                } else {
                    alert(res.msg);
                };
            }); 
        }
    }


     /* eslint-disable */ 
     React.useEffect(() => { 
        if (fetching === "wr_updating_v2_success"){
            setEditPackageIndex(null); 
        }
        if (fetching === "wr_updating_v2_error" && message){ 
           if(message) setTimeout(alert(message) , 1500) ;
           dispatch({
               type: 'FETCHING',
               fetching: null,
               message: null
           })
        }
    }, [fetching]);
 

    /* eslint-enable */


    if (!wr) return null;
 
    if (!WR) return null;
     
    
    return <div
        style={{
            width: '100%', 
            display: 'flex',
            flexDirection: 'column',  
        }}
    >
    

       
        {readOnly ?
            (remarks || '') !== '' &&
                <div
                    style={{
                        padding: 5
                    }}
                >
                    <div>{WR.whName} Warehouse</div>

                    W/R Remarks<br />
                    {remarks}
                </div>
            :
            <div
                style={{
                    padding: 5
                }}
            > 


            Warehouse
            <br/>
            <select
                style={{
                    fontSize: 17,
                    padding: 5,
                    width: 150
                }}
                value={warehouseId}
                onChange={(e) => setWarehouseId(e.target.value)}
            >
                {WAREHOUSES.map( (w)  => <option key={w.id} value={w.id}>{w.name}</option> )}
            </select>
            
            <br/><br/>
                W/R Remarks<br />
                
                <textarea
                    style={{
                        width: '95%',
                        maxWidth: 400,
                        fontSize: 17
                    }}
                    value={remarks || ""}
                    onChange={e => setRemarks(e.target.value)}
                /> 
            </div>

        }
       
 
        <div style={{
            marginTop: editPackageIndex === null  ? 10 :  0,
        }}>
        {wr.packages.length === 0 ?
                <div style={{padding: 10, backgroundColor: '#c0c0c0'}}>No Packages</div>
            : 
                <div>
                {wr.packages.map( (p,ix) => <div
                        key={ix}
                        style={{
                            padding: 5,
                            backgroundColor: ix%2 && !editPackageIndex  ? '#c0c0c0' : '#ffffff',
                            borderTopStyle: 'solid',
                            borderTopColor: '#c0c0c0',
                            borderTopWidth: 1
                        }}
                    > 
                        { editPackageIndex === (ix+1) &&
                            <WRPackage
                                label={ix+1}
                                PCK={p}
                                fnCancel={() => {
                                    setEditPackageIndex(null)
                                }}
                                fnDelete={(val) => {
                                    if (window.confirm('Are you sure you wish to delete this package?')){
                                        dispatch({
                                            type: 'WR_UPDATE_V2', 
                                            fileId: WR.fileId,
                                            vendorPOId: WR.vendorPOId,
                                            wrId: WR.id,
                                            wrRemarks: remarks,
                                            deletePackageId: val 
                                        }) 
                                    }
                                
                                   
                                }}
                                fnSave={(val) => {
                                    
                                    dispatch({
                                        type: 'WR_UPDATE_V2', 
                                        fileId: WR.fileId,
                                        vendorPOId: WR.vendorPOId,

                                        warehouseId,
                                        wrId: WR.id,
                                        wrRemarks: remarks,
                                        updatePackage: val
                                        
                                    }) 
                                }}
                            />
                        }

                        {editPackageIndex ===  null &&

                            <div
                                style={{
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <div>
                                    <div>{p.packages} {p.packageType}</div>
                                    <div>{p.weight} lbs {p.height} x {p.width} x {p.length}</div>
                                    <div>{p.remarks}</div>
                                </div>
                                {!readOnly && editPackageIndex === null && <div>
                                    <button 
                                        style={{
                                            width: 60,
                                            borderStyle: 'none',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: 'blue',
                                            backgroundColor :'transparent',
                                            textAlign: 'right'
                                        }}  
                                        onClick={() => setEditPackageIndex(ix+1) }
                                    >Edit</button></div>
                                }
                            </div>
                        }
                        
                    
                    </div>   
                )}
                </div>
        }
        </div>
        
        {   editPackageIndex === -1 ?
            <WRPackage
                label={"New"}
                fnCancel={() => {
                    setEditPackageIndex(null)
                }}
                PCK={{
                    id: 0
                }}
                fnSave={(val) => {
 
                    
                    dispatch({
                        type: 'WR_UPDATE_V2',
                       
                            fileId: WR.fileId, 
                            vendorPOId: WR.vendorPOId,
                            wrId: WR.id,
                            wrRemarks: remarks,

                            warehouseId,
                            updatePackage: val
                         
                    })
                   
                }}
            />
            :
            <div>
                { !readOnly && 
                <div
                    style={{ 
                        margin: 10
                    }}
                >
    
                    {editPackageIndex === null &&
                        <div 
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start'
                            }}
                        > 
                            <div>
                                <Styles.ButtonMain 
                                    onClick={() => { 

                                    
                                        dispatch({
                                            type: 'WR_UPDATE_V2', 
                                            fileId: WR.fileId, 
                                            vendorPOId: WR.vendorPOId,
                                            wrId: WR.id,
                                            warehouseId,
                                            wrRemarks: remarks
                                            
                                        })
                                    }}
                                    style={{
                                        width: 200,
                                        marginBottom: 10
                                    }}
                                    disabled={fetching === "wr_updating_v2"}
                                >
                                    {fetching === "wr_updating_v2"  ? 
                                        <ThreeDots 
                                            color={"#fff"}
                                            width={50}
                                        />
                                        :
                                        "Save"
                                    }
                                    
                                </Styles.ButtonMain >

                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <button
                                    disabled={editPackageIndex !== null}
                                    style={{ 
                                        width: 150, 
                                    }}
                                    onClick={() => setEditPackageIndex(-1) }
                                >
                                    Add New Package
                                </button>


                                
                            </div>

                        </div>
                    }


                <button
                    style={{ width: 200 }}
                    onClick={() => window.open(`/admin/wh/${WR.fileId}/labels/${WR.id}`)}
                >
                    Print Labels 
                </button>


                    <WRPhotos
                        wr={wr}
                    />
                </div>
                }

                {!readOnly && 
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                <button 
                    style={{
                        width: 100,   
                        color: 'red', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        margin: 5 
                    }}
                    onClick={() => fnDeleteWr()}
                    disabled={deleting}
                >
                        {
                                (deleting ) ? <ThreeDots
                                color="red" 
                                height={15}

                                /> :
                                "Delete W/R"
                        }
                </button>
                </div>
                    }

            </div>

        }
    </div>
}


export default WRDetails;