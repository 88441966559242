import React from "react";
import Menu from './Menu';
import Header from './Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSearchParams, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { IoCloudDownloadSharp, IoChevronForward, IoHomeSharp } from "react-icons/io5";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

    ArcElement,

} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

import Fetching from "../Client/Fetching";

import API from '../../api/ReportsAPI';
import FileQuoteDetails from "../../app/components/FileQuoteDetails";
import FilePODetails from "../../app/components/FilePODetails";
import {
    formatter
} from '../../app/components/';

const add2arrays = (a, b) => a.map((n, i) => n + b[i]);
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];



ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

    ArcElement,
);

const OPTIONS = {

    plugins: {
        title: {
            display: false,
            text: ' ',
        },
        legend: {
            display: false
        },
    },
};


const OPTIONS_PIE_Q = {

    plugins: {
        title: {
            display: true,
            text: 'Quotes',
        },
        legend: {
            display: false
        },
    },
    redraw: true,
    responsive: true,
    maintainAspectRatio: true
};

const OPTIONS_PIE_P = {

    plugins: {
        title: {
            display: true,
            text: 'Purchase Orders',
        },
        legend: {
            display: false
        },
    },
    redraw: true,
    responsive: true,
    maintainAspectRatio: true
};

const OPTIONS_PIE_I = {

    plugins: {
        title: {
            display: true,
            text: 'Invoices',
        },
        legend: {
            display: false
        },
    },
    redraw: true,
    responsive: true,
    maintainAspectRatio: true
};







const PieCharts = ({
    Data
}) => {

    return <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            width: 650,
            height: 250,
            justifyContent: 'space-between',
            margin: 'auto',
            marginTop: 10,
            marginLeft: 130
        }}
    >
        {Data.Quotes.show &&
            <div
                style={{
                    width: 250
                }}
            >

                <Pie

                    options={OPTIONS_PIE_Q}
                    data={Data.Quotes}
                />

            </div>
        }
        {Data.PurchaseOrders.show ?
            <div
                style={{
                    width: 250
                }}
            >

                <Pie

                    options={OPTIONS_PIE_P}
                    data={Data.PurchaseOrders}
                />

            </div>
            :
            <div />
        }
        {Data.Invoices.show &&
            <div
                style={{
                    width: 250
                }}
            >

                <Pie

                    options={OPTIONS_PIE_I}
                    data={Data.Invoices}
                />

            </div>
        }

    </div>
}


const DataTable = ({
    data,
    lastMonth,
    yearToDate,
    groupBy
}) => {

    lastMonth = lastMonth.substr(4, 6);

    const [Data, setData] = React.useState({});
    const [DataPies, setDataPies] = React.useState();

    const [filters, setFilters] = React.useState(JSON.parse(sessionStorage.getItem("filters")) || { 0: true, 1: true, 2: true })
    const [filters2, setFilters2] = React.useState(JSON.parse(sessionStorage.getItem("filters2")) || {})
    const [filters3, setFilters3] = React.useState(JSON.parse(sessionStorage.getItem("filters3")) || { 'USD': true, 'EUR': false });
    const [data_objs, setDataObjs] = React.useState([])

    const [itemsWithValues, setItemsWithValues] = React.useState({});

    React.useLayoutEffect(() => {
        if (!data) {
            return;
        }

        sessionStorage.setItem("filters", JSON.stringify(filters));
        sessionStorage.setItem("filters2", JSON.stringify(filters2));
        sessionStorage.setItem("filters3", JSON.stringify(filters3));

        let labels = [monthNames[lastMonth - 1]];
        if (yearToDate === 'yearToDate') {
            labels = [1, 2, 3, 4, 5, 6, 7].map(i => {
                let mindex = parseInt(lastMonth) - i;
                if (mindex < 0) {
                    mindex = 12 + mindex;
                }
                return monthNames[(parseInt(mindex))];
            })
        }
        let datasets = [];

        let _itemsWithValues = {}

        let PiesData = {
            "Quotes": {
                show: false,
                labels: [

                ],
                datasets: [{
                    label: 'Quotes',
                    data: [],
                    backgroundColor: []
                }],
                hoverOffset: 4
            },
            "PurchaseOrders": {
                show: false,
                labels: [

                ],
                datasets: [{
                    label: 'Purchase Orders',
                    data: [],
                    backgroundColor: []
                }],
                hoverOffset: 4
            },
            "Invoices": {
                show: false,
                labels: [

                ],
                datasets: [{
                    label: 'Invoices',
                    data: [],
                    backgroundColor: []
                }],
                hoverOffset: 4
            }
        };

        for (const key in data.chart_data) {
            let ix = data.objs.findIndex((obj) => obj[data.params.fieldName] === data.chart_data[key].label);
            let backgroundColor = data.objs[ix].color;

            if (data.chart_data[key].invoiceCount.map(e => e > 0).includes(true)) {
                if (filters[0]) {

                    _itemsWithValues[data.objs[ix][data.params.groupValueName]] = true;
                }
            }
            if (data.chart_data[key].poCount.map(e => e > 0).includes(true)) {
                if (filters[1]) {
                    _itemsWithValues[data.objs[ix][data.params.groupValueName]] = true;
                }
            }
            if (data.chart_data[key].quoteCount.map(e => e > 0).includes(true)) {
                if (filters[2]) {
                    _itemsWithValues[data.objs[ix][data.params.groupValueName]] = true;
                }
            }

            if (!filters2[key]) {
                let _data;
                if (filters[2]) {
                    PiesData.Quotes.show = true;
                    PiesData.Quotes.labels.push(data.chart_data[key].label);
                    _data = (filters3.USD && !filters3.EUR) ?
                        data.chart_data[key].quoteUSD
                        :
                        (filters3.EUR && !filters3.USD) ?
                            data.chart_data[key].quoteEUR
                            :
                            add2arrays(data.chart_data[key].quoteUSD, data.chart_data[key].quoteEUR);
                    PiesData.Quotes.datasets[0].data.push(_data);
                    PiesData.Quotes.datasets[0].backgroundColor.push(backgroundColor);


                    datasets.push({
                        label: data.chart_data[key].label + ' Quotes',
                        data: _data,
                        backgroundColor,
                        stack: "Quote"
                    });

                }
                if (filters[1]) {
                    _data = (filters3.USD && !filters3.EUR) ?
                        data.chart_data[key].poUSD
                        :
                        (filters3.EUR && !filters3.USD) ?
                            data.chart_data[key].poEUR
                            :
                            add2arrays(data.chart_data[key].poUSD, data.chart_data[key].poEUR);
                    PiesData.PurchaseOrders.show = true;
                    PiesData.PurchaseOrders.labels.push(data.chart_data[key].label);
                    PiesData.PurchaseOrders.datasets[0].data.push(_data);
                    PiesData.PurchaseOrders.datasets[0].backgroundColor.push(backgroundColor);



                    datasets.push({
                        label: data.chart_data[key].label + ' POs',
                        data: _data
                        ,
                        backgroundColor,
                        stack: "PO"
                    });

                }

                if (filters[0]) {
                    _data = (filters3.USD && !filters3.EUR) ?
                        data.chart_data[key].invoiceUSD
                        :
                        (filters3.EUR && !filters3.USD) ?
                            data.chart_data[key].invoiceEUR
                            :
                            add2arrays(data.chart_data[key].invoiceUSD, data.chart_data[key].invoiceEUR);

                    PiesData.Invoices.show = true;
                    PiesData.Invoices.labels.push(data.chart_data[key].label);
                    PiesData.Invoices.datasets[0].data.push(_data);
                    PiesData.Invoices.datasets[0].backgroundColor.push(backgroundColor);

                    datasets.push({
                        label: data.chart_data[key].label + ' Invoices',
                        data: _data
                        ,
                        //data: data.chart_data[key].invoiceUSD,
                        backgroundColor,
                        stack: "Invoice"
                    });
                }
            }
        }
        setItemsWithValues(_itemsWithValues)
        setDataObjs(data.objs);


        setDataPies(PiesData);
        setData({
            labels,
            datasets
        });

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [data, filters, filters2, filters3]);

    if (!data || !data.monthlyTotals) {
        return null;
    }


    const TotalLabels = () => {




        let invoiceUSDTotal = 0,
            invoiceTotalEUR = 0,
            quoteTotalEUR = 0,
            quoteTotalUSD = 0,
            poTotalEUR = 0,
            poTotalUSD = 0;

        let invoiceTotalUSD_array = [0, 0, 0, 0, 0, 0, 0],
            invoiceTotalEUR_array = [0, 0, 0, 0, 0, 0, 0],
            quoteTotalEUR_array = [0, 0, 0, 0, 0, 0, 0],
            quoteTotalUSD_array = [0, 0, 0, 0, 0, 0, 0],
            poTotalEUR_array = [0, 0, 0, 0, 0, 0, 0],
            poTotalUSD_array = [0, 0, 0, 0, 0, 0, 0];



        for (const key in data.chart_data) {
            if (!filters2[key]) {
                invoiceUSDTotal += parseFloat(data.chart_data[key].invoiceUSD[0]);
                invoiceTotalEUR += parseFloat(data.chart_data[key].invoiceEUR[0]);

                quoteTotalEUR += parseFloat(data.chart_data[key].quoteEUR[0]);
                quoteTotalUSD += parseFloat(data.chart_data[key].quoteUSD[0]);

                poTotalEUR += parseFloat(data.chart_data[key].poEUR[0]);
                poTotalUSD += parseFloat(data.chart_data[key].poUSD[0]);

                quoteTotalUSD_array = add2arrays(quoteTotalUSD_array, data.chart_data[key].quoteUSD);
                quoteTotalEUR_array = add2arrays(quoteTotalEUR_array, data.chart_data[key].quoteEUR);
                poTotalUSD_array = add2arrays(poTotalUSD_array, data.chart_data[key].poUSD);
                poTotalEUR_array = add2arrays(poTotalEUR_array, data.chart_data[key].poEUR);
                invoiceTotalUSD_array = add2arrays(invoiceTotalUSD_array, data.chart_data[key].invoiceUSD);
                invoiceTotalEUR_array = add2arrays(invoiceTotalEUR_array, data.chart_data[key].invoiceEUR);


            }


        }

        if (yearToDate !== 'month') {
            return <div
                className="div-row-space-between"
                style={{
                    margin: '0px 0px 0px 80px',
                    fontSize: 13,
                }}
            >
                {[0, 1, 2, 3, 4, 5, 6].map((item, index) => {
                    return <div
                        key={index}
                        style={{
                            width: 100,
                            textAlign: 'center'
                        }}
                    >
                        {filters3.USD &&
                            <div>

                                <hr />
                                {filters[2] && <div>
                                    {formatter((quoteTotalUSD_array[index]), 'USD').replace("$", "").split(".")[0]}
                                </div>}

                                {filters[1] && <div>
                                    {formatter(poTotalUSD_array[index], 'USD').replace("$", "").split(".")[0]}
                                </div>}
                                {filters[0] &&
                                    <div>
                                        {formatter(invoiceTotalUSD_array[index], 'USD').replace("$", "").split(".")[0]}
                                    </div>
                                }
                            </div>
                        }
                        {filters3.EUR &&
                            <div>
                                <hr />
                                {filters[2] && <div>
                                    {formatter((quoteTotalEUR_array[index]), 'EUR').replace("€", "").split(".")[0]}
                                </div>}

                                {filters[1] && <div>
                                    {formatter(poTotalEUR_array[index], 'EUR').replace("€", "").split(".")[0]}
                                </div>}
                                {filters[0] &&
                                    <div>
                                        {formatter(invoiceTotalEUR_array[index], 'EUR').replace("€", "").split(".")[0]}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                })}


            </div>;
        }
        return <div
            className="div-row-space-between"
            style={{
                margin: '0px 80px 0px 130px',
                fontSize: 18,
            }}
        >
            {filters[2] &&
                <div style={{ flex: 1, textAlign: 'center' }}>
                    Quoted
                    <br />
                    {!!quoteTotalUSD && formatter(quoteTotalUSD, 'USD')}
                    <br />
                    &nbsp;{!!quoteTotalEUR && formatter(quoteTotalEUR, 'EUR')}&nbsp;
                </div>
            }

            {filters[1] &&
                <div style={{ flex: 1, textAlign: 'center' }}>
                    Ordered
                    <br />
                    {!!poTotalUSD && formatter(poTotalUSD, 'USD')}
                    <br />
                    &nbsp;{!!poTotalEUR && formatter(poTotalEUR, 'EUR')}&nbsp;


                </div>
            }
            {filters[0] &&
                <div style={{ flex: 1, textAlign: 'center' }}>
                    Invoiced
                    <br />
                    {!!invoiceUSDTotal && formatter(invoiceUSDTotal, 'USD')}
                    <br />
                    &nbsp;{!!invoiceTotalEUR && formatter(invoiceTotalEUR, 'EUR')}&nbsp;


                </div>
            }


        </div>
    }

    const DataList = ({
        data,
    }) => {
        const [showModal, setShowModal] = React.useState(false);
        const [section, setSection] = React.useState('quotes');
        const [fileDetails, setFileDetails] = React.useState({
            fileId: null,
            clientQuoteId: null
        });
        const Item = ({
            item,
            index,
            label,
            amount,
        }) => {

            return <div
                style={{
                    margin: '10px 0px'
                }}
            >
                <div className="div-row-space-between" >

                    <div style={{ flex: 1 }}  >
                        {index + 1})
                        File {item.fileId}
                    </div>


                    {section === 'quotes' ?
                        <div style={{ flex: 1 }}>
                            <div
                                className="link"
                                onClick={() => {
                                    setShowModal(true);
                                    setFileDetails({
                                        fileId: item.fileId,
                                        clientQuoteId: item.clientQuoteId
                                    });
                                }}
                                style={{
                                    color:
                                        item.finishStatusId === 1 ? '#000'
                                            :
                                            item.finishStatusId === 2 ? '#808080'

                                                :
                                                item.fileStatusId === 4 ? 'red'
                                                    :
                                                    item.fileStatusId === 7 ? 'green'
                                                        :
                                                        'black'

                                    ,
                                }}
                            >{label}</div>
                            {item.finishStatusName || item.fileStatusName}
                        </div>
                        :
                        <div style={{ flex: 1 }}>
                            <div
                                className="link"
                                onClick={() => {
                                    setShowModal(true);
                                    setFileDetails({
                                        fileId: item.fileId,
                                        clientPOId: item.clientPOId
                                    });
                                }}
                                style={{
                                    color: "blue"
                                }}
                            >{label} {item.clientPOId}</div>
                        </div>

                    }



                    <div style={{ flex: 1, textAlign: 'right' }}>{formatter(amount, item.rfqCurrency)}</div>
                </div>

                <div className="div-row-space-between">
                    <div style={{ flex: 4 }}>{item.clientName}</div>
                    <div style={{ flex: 1 }}>{item.countryCode}</div>
                    <div style={{ flex: 1, textAlign: 'right' }}>{item.userName}</div>
                </div>
            </div >

        }

        if (!data) return null;


        return <div style={{
            marginBottom: 50
        }}>


            <Modal
                ariaHideApp={false}
                isOpen={showModal}
                contentLabel="File"
                style={customStyles}
            >
                <div
                    style={{
                        width: '100%',
                        height: 550
                    }}
                >
                    <button
                        style={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                        }}
                        onClick={() => {
                            setShowModal(false);

                        }}
                    >Close</button>


                    <div>
                        <h4 style={{ textAlign: 'left', padding: 0, margin: 0 }}>File {fileDetails.fileId}</h4>

                        {
                            fileDetails.clientQuoteId && <FileQuoteDetails
                                fileId={fileDetails.fileId}
                                quoteId={fileDetails.clientQuoteId || fileDetails.quoteId}
                                data={fileDetails}
                            />
                        }

                        {
                            fileDetails.clientPOId && <FilePODetails
                                fileId={fileDetails.fileId}
                                clientPOId={fileDetails.clientPOId}
                                data={fileDetails}
                            />
                        }


                    </div>


                </div>
            </Modal >

            <div className="div-row" >

                <button
                    style={{ backgroundColor: section === 'quotes' ? 'blue' : 'white', color: section === 'quotes' ? 'white' : 'black' }}
                    onClick={() => setSection('quotes')}
                >
                    Quoted
                </button>
                &nbsp;
                <button
                    style={{ backgroundColor: section === 'orders' ? 'blue' : 'white', color: section === 'orders' ? 'white' : 'black' }}
                    onClick={() => setSection('orders')}
                >
                    Ordered
                </button>
                &nbsp;
                <button
                    style={{ backgroundColor: section === 'invoices' ? 'blue' : 'white', color: section === 'invoices' ? 'white' : 'black' }}
                    onClick={() => setSection('invoices')}

                >
                    Invoiced
                </button>
            </div>
            {section === 'quotes' &&
                <div>
                    {data.quotes.filter(item => {
                        if (
                            (groupBy === 'country' && filters2[item.countryCode])
                            ||
                            (groupBy === 'salesperson' && filters2[item.salesRepUserId])
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }).map((item, index) => {
                        return <Item
                            key={index}
                            index={index}
                            item={item}
                            label={'Quote ' + item.clientQuoteId}
                            amount={item.quoteTotal}
                        />


                    })}


                </div>
            }
            {section === 'orders' &&
                <div>
                    {data.pos.filter(item => {
                        if (
                            (groupBy === 'country' && filters2[item.countryCode])
                            ||
                            (groupBy === 'salesperson' && filters2[item.salesRepUserId])
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }).map((item, index) => {

                        return <Item
                            key={index}
                            index={index}
                            item={item}
                            label={'PO' + item.clientPONumber}
                            amount={item.poTotal}
                        />
                    })}


                </div>
            }
            {section === 'invoices' &&
                <div>
                    {data.invoices.filter(item => {
                        if (
                            (groupBy === 'country' && filters2[item.countryCode])
                            ||
                            (groupBy === 'salesperson' && filters2[item.salesRepUserId])
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }).map((item, index) => {

                        return <Item
                            key={index}
                            index={index}
                            item={item}
                            label={'Invoice' + item.invoiceId}
                            amount={item.invoiceTotal}
                        />

                    })}


                </div>
            }
        </div>
    }

    const FilterButton = ({
        label,
        index
    }) => <button
        onClick={() => {
            setFilters({
                ...filters,
                [index]: !filters[index]
            })
        }}
        style={{
            backgroundColor: filters[index] ? "green" : "red",
            color: "white",
            borderRadius: 5,
            padding: 5,
            border: "none",
            width: 130,
            marginBottom: 10,
            textDecoration: filters[index] ? null : 'line-through',
            fontSize: 14
        }}
    >
            {label}
        </button>




    const CountrySalesPersonButton = ({
        label,
        index,
        color
    }) => <div

        onClick={() => {
            setFilters2({
                ...filters2,
                [index]: !filters2[index]
            })
        }}
        className="div-row"
        style={{
            border: '2px solid',
            borderColor: !filters2[index] ? "green" : "red",
            background: !filters2[index] ? "#00800030" : "#FF000030",
            color: "#000",
            borderRadius: 5,
            padding: 5,
            width: 170,
            margin: 2,
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: !filters2[index] ? null : 'line-through',
            textAlign: 'center',
            fontSize: 14,
            cursor: 'pointer',
            height: 40,
        }}
    >

            <div className="div-row">
                <div style={{ width: 120 }}>{label}</div>

                <div
                    style={{ border: '2px solid #000', width: 20, height: 20, borderRadius: 7, backgroundColor: color, display: 'inline-block', marginLeft: 10 }}>
                </div>

            </div>
        </div>

    const FilterButton3 = ({
        label,
        index
    }) => <button
        onClick={() => {
            setFilters3({
                ...filters3,
                [index]: !filters3[index]
            })
        }}
        style={{
            backgroundColor: filters3[index] ? "green" : "red",
            color: "white",
            borderRadius: 5,
            padding: 5,
            border: "none",
            width: 60,
            margin: 5,
            fontSize: 14
        }}
    >
            {label}
        </button>


    return <div
        style={{
            marginBottom: 50
        }}
    ><div
        style={{
            width: 900,

            padding: '20px 0px',
            margin: '20px 0px'
        }}
    >

            {!!Data.datasets && <div>

                {yearToDate === 'month' &&

                    <PieCharts
                        Data={DataPies}
                    />

                }
                <Bar
                    options={OPTIONS}
                    data={Data}
                />
                <TotalLabels
                />
            </div>
            }





        </div>
        <div
            className="div-row"
            style={{
                width: 800,
                justifyContent: 'space-between',
                margin: 'auto'
            }}
        >
            <div
                className="div-col"
                style={{

                    alignItems: 'center',
                }}
            >
                <FilterButton
                    label={"Quoted"}
                    index={2}
                />
                <FilterButton
                    label={"Ordered"}
                    index={1}
                />
                <FilterButton
                    label={"Invoiced"}
                    index={0}
                />

                <br />

                <div
                    className="div-row"
                >
                    {['USD', 'EUR'].map((e) => {
                        return <FilterButton3
                            key={e}
                            label={e}
                            index={e}
                        />
                    })}
                </div>
            </div>

            <div
                className="div-row"
                style={{
                    flexWrap: 'wrap',
                    width: 600,
                    alignItems: 'center',
                }}
            >
                {data_objs.sort((a, b) => a[data.params.fieldName].localeCompare(b[data.params.fieldName])).map((obj, index) => {

                    if (!itemsWithValues[obj[data.params.groupValueName]])
                        return null;

                    return <CountrySalesPersonButton
                        key={index}
                        label={obj[data.params.fieldName]}
                        index={obj[data.params.groupValueName]}
                        color={obj.color}
                    />
                })}

            </div>

        </div>
        <br /><br />
        {yearToDate === 'month' &&
            <DataList
                data={data}
            />
        }
    </div>

}

const fnMonth = () => {
    let m = new Date().getMonth() + 1;
    if (m < 10)
        m = "0" + m;
    return m;
}

function ReporteGestion() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [yearMonth, setYearMonth] = React.useState(searchParams.get("yearMonth") || (new Date().getFullYear() + "" + (fnMonth())));
    const [groupBy, setGroupBy] = React.useState(searchParams.get("groupBy") || "country");
    const [yearToDate, setYearToDate] = React.useState(searchParams.get("ytd") || "month");

    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [popupBlocked, setPopupBlocked] = React.useState(false);

    /* eslint-disable */
    React.useLayoutEffect(() => {

        setSearchParams({ yearMonth, groupBy, ytd: yearToDate });
        DataFetch({
            xls: false
        });
    }, [yearMonth, groupBy, yearToDate]);
    /* eslint-enable */

    const YearMonths = (last_n_months) => {
        const yearMonths = [];
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        let n = 0;
        for (let i = currentYear; i > 2021; i--) {
            n++;
            if (n < last_n_months) {
                if (currentYear !== i) {
                    currentMonth = 12;
                }
                for (let j = currentMonth; j > 0; j--) {
                    yearMonths.push(`${i}${j < 10 ? "0" + j : j}`)
                }
            }

        }

        return yearMonths;
    }


    const DataFetch = async ({
        xls
    }) => {
        if (loading) return;
        setLoading(true);
        const data = await API.ReporteGestionFetch({
            yearMonth,
            groupBy,
            method: xls ? "POST" : "GET"
        });
        setLoading(false);


        if (yearToDate === 'month') {
            for (const key in data.chart_data) {

                for (const key2 in data.chart_data[key]) {
                    if (Array.isArray(data.chart_data[key][key2])) {
                        data.chart_data[key][key2] = [data.chart_data[key][key2][0]];
                    }
                }

            }
        }
        setData(data);
        if (xls) {
            var newWin = window.open(data.file_url, '_blank');
            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                alert("Please disable your pop-up blocker or click to download");
                setPopupBlocked(true);
            } else {
                setPopupBlocked(false);
            }
        }

    }


    return (<div   >
        <HelmetProvider>
            <Helmet>
                <title>Reports</title>
            </Helmet>
        </HelmetProvider>
        <Menu />
        <Header />

        <div className="navigation-bar" >
            <div className="navigation-bar-content" >
                <Link to={`/admin/reports/`}><IoHomeSharp /></Link>

                <IoChevronForward />

                <b>Monthly</b>
            </div>
        </div>
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 950,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <div
                className="div-row"
                style={{ flexWrap: "nowrap", paddingLeft: 30 }}
            >
                <select onChange={(e) => {
                    setYearToDate(e.target.value);
                }}
                    value={yearToDate}
                >
                    <option value="month">Month</option>
                    <option value="yearToDate">Previous 6 months</option>
                </select>
                &nbsp;
                <select
                    onChange={(e) => {
                        setGroupBy(e.target.value);
                    }}
                    value={groupBy}
                >
                    <option value="country">By Country</option>
                    <option value="salesperson">By Salesperson</option>
                </select>

                &nbsp;
                <select
                    value={yearMonth}
                    onChange={(e) => {
                        setYearMonth(e.target.value)
                    }}
                >
                    {YearMonths(12).map((ym) => (
                        <option key={ym} value={ym}>{monthNames[parseInt(ym.substring(4, 6)) - 1]} {ym.substring(0, 4)}</option>
                    ))}
                </select>




                <div className="div-row"
                    style={{
                        width: 150, marginLeft: 20,
                        alignItems: 'center'
                    }}
                >
                    {!(data.file_url && popupBlocked && !loading)

                        ?

                        <button className="btn-invisible"
                            style={{
                                width: 100,
                                height: 30,
                                alignItems: 'center',

                                justifyContent: 'center',
                                color: loading ? '#c0c0c0' : 'blue'
                            }}
                            onClick={() => {
                                DataFetch({
                                    xls: true
                                });
                            }}
                        >
                            Create XLS

                        </button>
                        :
                        <div><a style={{ color: 'green' }} href={`${data.file_url}`} target="_blanl" rel="noreferrer" ><IoCloudDownloadSharp /> Download {yearMonth}</a></div>
                    }

                </div>
            </div>

            {
                loading ? <div style={{ marginTop: 50 }} >
                    <Fetching />
                </div> :
                    <div>
                        <DataTable
                            data={data}
                            lastMonth={yearMonth}
                            yearToDate={yearToDate}
                            groupBy={groupBy}
                        />

                    </div>
            }



        </div>
    </div >
    );
}

export default ReporteGestion;



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 550
    },
    overlay: {
        background: "#00000090"
    }
};