import React from "react";
import Styles from "../styles";
import Menu from '../app/components/Menu';
import Header from '../app/components/HeaderWH';
import { Link } from "react-router-dom";
import { Circles } from "react-loader-spinner";

import WHAPI from "../api/WHAPI";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width: width - 10,
        widthCol: width >= 1400 ? 600 : width >= 400 ? ((width / 2) - 20) : width,
        height
    };
}

const Items = ({
    items
}) => {

    /* eslint-disable */
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    React.useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /* eslint-enable */




    return <div
        style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }}

    >

        <div
            style={{
                borderTopWidth: 1, borderTopColor: '#c0c0c0', borderTopStyle: 'solid',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: windowDimensions.width,
                maxWidth: 600,
                minWidth: 350,
                margin: 'auto'
            }}
        >
            {items.map((i, ix) => <Styles.DivRow
                key={ix}
            >
                <span>
                    <b><Link to={`/admin/wh/file/${i.fileId}?vendorPOId=${i.vendorPOId}&s=vendor`}>File {i.fileId}</Link></b>
                    <br />
                    {i.clientName}
                    <br />
                    Vendor PO# {i.vendorPONumber}
                    <br />
                    {i.vendorName}
                    <br />

                    <b>{i.carrier.toUpperCase()} {i.trackingNumber}</b>
                </span>
            </Styles.DivRow >)

            }
        </div>
    </div>
}

const IncomingOrders = () => {
    const [fetching, setFetching] = React.useState(false);
    const [items, setItems] = React.useState([]);


    const DataFetch = async () => {
        setFetching(true);
        WHAPI.incomingFetch().then(res => {
            setFetching(false)
            setItems(res)

        });
    }
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    /* eslint-disable */
    React.useEffect(() => {
        DataFetch();

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /* eslint-enable */



    return <div>

        {fetching ?
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    padding: 20,

                }}
            >
                <Circles
                    color="#FF9400"
                    height={50}
                />
            </div>
            :
            <div
                style={{
                    width: windowDimensions.width
                }}
            >

                <Items
                    items={items}
                />
            </div>
        }


    </div>

}


const AdminMain = () => {

    return (<div   >
        <Menu />

        <Header />
        <div style={{
            margin: "auto",
            marginTop: 20,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>
            <div>

                <Link to="/admin/wh">Warehouse</Link>
                |
                <Link to="/admin/wh/release">For Release</Link>
            </div>
            <div
                style={{ marginTop: 5 }}
            >

                <Link to="/admin/wh/receive">Receive W/R</Link>
            </div>
            <Styles.H1 >In Transit</Styles.H1>




            <IncomingOrders />
        </div>
    </div>
    );
}

export default AdminMain;