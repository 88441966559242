import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { slide as MenuBurger } from 'react-burger-menu';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const Menu = () => {
    let token = useParams().token || "";

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);

    const [isOpen, setOpen] = React.useState(false)
    const handleIsOpen = () => {
        setOpen(!isOpen)
    }
    const navigate = useNavigate();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData
    } = dataReducer;

    const navigateTo = (pg) => {

        setOpen(false);
        setTimeout(() => {
            navigate(pg);
        }, 200)


    }
    /* eslint-disable */
    React.useEffect(() => {
        if (userData && userData.accountInfo) {
            if (userData.accountInfo.accountExists || userData.accountInfo.id) {
                setCookie('alani360', {
                    token,
                    phoneNumber: userData.accountInfo.phoneNumber,

                }, { path: '/' });
            }
        }
    }, [userData]);
    /* eslint-enable */

    return <MenuBurger
        styles={styles}
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
    >
        <a href="# " style={{ height: 0, visibility: 'hidden' }}>.</a>

        <a href="# " id="home" className="menu-item" onClick={() => navigateTo(`/admin`)} >Main</a>

        <div className="menu-item" style={{ textDecoration: 'none', fontWeight: 'bold' }}>Warehouse</div>
        <a href="# " style={{ margin: 5, }} id="home" className="menu-item" onClick={() => navigateTo(`/admin/wh/inTransit`)} >In Transit</a>
        <a href="# " style={{ margin: 5, }} id="home" className="menu-item" onClick={() => navigateTo(`/admin/wh/receive`)} >Receive W/R</a>

        <a href="# " style={{ margin: 5, }} id="home" className="menu-item" onClick={() => navigateTo(`/admin/wh`)} >Warehouse</a>
        <a href="# " style={{ margin: 5, }} id="home" className="menu-item" onClick={() => navigateTo(`/admin/wh/release`)} >For Release</a>
        <a href="# " style={{ margin: 5, }} id="home" className="menu-item" onClick={() => navigateTo(`/admin/wh/released`)} >Released</a>

        <a href="# " id="home" className="menu-item" onClick={() => navigateTo(`/admin/notifications/`)} >Clients Notifications</a>

        <a href="# " id="home" className="menu-item" onClick={() => navigateTo(`/admin/wh/reports`)} >WH Inventory</a>


        <br /><br />
        <a href="# " id="cuenta" className="menu-item" onClick={() => {
            let confirm = window.confirm("Logout ?");
            if (confirm) {
                localStorage.setItem("token", undefined);
                navigateTo(`/login/`);
            }
        }}  >Logout</a>


    </MenuBurger>
}

export default Menu;

var styles = {

    bmBurgerButton: {
        position: 'absolute',
        width: '30px',
        height: '20px',
        left: '10px',
        top: '20px'
    },
    bmBurgerBars: {
        background: '#373a47'
    },
    bmBurgerBarsHover: {
        background: '#a90000',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
    },
    bmMenu: {
        background: '#373a47',
        padding: '0 1.5em 0',
        fontSize: '1.15em'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        display: 'flex',
        flexDirection: 'column'
    },
    bmItem: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        marginBottom: 5,
        marginTop: 5,
        paddingTop: 5,
        paddingBottom: 5,
        textDecoration: 'underline',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
    }
}