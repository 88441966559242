import React from "react";
import Header from './Header';
import { Helmet, HelmetProvider } from "react-helmet-async";

import Fetching from "../Client/Fetching";

import { IoChevronForward, IoNotificationsCircleSharp } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import { NavBarSetup } from "./Components/NavBarSetup";

import API from "../../api/CustomerServiceAPI";

function CSSetup() {
    const navigate = useNavigate();

    const [fetching, setFetching] = React.useState(false);
    const [data, setData] = React.useState();
    const dataSearch = async () => {
        API.agents({}).then(res => {
            setFetching(false);
            setData(res.data.agents)
        });

    }
    React.useEffect(() => {
        setFetching(true);
        dataSearch();
    }, []);

    const Item = ({ item, index }) => {
        return (<div className="div-row"
            style={{
                backgroundColor: index % 2 === 0 ? '#e0e0e0' : '#ffffff',

                padding: 10
            }}
        >
            <div className="div-col" style={{
                width: '100%',
            }}>

                <div className="div-row-space-between" >
                    <Link to={`/admin/customer-service/setup/agent/${item.userId}`}>{item.userName}</Link>
                    <div>{item.userPhone || ' - '}</div>
                </div>
                <div className="div-row-space-between" >
                    <div>

                    </div>
                    {!!item.whatsAppReceiveNotifications ?
                        <div className="div-row" style={{ color: 'green' }}>
                            <IoNotificationsCircleSharp color="green" size={20} />
                            Enabled
                        </div>
                        :
                        <div className="div-col" style={{ color: 'red' }} >

                            Disabled
                            <button
                                onClick={() => {
                                    navigate(`/admin/customer-service/setup/agent/${item.userId}`)
                                }}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Details
                                <IoChevronForward />
                            </button>
                        </div>


                    }

                </div>
            </div>

        </div >)
    }
    return (<>
        <HelmetProvider>
            <Helmet>
                <title>Agents</title>
            </Helmet>
        </HelmetProvider>
        <Header />

        <Container
            style={{
                marginTop: 70,
                maxWidth: 650
            }}
        >

            <NavBarSetup />

            {fetching ?
                <Fetching />
                :
                <div

                >
                    <button
                        onClick={() => {
                            navigate(`/admin/customer-service/setup/agent/new`)
                        }}
                    >
                        New Agent
                    </button>

                    <div
                        style={{
                            width: '100%',
                            maxWidth: 450,
                            marginTop: 10,
                        }}
                    >
                        {data?.map((item, index) => {

                            return <Item key={index} index={index} item={item} />

                        })}


                    </div>

                </div>
            }

        </Container>
    </>
    );
}

export default CSSetup;